import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../constants/theme';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';
import GmisLogo from '../gmis-logo';
import { Grid } from '@material-ui/core';

const SecondaryLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Grid
          container
          direction='column'
          alignItems='center'
          justify='center'
          style={{ minHeight: '100vh', width: '100%' }}
          spacing={3}>
          <Grid item xs={12}>
            <GmisLogo width='238px' viewBox='0 0 238 50' />
          </Grid>
          <Grid item xs={12}>
            <Container>{children}</Container>
          </Grid>
        </Grid>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default SecondaryLayout;
