import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxBatch } from "@manaflair/redux-batch";
// import { createStore } from "redux";
// import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";

import rootReducer from "../redux/reducers/rootReducer";
import { rootSaga } from "../redux/reducers/rootReducer";

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
];
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(persistedReducer);
export default store;
