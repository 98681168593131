import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import colors from '../constants/colors';

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: '#FFF',
    backgroundColor: '#000',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    marginLeft: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  container: {
    display: 'inline-flex',
    '& .MuiInputLabel-root': {
      top: '-8px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '9.5px',
    },
  },
  searchForm: {
    width: '400px',
    backgroundColor: theme.palette.background.default,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  selectBoxColor: {
    '& .MuiSelect-iconFilled': {
      fill: '#dfdfdf',
    },
  },
  showHideColumns: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageTitle: {
    color: '#FFF',
    // backgroundColor: '#1f1f22',
  },
  pointer: {
    cursor: 'pointer',
  },
}));
export const useStylesTheme = makeStyles((theme) => ({
  root: {
    // paddingTop: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    color: '#FFF',
    backgroundColor: theme.palette.background.main,
  },
  highlight: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.default,
    },
  },
  searchForm: {
    width: '400px',
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(0),
    borderRadius: '5px',
    '& .MuiInputBase-root:focus': {
      border: `solid 1px ${theme.palette.primary}`,
    },
  },
}));
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
export const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '& .MuiCheckbox-colorSecondary': {
      color: theme.palette.primary.main,
    },
    '& .Mui-Checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: `${colors.primary} !important`,
    },
    '& .MuiIconButton-colorSecondary ': {
      color: `${colors.primary} !important`,
    },
  },
}))(Checkbox);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.background.default,
    //theme.palette.background.main
    // },
    backgroundColor: theme.palette.background.main,
  },
}))(TableRow);

export const handleLogout = () => {
  console.log('test');
  localStorage.removeItem('token');
  localStorage.removeItem('persist:users');
  localStorage.removeItem('persist:events');
  localStorage.removeItem('persist:guests');
  localStorage.removeItem('persist:tags');
  localStorage.removeItem('persist:categories');
  window.location.replace('/login');
};
