/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import guestJSON from './guest.json';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Button,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as GuestReducer from '../../redux/reducers/guests';
import { connect, useSelector } from 'react-redux';
import { addGuestAPI, editGuestAPI, getJobRolesAPI, getIndustrialSectorAPI } from '../../API/guests';
import { getCategoriesAPI } from '../../API/categories';
import { getTagsAPI } from '../../API/tags';
import * as RxCategories from '../../redux/reducers/categories';
import * as RxTags from '../../redux/reducers/tags';
import { guestForm } from '../../validators/validators';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paperStyle: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  buttonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.main,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.main,
      opacity: 0.7,
    },
  },
  formControl: {
    width: '100%',
  },
  countryCode: {},
}));

// change name of component
const GuestFormFragment = (props) => {
  const { guestData } = props;
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [files, setFiles] = useState(null);
  const [errorValidation, setErrorValidation] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [jobRoleAPIUnion, setJobRoleAPIUnion] = useState([]);
  const [industrialSecotrAPIUnion, setIndustrialSecotrAPIUnion] = useState([]);
  const [formValues, setFormValues] = useState({
    code: '',
    categoryCode: '',
    userCode: '',
    salutation: '',
    firstName: '',
    lastName: '',
    nationality: '',
    tagCodes: '',
    country: '',
    jobRole: '',
    jobTitle: '',
    companyName: '',
    industrySector: '',
    email: '',
    files: '',
    countryCode: '',
    contact: '',
    twitter: '',
    linkedin: '',
    phone: '',
    city: '',
    mediaOutletName: '',
    mediaOutletUrl: '',
    shortBio: '',
    publishingHouse: '',
    // referenceCode: '',
    address: '',
    articleLinks: [''],
  });

  useEffect(() => {
    if (guestData && guestData.code) {
      setFormValues((data) => ({
        ...data,
        ['code']: guestData.code,
        ['categoryCode']: guestData.category.code,
        ['userCode']: currentUser.code,
        ['salutation']: guestData.salutation === 'Pr.' ? 'Prof.' : guestData.salutation,
        ['firstName']: guestData.firstName,
        ['lastName']: guestData.lastName,
        ['nationality']: guestData.nationality ? guestData.nationality : '',
        // ["tagCodes"]: guestData.tagCodes,
        ['country']: guestData.country,
        ['jobRole']: guestData.jobRole ? guestData.jobRole : '',
        ['jobTitle']: guestData.jobTitle,
        ['companyName']: guestData.companyName ? guestData.industrySector : '',
        ['industrySector']: guestData.industrySector ? guestData.industrySector : '',
        ['email']: guestData.email,
        ['contact']: guestData.contact,
        ['countryCode']: guestData.countryCode,
        ['articleLinks']: guestData.articleLinks ? guestData.articleLinks : [''],
        ['twitter']: guestData.twitter ? guestData.twitter : '',
        ['linkedin']: guestData.linkedin ? guestData.linkedin : '',
        ['phone']: guestData.phone ? guestData.phone : '',
        ['city']: guestData.city ? guestData.city : '',
        ['mediaOutletName']: guestData.mediaOutletName ? guestData.mediaOutletName : '',
        ['mediaOutletUrl']: guestData.mediaOutletUrl ? guestData.mediaOutletUrl : '',
        ['shortBio']: guestData.shortBio ? guestData.shortBio : '',
        ['publishingHouse']: guestData.publishingHouse ? guestData.publishingHouse : '',
        // ['referenceCode']: guestData.referenceCode ? guestData.referenceCode : '',
        ['address']: guestData.address ? guestData.address : '',
      }));
      if (guestData.file && guestData.file.length > 0) {
        if (formValues.categoryCode !== 'GUEPRESS') {
          setFormValues((data) => ({
            ...data,
            ['files']: guestData.file[0].code,
          }));
        } else {
          var files = guestData.file.map((_file) => {
            return _file.code;
          });
          setFormValues((data) => ({
            ...data,
            ['files']: files,
          }));
        }
      }
    }

    if (guestData && guestData.file) {
      const uploadedFileArray = [];
      if (guestData.file.length > 0) {
        if (guestData.category.code !== 'GUEPRESS') {
          uploadedFileArray.push(`${process.env.REACT_APP_API_URL}static/${guestData.file[0].code}`);
          setUploadedFile(uploadedFileArray);
        } else {
          guestData.file.forEach((_file) => {
            uploadedFileArray.push(`${process.env.REACT_APP_API_URL}static/${_file.code}`);
            setUploadedFile(uploadedFileArray);
          });
        }
      } else {
        setUploadedFile([]);
      }
    } else {
      setUploadedFile([]);
    }

    // eslint-disable-next-line
  }, [guestData]);

  const history = useHistory();
  const currentUser = useSelector((store) => store.users.currentUser);
  useEffect(() => {
    setFormValues((data) => ({
      ...data,
      userCode: currentUser.code,
    }));
    //eslint-disable-next-line
  }, [setFormValues]);
  const classes = useStyles();
  const handleDropZoneChange = (file) => {
    setFiles(
      file.filter((_file) => {
        return _file.name.includes('.');
      })
    );
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setErrorValidation({
      ...errorValidation,
      [name]: null,
    });
  }

  function handleArticleLinkChange(e, index) {
    const { name, value } = e.target;
    var articleLinks = formValues.articleLinks;
    articleLinks[index] = value;
    setFormValues((data) => ({
      ...data,
      [name]: articleLinks,
    }));
  }

  function handleAddeNewArticlesLinks() {
    var articleLinks = formValues.articleLinks;
    articleLinks.push('');
    setFormValues((data) => ({
      ...data,
      articleLinks: articleLinks,
    }));
  }

  function handleCountryChange(e) {
    // eslint-disable-next-line array-callback-return
    guestJSON.country.map((_data, index) => {
      // eslint-disable-next-line eqeqeq
      if (_data.name == e.target.value) {
        setFormValues((data) => ({
          ...data,
          ['countryCode']: _data.dialCode,
          ['country']: e.target.value,
        }));
      }
    });
  }

  // Get Category list
  const fetchCateogryList = () => {
    props.processFilteredCategories();
    getCategoriesAPI()
      .then(({ data }) => {
        props.processFilteredCategoriesSuccess(data);
      })
      .catch((err) => props.processFilteredCategoriesFailed());
  };
  // Get Job Rules list
  const fetchJobRolesList = () => {
    getJobRolesAPI()
      .then((data) => {
        setJobRoleAPIUnion(
          _.compact(_.union(_.map(data.data, 'name'), _.map(guestJSON.jobRoleStates, 'name'))).sort((a, b) =>
            a.localeCompare(b)
          )
        );
      })
      .catch((err) => console.log(err));
  };
  // Get Industry Sectors list
  const fetchIndustrySectorList = () => {
    getIndustrialSectorAPI()
      .then((data) => {
        setIndustrialSecotrAPIUnion(
          _.compact(_.union(_.map(data.data, 'name'), _.map(guestJSON.industrySectorStates, 'name'))).sort((a, b) =>
            a.localeCompare(b)
          )
        );
      })
      .catch((err) => console.log(err));
  };

  const { categories } = useSelector((state) => state);

  const fetchTagList = () => {
    props.processFilteredTags();

    getTagsAPI()
      .then(({ data }) => {
        props.processFilteredTagsSuccess(data);
      })
      .catch((err) => props.processFilteredTagsFailed());
  };
  useEffect(() => {
    fetchTagList();
    fetchCateogryList();
    fetchJobRolesList();
    fetchIndustrySectorList();
    //api call here
    //eslint-disable-next-line
  }, []);
  const submitForm = () => {
    let errorMessages = {};
    try {
      setIsSubmitted(true);
      if (guestData && guestData.code) {
        props.processEditGuests();
        guestForm
          .validate(formValues, { abortEarly: false })
          .then(() => {
            editGuestAPI(formValues, files, deletedFiles)
              .then(({ data }) => {
                props.processEditGuestsSuccess(data);
                history.push('/guestlist');
              })
              .catch((err) => props.processEditGuestsFailed());
          })
          .catch((e) => {
            if (e.inner) {
              e.inner.forEach((err) => {
                errorMessages[err.path] = err.message;
              });
              setErrorValidation(errorMessages);
              console.log(errorMessages);
            }
          });
        setErrorValidation({});
      } else {
        props.processAddGuests();
        guestForm
          .validate(formValues, { abortEarly: false })
          .then(() => {
            addGuestAPI(formValues, files)
              .then(({ data }) => {
                props.processAddGuestsSuccess(data);
                history.push('/guestlist');
              })
              .catch((err) => props.processAddGuestsFailed());
          })
          .catch((e) => {
            e.inner.forEach((err) => {
              errorMessages[err.path] = err.message;
            });
            setErrorValidation(errorMessages);
            setIsSubmitted(true);
          });
        setErrorValidation({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <mainLayout>
      <div className={classes.root}>
        <Box className={classes.paperStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={5}>
              <Typography variant='h6'>
                {guestData && guestData.code ? (
                  <Box fontWeight='fontWeightBold'>EDIT GUEST</Box>
                ) : (
                  <Box fontWeight='fontWeightBold'>ADD GUEST</Box>
                )}
              </Typography>
            </Grid>
            <Grid container item xs={8} spacing={3}>
              <Box clone width={1}>
                <Grid item xs={12} md={6}>
                  <FormControl variant='filled' className={classes.formControl}>
                    <InputLabel id='salutation-label'>Salutation</InputLabel>
                    <Select
                      labelId='salutation-label'
                      id='salutation'
                      label='Salutation'
                      value={formValues.salutation}
                      name='salutation'
                      onChange={handleChange}
                      fullWidth>
                      {guestJSON.salutation.map((data, index) => {
                        return <MenuItem value={data.name}>{data.name}</MenuItem>;
                      })}
                    </Select>
                    {errorValidation.salutation ? (
                      <FormHelperText color='error'>
                        <Typography variant='body2' color='error'>
                          {errorValidation.salutation}
                        </Typography>
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Box>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <TextField
                    label='First Name'
                    type='text'
                    name='firstName'
                    variant='filled'
                    onChange={handleChange}
                    fullWidth
                    value={formValues.firstName}
                    error={errorValidation.firstName}
                    helperText={errorValidation.firstName}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <TextField
                    label='Last Name'
                    type='text'
                    variant='filled'
                    name='lastName'
                    onChange={handleChange}
                    fullWidth
                    value={formValues.lastName}
                    error={errorValidation.lastName}
                    helperText={errorValidation.lastName}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='nationality-label'>Nationality</InputLabel>
                  <Select
                    labelId='nationality-label'
                    id='nationality'
                    label='Nationality'
                    value={formValues.nationality}
                    name='nationality'
                    onChange={handleChange}
                    fullWidth>
                    {guestJSON.country.map((data, index) => {
                      return <MenuItem value={data.name}>{data.name}</MenuItem>;
                    })}
                  </Select>
                  {errorValidation.nationality ? (
                    <FormHelperText color='error'>
                      <Typography variant='body2' color='error'>
                        {errorValidation.nationality}
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='country-label'>Country</InputLabel>
                  <Select
                    labelId='country-label'
                    id='country'
                    label='Country'
                    value={formValues.country}
                    name='country'
                    onChange={handleCountryChange}
                    fullWidth>
                    {guestJSON.country.map((data, index) => {
                      return <MenuItem value={data.name}>{data.name}</MenuItem>;
                    })}
                  </Select>
                  {errorValidation.country ? (
                    <FormHelperText color='error'>
                      <Typography variant='body2' color='error'>
                        {errorValidation.country}
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {formValues.categoryCode === 'GUEPRESS' && (
                <Grid item xs={12} md={6}>
                  <FormControl variant='filled' className={classes.formControl}>
                    <TextField
                      label='City'
                      type='text'
                      variant='filled'
                      name='city'
                      onChange={handleChange}
                      fullWidth
                      value={formValues.city}
                      error={errorValidation.city}
                      helperText={errorValidation.city}></TextField>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='job-role-label'>Job Role</InputLabel>
                  <Select
                    labelId='job-role-label'
                    id='job_role'
                    label='Job Role'
                    name='jobRole'
                    value={formValues.jobRole}
                    onChange={handleChange}
                    fullWidth>
                    {jobRoleAPIUnion.map((data, index) => {
                      return <MenuItem value={data}>{data}</MenuItem>;
                    })}
                  </Select>
                  {errorValidation.jobRole ? (
                    <FormHelperText color='error'>
                      <Typography variant='body2' color='error'>
                        {errorValidation.jobRole}
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <TextField
                    label='Job Title'
                    type='text'
                    variant='filled'
                    name='jobTitle'
                    onChange={handleChange}
                    fullWidth
                    value={formValues.jobTitle}
                    error={errorValidation.jobTitle}
                    helperText={errorValidation.jobTitle}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <TextField
                    label='Company Name'
                    type='text'
                    variant='filled'
                    name='companyName'
                    onChange={handleChange}
                    fullWidth
                    value={formValues.companyName}
                    error={errorValidation.companyName}
                    helperText={errorValidation.companyName}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='industrial-sector-label'>Industrial Sector</InputLabel>
                  <Select
                    labelId='industrial-sector-label'
                    id='industrial_sector'
                    label='Industrial Sector'
                    value={formValues.industrySector}
                    name='industrySector'
                    onChange={handleChange}
                    fullWidth>
                    {industrialSecotrAPIUnion.map((data, index) => {
                      return <MenuItem value={data}>{data}</MenuItem>;
                    })}
                  </Select>
                  {errorValidation.industrySector ? (
                    <FormHelperText color='error'>
                      <Typography variant='body2' color='error'>
                        {errorValidation.industrySector}
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <TextField
                    label='Email'
                    type='text'
                    variant='filled'
                    name='email'
                    onChange={handleChange}
                    fullWidth
                    value={formValues.email}
                    error={errorValidation.email}
                    helperText={errorValidation.email}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <Grid container>
                    <Grid item xs={3}>
                      <InputLabel id='country-code-label'>Code</InputLabel>
                      <Select
                        labelId='country-code-label'
                        id='country_code'
                        value={formValues.countryCode}
                        name='countryCode'
                        onChange={handleChange}
                        fullWidth
                        readonly>
                        {guestJSON.country.map((data, index) => {
                          return <MenuItem value={data.dialCode}>{data.dialCode}</MenuItem>;
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={9}>
                      <Box ml={1} width={1}>
                        <TextField
                          label='Mobile'
                          type='text'
                          variant='filled'
                          name='contact'
                          onChange={handleChange}
                          value={formValues.contact}
                          error={errorValidation.contact}
                          helperText={errorValidation.contact}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              {formValues.categoryCode === 'GUEPRESS' && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <Grid container>
                        <Grid item xs={3}>
                          <InputLabel id='country-code-label'>Code</InputLabel>
                          <Select
                            labelId='country-code-label'
                            id='country_code'
                            value={formValues.countryCode}
                            name='countryCode'
                            onChange={handleChange}
                            fullWidth
                            readonly>
                            {guestJSON.country.map((data, index) => {
                              return <MenuItem value={data.dialCode}>{data.dialCode}</MenuItem>;
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={9}>
                          <Box ml={1} width={1}>
                            <TextField
                              label='Phone'
                              type='text'
                              variant='filled'
                              name='phone'
                              onChange={handleChange}
                              value={formValues.phone}
                              error={errorValidation.phone}
                              helperText={errorValidation.phone}
                              fullWidth
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Twitter'
                        type='text'
                        variant='filled'
                        name='twitter'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.twitter}
                        error={errorValidation.twitter}
                        helperText={errorValidation.twitter}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Linkedin URL'
                        type='text'
                        variant='filled'
                        name='linkedin'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.linkedin}
                        error={errorValidation.linkedin}
                        helperText={errorValidation.linkedin}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Media Outlet Name'
                        type='text'
                        variant='filled'
                        name='mediaOutletName'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.mediaOutletName}
                        error={errorValidation.mediaOutletName}
                        helperText={errorValidation.mediaOutletName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Media Outlet URL'
                        type='text'
                        variant='filled'
                        name='mediaOutletUrl'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.mediaOutletUrl}
                        error={errorValidation.mediaOutletUrl}
                        helperText={errorValidation.mediaOutletUrl}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Short Bio'
                        type='text'
                        variant='filled'
                        name='shortBio'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.shortBio}
                        error={errorValidation.shortBio}
                        helperText={errorValidation.shortBio}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Publishing House'
                        type='text'
                        variant='filled'
                        name='publishingHouse'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.publishingHouse}
                        error={errorValidation.publishingHouse}
                        helperText={errorValidation.publishingHouse}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Reference Code'
                        type='text'
                        variant='filled'
                        name='referenceCode'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.referenceCode}
                        error={errorValidation.referenceCode}
                        helperText={errorValidation.referenceCode}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Address'
                        type='text'
                        variant='filled'
                        name='address'
                        onChange={handleChange}
                        fullWidth
                        value={formValues.address}
                        error={errorValidation.address}
                        helperText={errorValidation.address}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='category-label'>Category</InputLabel>
                  <Select
                    labelId='category-label'
                    id='category'
                    label='Category'
                    name='categoryCode'
                    value={formValues.categoryCode}
                    onChange={handleChange}
                    fullWidth
                    required>
                    {categories.data.map((data, index) => {
                      return <MenuItem value={data.code}>{data.name}</MenuItem>;
                    })}
                  </Select>
                  {errorValidation.categoryCode ? (
                    <FormHelperText color='error'>
                      <Typography variant='body2' color='error'>
                        {errorValidation.categoryCode}
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {formValues.categoryCode === 'GUEPRESS' && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant='filled' className={classes.formControl}>
                      <TextField
                        label='Coverage Article Link'
                        type='text'
                        variant='filled'
                        name='articleLinks'
                        onChange={(e) => {
                          handleArticleLinkChange(e, 0);
                        }}
                        fullWidth
                        value={
                          formValues.articleLinks &&
                          formValues.articleLinks.length &&
                          formValues.articleLinks.length > 0
                            ? formValues.articleLinks[0]
                            : ''
                        }
                        error={errorValidation.articleLinks}
                        helperText={errorValidation.articleLinks}
                      />
                    </FormControl>
                  </Grid>
                  {formValues.articleLinks.length > 1
                    ? formValues.articleLinks.map((_link, index) => {
                        if (index !== 0)
                          return (
                            <>
                              <Grid item sm={10} lg={11}>
                                <FormControl variant='filled' className={classes.formControl}>
                                  <TextField
                                    label={`Additional Link ${index}`}
                                    type='text'
                                    variant='filled'
                                    name='articleLinks'
                                    onChange={(e) => {
                                      handleArticleLinkChange(e, index);
                                    }}
                                    fullWidth
                                    value={
                                      formValues.articleLinks && formValues.articleLinks.length > 0
                                        ? formValues.articleLinks[index]
                                        : ''
                                    }
                                    error={errorValidation.articleLinks}
                                    helperText={errorValidation.articleLinks}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item sm={2} lg={1}>
                                <Button
                                  onClick={() => {
                                    setFormValues((data) => ({
                                      ...data,
                                      articleLinks: formValues.articleLinks.filter((_item, idx) => {
                                        return idx !== index;
                                      }),
                                    }));
                                  }}>
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            </>
                          );
                      })
                    : null}
                  <Button
                    disabled={formValues.articleLinks[formValues.articleLinks.length - 1] !== '' ? false : true}
                    color='primary'
                    onClick={() => handleAddeNewArticlesLinks()}>
                    + Additional Links
                  </Button>
                </>
              )}
            </Grid>

            <Grid container item xs={4} spacing={3}>
              <Grid item xs={12}>
                {uploadedFile.length > 0 ? (
                  <DropzoneArea
                    key='withFiles'
                    dropzoneText='Drag and drop here'
                    maxFileSize={13000000}
                    onChange={(file) => handleDropZoneChange(file)}
                    onDelete={(file) => {
                      if (!file.name.includes('.') && guestData) {
                        setDeletedFiles((data) => [...data, file.name]);
                      }
                    }}
                    filesLimit={formValues.categoryCode === 'GUEPRESS' ? 3 : 1}
                    acceptedFiles={
                      formValues.categoryCode === 'GUEPRESS' ? ['image/*', 'application/pdf'] : ['image/*']
                    }
                    initialFiles={uploadedFile}
                  />
                ) : (
                  <DropzoneArea
                    key='withOutFiles'
                    dropzoneText='Drag and drop here'
                    onChange={(file) => handleDropZoneChange(file)}
                    maxFileSize={13000000}
                    filesLimit={formValues.categoryCode === 'GUEPRESS' ? 3 : 1}
                    acceptedFiles={
                      formValues.categoryCode === 'GUEPRESS' ? ['image/*', 'application/pdf'] : ['image/*']
                    }
                  />
                )}

                {!files && isSubmitted ? (
                  <FormHelperText color='error'>
                    <Typography variant='body2' color='error'>
                      Image file is required
                    </Typography>
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box align='right' justifyContent='flex-end' display='flex' mt={5}>
                <Box mr={2} onClick={() => history.goBack()}>
                  {guestData && guestData.code ? (
                    <Button vaiant='text'>
                      <Box fontWeight='fontWeightBold'>Cancel</Box>
                    </Button>
                  ) : (
                    <Button
                      vaiant='text'
                      onClick={() =>
                        setFormValues({
                          code: '',
                          categoryCode: '',
                          userCode: '',
                          salutation: '',
                          firstName: '',
                          lastName: '',
                          nationality: '',
                          tagCodes: '',
                          country: '',
                          jobRole: '',
                          jobTitle: '',
                          companyName: '',
                          industrySector: '',
                          email: '',
                          file: '',
                          countryCode: '',
                          contact: '',
                        })
                      }>
                      <Box fontWeight='fontWeightBold'>Cancel</Box>
                    </Button>
                  )}
                </Box>
                <Box>
                  <Button
                    className={classes.buttonStyles}
                    vaiant='contained'
                    onClick={() => {
                      submitForm();
                    }}>
                    <Box fontWeight='fontWeightBold'>Submit</Box>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </mainLayout>
  );
};
export default connect(null, {
  ...RxCategories.actions,
  ...GuestReducer.actions,
  ...RxTags.actions,
})(GuestFormFragment);
