import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@material-ui/core';
import { StyledTableCell } from '../../helpers/helpers';
import colors from '../../constants/colors';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headRow: {
    color: colors.textSecondary,
  },
  approved: {
    color: `${colors.secondary} !important`,
  },
  sent: {
    color: `${colors.primary} !important`,
  },
  rejected: {
    color: `${colors.error} !important`,
  },
  notSelected: {
    color: `${colors.textSecondary} !important`,
  },
});
const MuiTableCell = withStyles({
  root: {
    borderBottom: 'none',
    color: colors.textSecondary,
  },
})(TableCell);
const headRows = [
  {
    id: 'event',
    label: 'Previous Event Name',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'job',
    label: 'Job Title',
  },
  {
    id: 'company',
    label: 'Company Name',
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

const EventsPerGuest = ({ guest, events, categories, statuses }) => {
  const classes = useStyles();
  return (
    <Box mt={5} ml={8}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              {events &&
                events.length > 0 &&
                headRows.map((_data, idx) => (
                  <StyledTableCell align='left' className={classes.headRow}>
                    {' '}
                    {_data.label}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events &&
              events.map(
                (row, idx) =>
                  row && (
                    <TableRow key={row.code}>
                      <MuiTableCell component='th' scope='row'>
                        {row.name}
                      </MuiTableCell>
                      <MuiTableCell align='left'>{categories[idx].name}</MuiTableCell>
                      <MuiTableCell align='left'>{guest.jobTitle}</MuiTableCell>
                      <MuiTableCell align='left'>{guest.companyName}</MuiTableCell>
                      <MuiTableCell align='left'>{`${row.startDate.slice(0, 10)} to ${row.endDate.slice(
                        0,
                        10
                      )}`}</MuiTableCell>
                      <MuiTableCell
                        className={
                          statuses[idx] === 'Approved'
                            ? classes.approved
                            : statuses[idx] === 'Rejected'
                            ? classes.rejected
                            : statuses[idx] === 'Sent'
                            ? classes.sent
                            : classes.notSelected
                        }
                        align='left'>
                        {statuses[idx]}
                      </MuiTableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default EventsPerGuest;
