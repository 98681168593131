import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Box,
  Button,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Paper,
  Checkbox,
} from '@material-ui/core';
import MainLayout from '../../layouts/MainLayout';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addEventAPI } from '../../API/events';
import { addVirtualEventValidator } from '../../validators/validators';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  cardStyles: {
    minWidth: 450,
  },
  buttonStyles: {
    backgroundColor: '#A4C627',
    color: '#19191D',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#A4C627',
      color: '#19191D',
      opacity: 0.7,
    },
  },
}));
const AddEvent = (props) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const [errorValidation, setErrorValidation] = useState({});
  const [startDate, setStartDate] = useState(new Date().toJSON());
  const [endDate, setEndDate] = useState(new Date().toJSON());
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setFormValues({ ...formValues, startDate, endDate });
    //eslint-disable-next-line
  }, []);
  const handleDateFromPicker = (id) => (e) => {
    setStartDate(e);
    setFormValues({
      ...formValues,
      [id]: e.toISOString(),
    });
  };
  const handleDateToPicker = (id) => (e) => {
    setEndDate(e);
    setFormValues({
      ...formValues,
      [id]: e.toISOString(),
    });
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
    const { name, checked } = event.target;
    // console.log(checked);
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setErrorValidation({
      ...errorValidation,
      [name]: null,
    });
  };
  const handleSubmit = () => {
    let errorMessages = {};
    try {
      const { name, startDate, endDate, description, inWebsite } = formValues;
      addVirtualEventValidator
        .validate({ name, description }, { abortEarly: false })
        .then(() => {
          addEventAPI({ name, startDate, endDate, description, inWebsite, isVirtual: true })
            .then(({ data }) => {
              history.push('/eventslist');
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log('e:', e, e.inner);
          //fill the validation here
          e.inner.forEach((err) => {
            console.log('err', err);
            errorMessages[err.path] = err.message;
          });
          setErrorValidation(errorMessages);
          console.log('state: validation', errorValidation);
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <MainLayout>
      <Paper className={classes.paper}>
        <Card>
          <CardContent>
            <Box xs={12} mb={5}>
              <Typography variant='h6'>
                <Box fontWeight='fontWeightBold'>ADD EVENT</Box>
              </Typography>
            </Box>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <TextField
                      label='Event Name'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.name}
                      onChange={handleChange}
                      name='name'
                      error={errorValidation.name}
                      helperText={errorValidation.name}></TextField>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box mr={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='dd/MM/yyyy'
                        margin='normal'
                        id='startDate'
                        value={startDate}
                        label='FROM'
                        name='from'
                        disablePast={true}
                        error={errorValidation.startDate}
                        helperText={errorValidation.startDate}
                        autoOk
                        onChange={handleDateFromPicker('startDate')}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      id='endDate'
                      value={endDate}
                      label='TO'
                      name='to'
                      disablePast={true}
                      minDate={startDate}
                      error={errorValidation.endDate}
                      helperText={errorValidation.endDate}
                      autoOk
                      onChange={handleDateToPicker('endDate')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <TextField
                    label='Description'
                    type='text'
                    variant='filled'
                    fullWidth
                    multiline
                    rows={5}
                    value={formValues.description}
                    onChange={handleChange}
                    name='description'
                    error={errorValidation.description}
                    helperText={errorValidation.description}></TextField>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBox}
                    name='inWebsite'
                    value={checked}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  Add to Registration Form
                </Grid>
              </Grid>
            </Grid>
            <Box item align='right' p={2}>
              {/* {Object.keys(formValues).length > 0 &&
                Object.values(formValues).filter((item) => item !== '').length > 0 && (
                  <Box clone mr={2}>
                    <Button vaiant='contained' onClick={clearForm} className={classes.buttonStyles}>
                      <Box fontWeight='fontWeightBold'>Cancel</Box>
                    </Button>
                  </Box>
                )} */}

              <Box clone mr={2}>
                <Button variant='text'>
                  <Box fontWeight='fontWeightBold' onClick={() => history.goBack()}>
                    Cancel
                  </Box>
                </Button>
              </Box>
              <Button variant='contained' onClick={handleSubmit} className={classes.buttonStyles}>
                <Box fontWeight='fontWeightBold'>Submit</Box>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </MainLayout>
  );
};
export default AddEvent;
