import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../constants/theme";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Container } from "@material-ui/core";
import CustomDrawer from "../components/customDrawer";

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Container maxWidth={false}>
          <CustomDrawer />
          {children}
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default MainLayout;
