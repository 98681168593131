import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  total: 0,
  limit: 20,
  list: [],
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: "user-logs", whitelist: ["user-logs", "list"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.USERS_LOG:
        return { ...state, loading: true };
      case actionTypes.USERS_LOG_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload.data,
          limit: action.payload.limit,
          total: action.payload.total,
        };
      case actionTypes.USERS_LOG_FAILED:
        return { ...state, loading: false };
      default:
        return state;
    }
  }
);
export const actionTypes = {
  USERS_LOG: "[USERS] LIST",
  USERS_LOG_SUCCESS: "[USERS] LIST SUCCESS",
  USERS_LOG_FAILED: "[USERS] LIST FAILED",
};

export const actions = {
  processFilteredUsersLogs: () => ({
    type: actionTypes.USERS_LOG,
    payload: {},
  }),
  processFilteredUsersLogsSuccess: (data) => ({
    type: actionTypes.USERS_LOG_SUCCESS,
    payload: data,
  }),
  processFilteredUsersLogsFailed: () => ({
    type: actionTypes.USERS_LOG_FAILED,
    payload: {},
  }),
};

export function* saga() {}
