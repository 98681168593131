import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
// import guests from "./guests";
import * as users from './users';
import * as guests from './guests';
import * as categories from './categories';
import * as events from './events';
import * as tags from './tags';
import * as agenda from './agenda';
import * as userLogs from './userLogs';
import * as virtualGuests from './virtualGuests';
//TODO: remeber that the root reducer will take the single reducer from each module
const rootReducer = combineReducers({
  users: users.reducer,
  guests: guests.reducer,
  categories: categories.reducer,
  events: events.reducer,
  tags: tags.reducer,
  agenda: agenda.reducer,
  userLogs: userLogs.reducer,
  virtualGuests: virtualGuests.reducer,
});
export default rootReducer;
// TODO: you yield each reducer with it's saga function at this rootSaga function
export function* rootSaga() {
  yield all([
    users.saga(),
    guests.saga(),
    categories.saga(),
    events.saga(),
    tags.saga(),
    agenda.saga(),
    userLogs.saga(),
    virtualGuests.saga(),
  ]);
}
