import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  total: 0,
  limit: 20,
  list: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: 'tags', whitelist: ['tags'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TAGS_LIST:
      return { ...state, loading: true };
    case actionTypes.TAGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        limit: action.payload.limit,
        total: action.payload.total,
      };
    case actionTypes.TAGS_LIST_FAILED:
      return { ...state, loading: false };
    //?==============================================
    case actionTypes.TAG_ADD:
      return { ...state, loading: true };
    case actionTypes.TAG_ADD_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.TAG_ADD_FAILED:
      return { ...state, loading: false };
    //?==============================================
    case actionTypes.TAG_EDIT:
      return { ...state, loading: true };
    case actionTypes.TAG_EDIT_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.TAG_EDIT_FAILED:
      return { ...state, loading: false };
    //?==============================================
    default:
      return state;
  }
});
export const actionTypes = {
  TAGS_LIST: '[TAGS] LIST',
  TAGS_LIST_SUCCESS: '[TAGS] LIST SUCCESS',
  TAGS_LIST_FAILED: '[TAGS] LIST FAILED',
  TAG_ADD: '[TAG] ADD TAG',
  TAG_ADD_SUCCESS: '[TAG]ADD TAG SUCESS',
  TAG_ADD_FAILED: '[TAG]ADD TAG FAILED ',
  TAG_EDIT: '[TAG] EDIT TAG',
  TAG_EDIT_SUCCESS: '[TAG]EDIT TAG SUCESS',
  TAG_EDIT_FAILED: '[TAG]EDIT TAG FAILED ',
};

export const actions = {
  processFilteredTags: () => ({
    type: actionTypes.TAGS_LIST,
    payload: {},
  }),
  processFilteredTagsSuccess: (data) => ({
    type: actionTypes.TAGS_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredTagsFailed: () => ({
    type: actionTypes.TAGS_LIST_FAILED,
    payload: {},
  }),
  //==============================================
  processAddTags: () => ({
    type: actionTypes.TAG_ADD,
    payload: {},
  }),
  processAddTagsSuccess: () => ({
    type: actionTypes.TAG_ADD_SUCCESS,
    payload: {},
  }),
  processAddTagsFailed: () => ({
    type: actionTypes.TAG_ADD_FAILED,
    payload: {},
  }),
  //==============================================
  processEditTags: () => ({
    type: actionTypes.TAG_EDIT,
    payload: {},
  }),
  processEditTagsSuccess: () => ({
    type: actionTypes.TAG_EDIT_SUCCESS,
    payload: {},
  }),
  processEditTagsFailed: () => ({
    type: actionTypes.TAG_EDIT_FAILED,
    payload: {},
  }),
  //==============================================
};

export function* saga() {}
