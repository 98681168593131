import axios from "axios";

const GET_CATEGORIES = "categories";
export const ADD_CATEGORY = "categories/add";
export const EDIT_CATEGORY = "categories/edit";
export const DELETE_CATEGORY = "categories/delete";

export const getCategoriesAPI = () => {
  return axios.post(GET_CATEGORIES);
};

export const deleteCategory = (code) => {
  return axios.post(DELETE_CATEGORY, code);
};

export const addCategoryAPI = (name) => {
  // payload: form data  ==>  file
  const body = {
    name,
  };
  return axios.post(ADD_CATEGORY, body);
};

export const editCategoryAPI = (name, code) => {
  // payload: form data  ==>  file
  const body = {
    name,
  };
  return axios.post(EDIT_CATEGORY + "/" + code, body);
};
