import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  total: 0,
  limit: 20,
  data: [],
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: 'categories', whitelist: ['categories'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.CATEGORIES_LIST:
        return { ...state, loading: true };
      case actionTypes.CATEGORIES_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
          limit: action.payload.limit,
          total: action.payload.total,
        };
      case actionTypes.CATEGORIES_LIST_FAILED:
        return { ...state, loading: false };
      //?==============================================
      case actionTypes.CATEGORY_ADD:
        return { ...state, loading: true };
      case actionTypes.CATEGORY_ADD_SUCCESS:
        return { ...state, loading: false };
      case actionTypes.CATEGORY_ADD_FAILED:
        return { ...state, loading: false };
      //?==============================================
      case actionTypes.CATEGORY_EDIT:
        return { ...state, loading: true };
      case actionTypes.CATEGORY_EDIT_SUCCESS:
        return { ...state, loading: false };
      case actionTypes.CATEGORY_EDIT_FAILED:
        return { ...state, loading: false };
      //?==============================================
      default:
        return state;
    }
  }
);
export const actionTypes = {
  CATEGORIES_LIST: '[CATEGORIES] LIST',
  CATEGORIES_LIST_SUCCESS: '[CATEGORIES] LIST SUCCESS',
  CATEGORIES_LIST_FAILED: '[CATEGORIES] LIST FAILED',
  CATEGORY_ADD: '[CATEGORY] ADD CATEGORY',
  CATEGORY_ADD_SUCCESS: '[CATEGORY]ADD CATEGORY SUCESS',
  CATEGORY_ADD_FAILED: '[CATEGORY]ADD CATEGORY FAILED ',
  CATEGORY_EDIT: '[CATEGORY] EDIT CATEGORY',
  CATEGORY_EDIT_SUCCESS: '[CATEGORY]EDIT CATEGORY SUCESS',
  CATEGORY_EDIT_FAILED: '[CATEGORY]EDIT CATEGORY FAILED ',
};

export const actions = {
  processFilteredCategories: () => ({
    type: actionTypes.CATEGORIES_LIST,
    payload: {},
  }),
  processFilteredCategoriesSuccess: (data) => ({
    type: actionTypes.CATEGORIES_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredCategoriesFailed: () => ({
    type: actionTypes.CATEGORIES_LIST_FAILED,
    payload: {},
  }),
  //==============================================
  processAddCategories: () => ({
    type: actionTypes.CATEGORY_ADD,
    payload: {},
  }),
  processAddCategoriesSuccess: () => ({
    type: actionTypes.CATEGORY_ADD_SUCCESS,
    payload: {},
  }),
  processAddCategoriesFailed: () => ({
    type: actionTypes.CATEGORY_ADD_FAILED,
    payload: {},
  }),
  //==============================================
  processEditCategories: () => ({
    type: actionTypes.CATEGORY_EDIT,
    payload: {},
  }),
  processEditCategoriesSuccess: () => ({
    type: actionTypes.CATEGORY_EDIT_SUCCESS,
    payload: {},
  }),
  processEditCategoriesFailed: () => ({
    type: actionTypes.CATEGORY_EDIT_FAILED,
    payload: {},
  }),
  //==============================================
};

export function* saga() {}
