import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//? initial state: empty array
const initialState = {
  list: [],
  limit: 20,
  page: 1,
  total: 0,
  loading: false,
  guestEvents: [],
};
//? actionTypes:
// getguests ==> addguest ==> deleteguest ==> updateguest
export const actionTypes = {
  GUEST_LIST: '[GUEST] LIST GUESTS',
  GUEST_LIST_SUCCESS: '[GUEST] LIST GUESTS SUCCESS',
  GUEST_LIST_FAILED: '[GUEST] LIST GUESTS FAILED',
  GUEST_ADD: '[GUEST] ADD GUEST',
  GUEST_ADD_SUCCESS: '[GUEST]ADD GUEST SUCESS',
  GUEST_ADD_FAILED: '[GUEST]ADD GUEST FAILED ',
  GUEST_EDIT: '[GUEST] EDIT GUEST',
  GUEST_EDIT_SUCCESS: '[GUEST]EDIT GUEST SUCESS',
  GUEST_EDIT_FAILED: '[GUEST]EDIT GUEST FAILED ',
  GUEST_LIST_ONLY: '[GUEST]ADD GUEST LIST ONLY',
  GUEST_LIST_EVENT_SUCCESS: '[GUEST] GUEST LIST EVENTS',
  // GUEST_UPDATE: "[GUEST] UPDATE",
  // GUEST_UPDATE_SUCCESS: "[GUEST] UPDATE SUCCESS",
  // GUEST_UPDATE_FAILED: "[GUEST]UPDATE FAILED",
  // GUEST_DELETE: "[GUEST] DELETE",
  // GUEST_DELETE_SUCCESS: "[GUEST] DELETE SUCCESS ",
  // GUEST_DELETE_FAILED: "[GUEST] DELETE FAILED",
};
//?reducer with persisteRudcer
export const reducer = persistReducer(
  {
    storage,
    key: 'guests',
    whitelist: ['guests', 'list'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GUEST_LIST_ONLY:
        console.log(action.payload);
        return { ...state, list: action.payload };
      // return { ...state };
      case actionTypes.GUEST_LIST:
        return { ...state, loading: true };
      case actionTypes.GUEST_LIST_SUCCESS:
        //todo: filter the data here and update the state
        const { data, total, limit, page, guestEvents } = action.payload;
        return { loading: false, list: data, total, limit, page, guestEvents };
      case actionTypes.GUEST_LIST_FAILED:
        return { ...state, loading: false };
      //?==============================================
      case actionTypes.GUEST_LIST_EVENT_SUCCESS:
        // const { data, guestEvents } = action.payload
        return { ...state, list: action.payload.data, guestEvents: action.payload.guestEvents };
      case actionTypes.GUEST_ADD:
        return { ...state, loading: true };
      case actionTypes.GUEST_ADD_SUCCESS:
        return { ...state, loading: false };
      case actionTypes.GUEST_ADD_FAILED:
        return { ...state, loading: false };
      //?==============================================
      case actionTypes.GUEST_EDIT:
        return { ...state, loading: true };
      case actionTypes.GUEST_EDIT_SUCCESS:
        return { ...state, loading: false };
      case actionTypes.GUEST_EDIT_FAILED:
        return { ...state, loading: false };
      //?==============================================
      default:
        return state;
    }
  }
);
//? actions
export const actions = {
  processFilteredGuests: () => ({
    type: actionTypes.GUEST_LIST,
    payload: {},
  }),
  processFilteredGuestsSuccess: (data) => ({
    type: actionTypes.GUEST_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredGuestsFailed: () => ({
    type: actionTypes.GUEST_LIST_FAILED,
    payload: {},
  }),
  processFilteredGuestsList: (data) => ({
    type: actionTypes.GUEST_LIST_ONLY,
    payload: data,
  }),
  //==============================================
  processAddGuests: () => ({
    type: actionTypes.GUEST_ADD,
    payload: {},
  }),
  processAddGuestsSuccess: () => ({
    type: actionTypes.GUEST_ADD_SUCCESS,
    payload: {},
  }),
  processAddGuestsFailed: () => ({
    type: actionTypes.GUEST_ADD_FAILED,
    payload: {},
  }),
  //==============================================
  processEditGuests: () => ({
    type: actionTypes.GUEST_EDIT,
    payload: {},
  }),
  processEditGuestsSuccess: () => ({
    type: actionTypes.GUEST_EDIT_SUCCESS,
    payload: {},
  }),
  processEditGuestsFailed: () => ({
    type: actionTypes.GUEST_EDIT_FAILED,
    payload: {},
  }),
  //==============================================
  processFilteredGuestsListSuccess: (data) => ({
    type: actionTypes.GUEST_LIST_EVENT_SUCCESS,
    payload: data,
  }),
};
//?saga function

export function* saga() {}
