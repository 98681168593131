import * as yup from 'yup';

export const loginValidator = yup.object().shape({
  username: yup.string().required('First name is required'),
  password: yup.string().required('Password is required'),
});
export const changePasswordValidator = yup.object().shape({
  currentPassword: yup.string().required('Enter the password recieved from email please'),
  password: yup.string().required('Password is required'),
  confirm: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], 'Password does not match'),
});
export const resetPasswordValidator = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirm: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), null], 'Passwords does not match'),
});
export const forgotPasswordValidator = yup.object().shape({
  email: yup.string().required('Email is required').email(),
});

export const addUserValidator = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup.string().required('Password is required'),
  email: yup.string().required('Email is required').email(),
  mobile: yup
    .string()
    .required('Mobile is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Minimum 9 digits'),
  userType: yup.string().required('User type is required'),
  isActive: yup.string().required('Activity status is required'),
});
export const addEventValidator = yup.object().shape({
  name: yup.string().required('Event name is required'),
  location: yup.string().required('location is required'),
  venue: yup.string().required('venue is required'),
  description: yup.string().required('description is required'),
  // startDate: yup.date().required('Event must have a start date'),
  // endDate: yup.date().required('Event must have an end date'),
});
export const addVirtualEventValidator = yup.object().shape({
  name: yup.string().required('Event name is required'),
  description: yup.string().required('description is required'),
  // startDate: yup.date().required('Event must have a start date'),
  // endDate: yup.date().required('Event must have an end date'),
});

export const guestForm = yup.object().shape({
  salutation: yup.string().required('Salutation is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  nationality: yup.string().nullable(),
  country: yup.string().required('Country is required'),
  jobTitle: yup.string().required('Job Title is required'),
  city: yup.string().nullable(),
  phone: yup.string().when('categoryCode', {
    is: 'GUEPRESS',
    then: yup.string(),
  }),
  jobRole: yup.string().when('categoryCode', {
    is: (categoryCode) => categoryCode !== 'GUEPRESS',
    then: yup.string().required('Job Role is required'),
  }),
  mediaOutletName: yup
    .string()
    .nullable()
    .when('categoryCode', {
      is: 'GUEPRESS',
      then: yup.string().required('Media Outlet Name is required'),
    }),
  publishingHouse: yup.string(),
  mediaOutletUrl: yup
    .string()
    .nullable()
    .when('categoryCode', {
      is: 'GUEPRESS',
      then: yup.string().required('URL of MEdia Outlet is required'),
    }),
  linkedin: yup.string(),
  twitter: yup.string(),
  address: yup.string().when('categoryCode', {
    is: 'GUEPRESS',
    then: yup.string().required('Address is required'),
  }),
  articleLinks: yup.array(),
  shortBio: yup.string(),
  industrySector: yup.string().when('categoryCode', {
    is: (categoryCode) => categoryCode !== 'GUEPRESS',
    then: yup.string().required('Industry sector is required'),
  }),
  companyName: yup.string().when('categoryCode', {
    is: (categoryCode) => categoryCode !== 'GUEPRESS',
    then: yup.string().required('Company Name is required'),
  }),
  email: yup.string().required('Email is required').email(),
  contact: yup
    .string()
    .required('Mobile is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Minimum 9 digits'),
  categoryCode: yup.string().required('Category is required'),
});
