/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense } from 'react';
import MainLayout from '../layouts/MainLayout';
import { getUsersLogsAPI, getAllUsersAPI } from '../API/users';
import { getEventsAPI, getAllEventsAPI } from '../API/events';
import {
  getGuestsAPI,
  getAllGuestsEventsAPI,
  getSourceGuestsAPI,
  getCategoryGuestsAPI,
  getJobRoleGuestsAPI,
  guestSourcePerEventAPI,
  guestJobRolePerEventAPI,
  guestTopCountriesApi,
  guestCountryPerEventAPI,
  getIndustrySectorAPI,
} from '../API/guests';
import * as UserLogReducer from '../redux/reducers/userLogs';
import * as UserReducer from '../redux/reducers/users';
import * as EventsReducer from '../redux/reducers/events';
import * as GuestsReducer from '../redux/reducers/guests';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BarChart, Bar, Cell, XAxis } from 'recharts';
import colors from '../constants/colors';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { CSVDownloader } from 'react-papaparse';

import {
  Grid,
  Paper,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Box,
  makeStyles,
  Divider,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { StyledTableCell, StyledTableRow } from '../helpers/helpers';

import { Scrollbars } from 'rc-scrollbars';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  margin: {
    marginTop: '10px',
  },
  marginR: {
    marginRight: '25px',
  },
  tableRowStyle: {
    backgroundColor: theme.palette.background.dark,
    borderBottom: '#5D5D5D',
    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
    '& .MuiTableCell-root': {
      color: '#A5A5A6',
    },
    '& .MuiIconButton-label': {
      color: '#e3e3e4',
    },
    '& .image-rounded': {
      borderRadius: '5px',
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
    background: 'transparent',
  },
  table: {
    minWidth: 750,
  },

  pageTitle: {
    color: '#FFF',
  },
  icons: {
    fontSize: '75px',
    color: 'white',
  },
  card: {
    background: theme.palette.background.main,
    width: '100%',
  },
  statsCard: {
    width: '95%',
  },
  iconBox: {
    background: theme.palette.text.primary,
  },
  cardHeader: {
    backgroundColor: `${colors.primary} !important`,
    color: colors.backgroundColor,
    height: '50%',
  },
  tab: {
    backgroundColor: `${colors.primary} !important`,
    color: `${colors.backgroundColor} !important`,
    borderTop: 'solid 1px',
    borderRight: 'solid 1px',
    borderLeft: 'solid 1px',
    borderRadius: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
    marginBottom: '-5px',
  },
  active: {
    backgroundColor: `${colors.paper} !important`,
    color: `${colors.textPrimary} !important`,

    borderRadius: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
    marginBottom: '-5px',
  },
  tabs: {
    width: '80%',
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '50px',
  },
  circlePrimary: {
    backgroundColor: colors.primary,
  },
  circleError: {
    backgroundColor: colors.error,
  },
  circleWarning: {
    backgroundColor: colors.warning,
  },
  circleSecondary: {
    backgroundColor: colors.secondary,
  },
  horizontalCirclesParagraph: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    // justifyContent: 'flex-end',
  },
  cursor: {
    cursor: 'pointer',
  },
  selectClass: {
    // width: '250%',
    width: '100%',
  },
  registrationBox: {
    // border: 'solid 1px white',
    backgroundColor: colors.paper,
    // width: '100%',
    padding: '15px',
    // borderRadius: 5,
  },
  registrationGrid: {
    backgroundColor: colors.backgroundColor,
    padding: 10,
    borderRadius: 5,
    boxShadow: '5 10',
  },
  divider: {
    background: colors.divider,
    color: colors.divider,
    border: 'solid 3px',
    borderRadius: 5,
  },
  scroll: {
    '& .rc-scrollbars-thumb': {
      backgroundColor: '#e8eae6 !important',
      height: '10px',
      width: '10px',
    },
    '& .rc-scrollbars-thumb-h': {
      height: '0% !important',
      display: 'none !important',
    },
    '& .rc-scrollbars-thumb-v': {},
  },
  topCountriesClass: {
    position: 'absolute',
    bottom: '9%',
    left: '0%',
    backgroundColor: 'black',
    width: '250px',
    // height: '350px',
    height: '265px',
    borderRadius: 5,
    overFlowX: 'hidden',
  },
  countriesExportBtn: {
    position: 'absolute',
    width: '150px',
    marginLeft: 'auto',
    // marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    bottom: 0,
  },
  countryClass: {
    backgroundColor: colors.paper,
    width: '220px',
    marginBottom: '10px',
    padding: '10px',
    borderRadius: 5,
  },
  topCountriesTypo: {
    marginBottom: '10px',
  },
  fallback: {
    padding: '10px',
  },
  exportBtn: {
    marginTop: '10px',
  },
  exportBtnDisabled: {
    marginTop: '10px',
    backgroundColor: '#fcfaf2',
    cursor: 'none',
  },
}));

const tableHeadData = ['First Name', 'Last Name', 'Code', 'Action', 'Date and Time'];

const Dashboard = (props) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [adminValue, setAdminValue] = useState(0);
  const [usersValue, setUsersValue] = useState(0);
  const [viewerValue, setViewerValue] = useState(0);
  // I need to find a way to updaete this state
  const [graphData, setGraphData] = useState([]);
  const [guestAttendeesData, setGuestAttendeesData] = useState(null);
  const [activeButton, setActiveButton] = useState(0);
  const [selectedEvent, setSelcetedEvent] = useState(null);
  const [categoriesData, setCategoriesData] = useState(null);
  const [categoriesBorderColor, setCategoriesBorderColor] = useState(null);
  const [showCategoriesData, setShowCategoriesData] = useState(false);
  const [upcomingEvents, setupcomingEvents] = useState([]);
  const [menuItemVal, setMenuItemVal] = useState(null);
  const [memoize, setMemoize] = useState(true);
  const [guestSource, setGuestSource] = useState([]);
  const [guestCategory, setGuestCategory] = useState([]);
  const [guestJobRole, setGuestJobRole] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const [industryData, setIndustryData] = useState(null);

  // const MemoizedRAdial = React.lazy(() => import('./charts/radial'));
  // const JobChart = React.lazy(() => import('./charts/jobChart'));
  const Map = React.lazy(() => import('./mapCharts'));
  const Charts = React.lazy(() => import('./charts'));
  const Industry = React.lazy(() => import('./charts/industry'));
  const RegistrationPie = React.lazy(() => import('../components/charts/source'));

  const today = new Date();
  // test filtering the events
  const history = useHistory();

  const fetchUserLogs = () => {
    // call the first action
    try {
      props.processFilteredUsersLogs();
      getUsersLogsAPI({
        limit: rowsPerPage,
      })
        .then(({ data }) => {
          props.processFilteredUsersLogsSuccess(data);
        })
        .catch((err) => props.processFilteredUsersLogsFailed());
    } catch (e) {}
  };

  const fetchUsersList = () => {
    try {
      props.processFilteredUsers();
      getAllUsersAPI()
        .then(({ data }) => {
          props.processFilteredUsersSuccess(data);
        })
        .catch((err) => props.processFilteredUsersFailed());
    } catch (e) {}
  };
  const fetchEventsList = () => {
    try {
      props.processFilteredEvents();
      getAllEventsAPI({})
        .then(({ data }) => {
          props.processFilteredEventsSuccess(data);
        })
        .catch((err) => props.processFilteredEventsFailed());
    } catch (e) {}
  };
  const fetchGuestList = () => {
    try {
      props.processFilteredGuests();
      getAllGuestsEventsAPI()
        .then(({ data }) => {
          props.processFilteredGuestsListSuccess(data);
        })
        .catch((err) => props.processFilteredGuestsFailed());
    } catch (e) {}
  };
  const fetchGuestsBySource = () => {
    try {
      let { id } = menuItemVal;
      guestSourcePerEventAPI(id).then(({ data }) => {
        setGuestSource(data);
      });
    } catch (e) {}
  };
  const fetchGuestsByJobRole = () => {
    try {
      let { id } = menuItemVal;
      guestJobRolePerEventAPI(id).then(({ data }) => {
        setGuestJobRole(data);
      });
    } catch (e) {}
  };
  const fetchGuestsTopCountries = () => {
    try {
      let { id } = menuItemVal;
      guestTopCountriesApi(id).then(({ data }) => {
        setTopCountries(data);
      });
    } catch (e) {}
  };
  const fetchCountryGuests = () => {
    try {
      const { id } = menuItemVal;
      guestCountryPerEventAPI(id)
        .then(({ data }) => {
          setCountries(data);
        })
        .catch((e) => {});
    } catch (err) {}
  };
  const fetchGuestsByIndustrySector = () => {
    try {
      const { id } = menuItemVal;
      getIndustrySectorAPI(id)
        .then(({ data }) => {
          // make a state and set the data to the state
          setIndustryData(data);
        })
        .catch((e) => {});
    } catch (err) {}
  };
  const fetchGuestsByCategory = () => {
    try {
      getCategoryGuestsAPI().then(({ data }) => {
        setGuestCategory(data);
      });
    } catch (e) {}
  };

  const fetchGuestListForRedux = () => {
    props.processFilteredGuests();
    getGuestsAPI({})
      .then(({ data }) => {
        // console.log("data:filtered:guest:", data);
        props.processFilteredGuestsSuccess(data);
      })
      .catch((err) => props.processFilteredGuestsFailed());
  };

  const { userLogs, users, guests, events } = useSelector((state) => state);
  useEffect(() => {
    if (guests && guests.guestEvents.length > 0) {
    } else {
      fetchGuestListForRedux();
    }
  }, []);
  const { isFirstLoad } = useSelector((state) => state.events);

  const graphDataShape = (_data, id) => {
    const selected = {
      status: 'Approved',
      number: _data.Status.Selected,
    };

    // const sent = {
    //   status: 'Sent',
    //   number: _data.Status.Sent,
    // };

    const rejected = {
      status: 'Rejected',
      number: _data.Status.Rejected,
    };
    const applied = {
      status: 'Applied',
      number: _data.Status.Applied,
    };
    const notSelected = {
      status: 'Not Selected',
      // number: Math.abs(guests.list.length - (_data.Status.Rejected + _data.Status.Sent + _data.Status.Selected)),
      number: guests.total
        ? Math.abs(guests.total - (_data.Status.Rejected + _data.Status.Sent + _data.Status.Selected))
        : Math.abs(guests.list.length - (_data.Status.Rejected + _data.Status.Sent + _data.Status.Selected)),
    };
    setGraphData([selected, rejected, applied, notSelected]);
    setActiveButton(id);
    setSelcetedEvent(_data);
    if (showCategoriesData) {
      setShowCategoriesData(false);
    }
  };
  const firstRender = React.useRef();

  useEffect(() => {
    if (!isFirstLoad) {
      viewData();
      firstRender.current = true;
      // setMenuItemVal(guestAttendeesData[0]);
    }
  }, [isFirstLoad]);
  useEffect(() => {
    fetchUserLogs();
    fetchUsersList();
    fetchGuestList();
    fetchEventsList();

    // fetchGuestsByCategory();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (guestAttendeesData && guestAttendeesData.length > 0) {
      graphDataShape(guestAttendeesData[0], activeButton);
      setMenuItemVal(guestAttendeesData[0]);
    } else {
      if (firstRender.current && guestAttendeesData && guestAttendeesData.length === 0) {
        viewData();
        firstRender.current = false;
        setLoadedData(true);
      }
    }

    // eslint-disable-next-line
  }, [guestAttendeesData]);

  useEffect(() => {
    if (loadedData) {
      viewData();
    }
  }, [loadedData]);
  // useEffect(() => {
  //   viewData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [events]);
  useEffect(() => {
    if (menuItemVal) {
      fetchGuestsBySource();
      fetchGuestsByJobRole();
      fetchGuestsTopCountries();
      fetchCountryGuests();
      fetchGuestsByIndustrySector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemVal]);

  const viewData = () => {
    let GuestAttendeesData = [];

    let eventsClone = events.list;

    //! put this back in case they wanted futureEvents to be upcoming events only rather than all events
    // let futureEvents = eventsClone.filter((_event) => new Date(_event.startDate).getTime() > new Date(today).getTime());
    let futureEvents = eventsClone;
    // eslint-disable-next-line no-sequences

    futureEvents.map(
      // eslint-disable-next-line no-sequences
      (_event) => ((_event['Attending'] = 0), (_event['Status'] = { Selected: 0, Sent: 0, Rejected: 0, Applied: 0 }))
    );

    // take the ids out of the future events
    let ids = futureEvents.map((_event) => _event.id);

    // loop over the guestEvents, based on the event id, update Attending and status

    for (let i = 0; i < guests.guestEvents.length; i++) {
      if (ids.includes(guests.guestEvents[i].eventId)) {
        for (let j = 0; j < futureEvents.length; j++) {
          if (futureEvents[j].id === guests.guestEvents[i].eventId) {
            futureEvents[j]['Attending'] += 1;
            // update status
            if (guests.guestEvents[i].status) {
              if (guests.guestEvents[i].status === 'Approved') {
                futureEvents[j]['Status'].Selected += 1;
              } else if (guests.guestEvents[i].status === 'Rejected') {
                futureEvents[j]['Status'].Rejected += 1;
              } else if (guests.guestEvents[i].status === 'Applied') {
                futureEvents[j]['Status'].Applied += 1;
              } else {
                futureEvents[j]['Status'].Sent += 1;
              }
            }
            GuestAttendeesData.push(futureEvents[j]);
          }
        }
      }
    }
    setTimeout(() => {
      GuestAttendeesData = new Set(GuestAttendeesData);
      GuestAttendeesData = Array.from(GuestAttendeesData);
      setGuestAttendeesData(GuestAttendeesData);
      setupcomingEvents(futureEvents);
    }, 500);
  };

  const updateIconsValues = () => {
    const vals = [{ admin: 0 }, { user: 0 }, { viewer: 0 }];
    users.list.forEach((_data) => {
      if (_data.userType === 1) {
        vals[0].admin += 1;
      } else if (_data.userType === 0) {
        vals[1].user += 1;
      } else {
        vals[2].viewer += 1;
      }
    });
    setAdminValue(vals[0].admin);
    setViewerValue(vals[2].viewer);
    setUsersValue(vals[1].user);
  };
  const showTime = (dateAndTime) => {
    let date = moment().format('MMMM Do YYYY', dateAndTime);
    let time = dateAndTime.slice(11, 16);
    let hours = Number(time.slice(0, 2)) + 4;
    let minutes = time.slice(2);
    time = hours + minutes;
    return `${date}  ${time}`;
  };
  useEffect(() => {
    updateIconsValues();
    // eslint-disable-next-line
  }, [users]);

  const statsCards = [
    {
      name: 'Admin',
      icon: (
        <Box clone p={0.5} borderRadius='borderRadius'>
          <Tooltip title='Admin'>
            <StarsIcon className={classes.icons} />
          </Tooltip>
        </Box>
      ),
      value: adminValue,
    },
    {
      name: 'User',
      icon: (
        <Box clone p={0.5} borderRadius='borderRadius'>
          <Tooltip title='User'>
            <BorderColorIcon className={classes.icons} />
          </Tooltip>
        </Box>
      ),
      value: usersValue,
    },
    {
      name: 'Viewer',
      icon: (
        <Box clone p={0.5} borderRadius='borderRadius'>
          <Tooltip title='Viewer'>
            <VisibilityIcon className={classes.icons} />
          </Tooltip>
        </Box>
      ),
      value: viewerValue,
    },
  ];

  const showCategories = (status) => {
    if (status !== 'Not Selected') {
      let catData = {};

      let guestEvents = events.guestEvents;
      for (let i = 0; i < guestEvents.length; i++) {
        if (guestEvents[i].eventId === selectedEvent.id) {
          if (guestEvents[i].status === status) {
            catData[guestEvents[i].categories.name]
              ? catData[guestEvents[i].categories.name]++
              : (catData[guestEvents[i].categories.name] = 1);
          }
        }
      }
      setCategoriesData(catData);
      switch (status) {
        case 'Approved':
          setCategoriesBorderColor(colors.secondary);
          break;
        case 'Sent':
          setCategoriesBorderColor(colors.primary);
          break;
        case 'Applied':
          setCategoriesBorderColor(colors.primary);
          break;
        case 'Rejected':
          setCategoriesBorderColor(colors.error);
          break;
        default:
          return;
      }
      setShowCategoriesData(true);
    } else {
      setShowCategoriesData(false);
    }
  };
  const handleChange = (event) => {
    graphDataShape(event.target.value);
    setMenuItemVal(event.target.value);
    fetchGuestsBySource();
    fetchGuestsByJobRole();
    fetchGuestsTopCountries();
    fetchCountryGuests();
    fetchGuestsByIndustrySector();
  };

  return (
    <MainLayout>
      <Box mt={10} ml={10}>
        {/* // ! dashboard box */}
        <Box mb={2}>
          <Typography color='inherit' variant='h4' className={classes.pageTitle}>
            <Box fontWeight='bold'>DashBoard</Box>
          </Typography>
          <Divider component='hr' orientation='horizontal' variant='fullWidth' />
        </Box>

        {/* //! main grid */}
        <Grid container spacing={3}>
          {/* //! invitation status */}
          <Grid item xs={11}>
            <Typography>
              <Box fontWeight='fontWeightBold'>Invitation Status (Per event)</Box>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {menuItemVal && (
              <>
                <FormControl variant='outlined' className={classes.select}>
                  <InputLabel id='event-label'></InputLabel>
                  <Select
                    className={classes.selectClass}
                    labelId=''
                    id='IDID'
                    name='eventMenu'
                    value={menuItemVal}
                    onChange={handleChange}
                    fullWidth
                    required>
                    {guestAttendeesData &&
                      guestAttendeesData.length > 0 &&
                      guestAttendeesData.map((_event, idx) => {
                        return (
                          <MenuItem id='idMenu' value={_event}>
                            {_event.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <Box clone ml={3} mt={1}>
                  <CSVDownloader
                    filename={`Registration By Invitation data for ${menuItemVal.name} Event `}
                    data={graphData}>
                    <Tooltip title='Download Registration By Invitation Status'>
                      <Button variant='contained' color='primary' className={classes.exportBtn}>
                        <Box fontWeight='fontWeightBold'>Export</Box>
                      </Button>
                    </Tooltip>
                  </CSVDownloader>
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} style={{ zIndex: 1 }}>
            <Paper className={classes.fallback}>
              {menuItemVal ? (
                <Suspense
                  fallback={
                    <Box p={2}>
                      <LinearProgress color='primary' />
                    </Box>
                  }>
                  {graphData.length > 0 ? (
                    <Grid item container xs={12}>
                      <Grid item container xs={7} alignContent='space-between' justify='center'>
                        {/* <Grid item xs={2} className={classes.horizontalCirclesParagraph}>
                        <Box className={`${classes.circle} ${classes.circlePrimary}`} mr={1}></Box>
                        <Typography color='textSecondary'>Sent</Typography>
                      </Grid> */}
                        <Grid item xs={2} className={classes.horizontalCirclesParagraph}>
                          <Box className={`${classes.circle} ${classes.circleSecondary}`} mr={1}></Box>
                          <Typography className='textSecondary'>Approved</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.horizontalCirclesParagraph}>
                          <Box className={`${classes.circle} ${classes.circleError}`} mr={1}></Box>
                          <Typography className='textSecondary'>Rejected</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.horizontalCirclesParagraph}>
                          <Box className={`${classes.circle} ${classes.circlePrimary}`} mr={1}></Box>
                          <Typography className='textSecondary'>Applied</Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.horizontalCirclesParagraph}>
                          <Box className={`${classes.circle} ${classes.circleWarning}`} mr={2} width={1}></Box>
                          <Typography className='textSecondary'>Not Selected</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={5}>
                        <Box mt={1} fontWeight='fontWeightBold' align='left'>
                          <Typography variant='subtitle1'>Categories (Per status)</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Box p={2}>
                          <LinearProgress color='primary' />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Suspense>
              ) : (
                <Typography>
                  <Box fontWeight='fontWeightBold'>
                    Graphs are empty at the moment because no guests are invited to any events
                  </Box>
                </Typography>
              )}

              {/* //! Invatation graphs */}

              <Grid item container xs={12}>
                <Grid item container xs={7} justify='center' className={classes.margin}>
                  <Box mb={1} className={classes.cursor}>
                    <Grid item xs={2}>
                      <BarChart width={300} height={200} data={graphData}>
                        {graphData.length > 0 && (
                          <XAxis
                            dataKey='number'
                            stroke='#FFF'
                            axisLine={false}
                            tickLine={false}
                            tickSize='12'
                            fontSize='20'
                          />
                        )}
                        <Bar dataKey='number'>
                          {graphData.map((_data) => (
                            <Cell
                              onClick={() => showCategories(_data.status)}
                              cursor='pointer'
                              fill={
                                _data.status === 'Applied'
                                  ? `${colors.primary}`
                                  : _data.status === 'Approved'
                                  ? `${colors.secondary}`
                                  : _data.status === 'Rejected'
                                  ? `${colors.error}`
                                  : `${colors.warning}`
                              }
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {showCategoriesData && (
                    <Box mt={2} width={1}>
                      <Box borderLeft={20} borderColor={categoriesBorderColor}>
                        <Card style={{ backgroundColor: colors.backgroundColor }}>
                          <CardContent>
                            <List>
                              {categoriesData &&
                                Object.keys(categoriesData).map((_data, idx) => {
                                  return (
                                    <Grid container item>
                                      <Grid item xs={6}>
                                        <ListItem key={idx}>
                                          <ListItemText>{_data}</ListItemText>
                                        </ListItem>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <ListItem>
                                          <ListItemText>{categoriesData[_data]}</ListItemText>
                                        </ListItem>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </List>
                          </CardContent>
                        </Card>
                      </Box>
                    </Box>
                  )}
                </Grid>
                {graphData.length > 0 && (
                  <Grid item container xs={6} justify='center'>
                    <Box mt={1}>
                      <Typography variant='body1' color='textPrimary'>
                        Click on the graph to check categories per status
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          {/*  // ? INdustry Charts should be here */}
          <Suspense
            fallback={
              <Box p={2}>
                <LinearProgress color='primary' />
              </Box>
            }>
            <Industry chartData={industryData} />
          </Suspense>

          {/* //! Registration by source */}
          {/* <Grid item container xs={12}>
            <Grid item xs={4}>
              <Box mb={2} ml={1}>
                <Typography>
                  <Box fontWeight='fontWeightBold'>Registration By Source</Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <Box fontWeight='fontWeightBold' mb={2} ml={3}>
                  Registration By Job Role
                </Box>
              </Typography>
            </Grid>
            <Box mt={2} clone pt={1}>
              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Paper>
                    <Suspense
                      fallback={
                        <Box p={2}>
                          <LinearProgress color='primary' />
                        </Box>
                      }>
                      <MemoizedRAdial memo={memoize} />
                    </Suspense>
                  </Paper>
                </Grid>
                <Grid item xs={8}>
                  <Box ml={3}>
                    <Paper>
                      <Suspense
                        fallback={
                          <Box p={2}>
                            <LinearProgress color='secondary' />
                          </Box>
                        }>
                        <MemoizedJobChart memo={memoize} />
                      </Suspense>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}
          {/* //? End Registration by source */}
          {/* //? New Registration by source Design */}
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Box mb={2} ml={1}>
                <Typography>
                  <Box fontWeight='fontWeightBold'>Registration By Source</Box>
                </Typography>
              </Box>
            </Grid>
            {/* ! The ydont want this now */}
            {/* <Grid item xs={4}>
              <Box mb={2} ml={1}>
                <Typography>
                  <Box fontWeight='fontWeightBold'>Registration By Categories</Box>
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={6}>
              <Box mb={2} ml={1}>
                <Typography>
                  <Box fontWeight='fontWeightBold'>Registration By Job Role</Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {menuItemVal ? (
            <Grid item container xs={12} spacing={3} justify='space-between'>
              <>
                <Suspense
                  fallback={
                    <Box p={2}>
                      <LinearProgress color='secondary' />
                    </Box>
                  }>
                  <RegistrationPie
                    chartData={guestSource}
                    btnText='Export'
                    tooltip='Download Registration By Source Data'
                    title={`Registration By Source data for ${menuItemVal.name} Event `}
                  />
                </Suspense>

                <Suspense
                  fallback={
                    <Box p={2}>
                      <LinearProgress color='secondary' />
                    </Box>
                  }>
                  <RegistrationPie
                    chartData={guestJobRole}
                    btnText='Export '
                    tooltip='Download Registration By Job Role Data'
                    title={`Registration By Job Role data for ${menuItemVal.name} Event `}
                  />
                </Suspense>
              </>
            </Grid>
          ) : (
            <Grid item container xs={12} spacing={3} justify='space-between'>
              <>
                <Grid item xs={6}>
                  <Paper className={classes.fallback}>
                    <Typography>
                      <Box fontWeight='fontWeightBold'>Registration by source is empty at the moment</Box>
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.fallback}>
                    <Typography>
                      <Box fontWeight='fontWeightBold'>Registration by job role is empty at the moment</Box>
                    </Typography>
                  </Paper>
                </Grid>
              </>
            </Grid>
          )}

          {/* <Grid item container xs={12} spacing={2}>
            <RegistrationPie chartData={guestSource} />
          </Grid> */}

          {/* //! Map */}
          <Grid item xs={12}>
            <Typography>
              <Box mb={2} ml={1} fontWeight='fontWeightBold'>
                Registration By country
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box clone ml={0} style={{ position: 'relative' }}>
              <Paper>
                <Suspense
                  fallback={
                    <Box p={2}>
                      <LinearProgress color='secondary' />
                    </Box>
                  }>
                  <Map countries={countries} />
                  {countries.length > 0 ? (
                    <CSVDownloader
                      filename={`Registration By Country data for ${menuItemVal ? menuItemVal.name : ''} Event `}
                      data={countries}>
                      <Tooltip title='Download Registration By Country Data'>
                        <Button variant='contained' color='primary' className={classes.countriesExportBtn}>
                          <Box fontWeight='fontWeightBold'>Export </Box>
                        </Button>
                      </Tooltip>
                    </CSVDownloader>
                  ) : (
                    <Button
                      variant='contained'
                      className={`${classes.countriesExportBtn} ${classes.exportBtnDisabled}`}>
                      <Box fontWeight='fontWeightBold'>Export </Box>
                    </Button>
                  )}

                  {topCountries.length > 0 && (
                    <Box className={classes.topCountriesClass} margin={2} padding={2}>
                      <Scrollbars
                        style={{ width: '107%', height: '250px' }}
                        className={classes.scroll}
                        thumbMinSize={10}>
                        <Typography className={classes.topCountriesTypo}>
                          <Box fontWeight='fontWeightBold'>Top countries</Box>
                        </Typography>

                        {topCountries.map((_data, idx) => {
                          return (
                            <Box
                              className={classes.countryClass}
                              width={1}
                              display='flex'
                              justifyContent='space-between'>
                              <Typography>
                                <Box fontWeight='fontWeightBold'>{_data.Country}</Box>
                              </Typography>
                              <Typography>
                                <Box fontWeight='fontWeightBold'>{_data.Number}</Box>
                              </Typography>
                            </Box>
                          );
                        })}
                      </Scrollbars>
                    </Box>
                  )}
                </Suspense>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <hr className={classes.divider}></hr>
          </Grid>

          {/* //! barcharts index */}
          <Suspense
            fallback={
              <Box p={2}>
                <LinearProgress color='primary' />
              </Box>
            }>
            <Charts />
          </Suspense>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography color='inherit' className={classes.pageTitle}>
                <Box fontWeight='bold'>Events</Box>
              </Typography>
            </Grid>
            {/* //! Cards of  events : this will have to change its position based on the wireframe */}

            {upcomingEvents &&
              upcomingEvents.length > 0 &&
              upcomingEvents.map((_data, idx) => {
                return (
                  idx < 3 && (
                    <Grid item lg={4} xs={12} spacing={1}>
                      <Box mt={1} width={1}>
                        <Card key={idx} className={`${classes.card} ${classes.statsCard}`}>
                          <CardHeader className={classes.cardHeader} title={_data.name}>
                            {_data.name}
                          </CardHeader>
                          <CardContent>
                            <Typography variant='h6'>Attending Guests:</Typography>
                            <Box align='center' mt={1}>
                              <Typography variant='h2'> {_data.Status.Selected}</Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  )
                );
              })}
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Box
                mr={5}
                display='flex'
                flexDirection='row-reverse'
                onClick={() => history.push('/eventslist')}
                className={classes.cursor}>
                <Typography variant='body1'>
                  <Box align='right' fontWeight='fontWeightBold'>
                    view all
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* //! user activity icons */}
          <Grid item xs={12}>
            <Box ml={1}>
              <Typography>
                <Box fontWeight='fontWeightBold'>User Accounts</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {statsCards.map((_data, idx) => {
              return (
                <Grid item container xs={4}>
                  <Box clone mb={2} mt={0}>
                    <Card key={idx} className={classes.card}>
                      <CardContent>
                        <Grid container alignItems='center' justify='space-between'>
                          <Box className={classes.iconBox}>
                            <Grid item xs={6}>
                              {_data.icon}
                            </Grid>
                          </Box>
                          <Grid item xs={6}>
                            <Typography align='right' variant='h2'>
                              <Box fontWeight='fontWeightBold'>{_data.value}</Box>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {/* //! table */}
          <Grid item xs={12}>
            <Typography>
              <Box mb={2} fontWeight='fontWeightBold'>
                Latest Activities
              </Box>
            </Typography>

            <Box>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {tableHeadData.map((_data, idx) => {
                        return <StyledTableCell key={idx}>{_data}</StyledTableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userLogs.list.map((_data, idx) => {
                      return (
                        <StyledTableRow key={idx}>
                          <StyledTableCell>
                            {_data.users && _data.users.firstName ? _data.users.firstName : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {_data.users && _data.users.lastName ? _data.users.lastName : '-'}
                          </StyledTableCell>
                          <StyledTableCell>{_data.users && _data.users.code ? _data.users.code : '-'}</StyledTableCell>
                          <StyledTableCell>
                            {_data.users && _data.description ? _data.description : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {_data.createdAt
                              ? // `${_data.createdAt.slice(0, 10)} -  ${_data.createdAt.slice(11, 16)} ${
                                //     _data.createdAt.slice(11, 13) > 11 ? 'PM' : 'AM'
                                //   } `
                                showTime(_data.createdAt)
                              : '-'}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};
export default React.memo(
  connect(null, {
    ...UserLogReducer.actions,
    ...UserReducer.actions,
    ...EventsReducer.actions,
    ...GuestsReducer.actions,
  })(Dashboard)
);
