import axios from 'axios';

export const GET_EVENTS = 'events';
export const GET_ALL_EVENTS = 'events/allevents';
export const GET_VENUES = 'venues';
export const ADD_EVENT = 'events/add';
export const UPDATE_EVENT = 'events/edit';
export const UPDATE_LOCATION = 'locations/edit';
export const UPDATE_VENUE = 'venues/edit';
export const DELETE_EVENT = 'events/delete';
export const ADD_LOCATION = 'locations/add';
export const ADD_VENUE = 'venues/add';
export const ADD_ATTENDEES = 'events/addAttendees';
export const REJECT_ATTENDEES = 'events/rejectAttendees';
export const SEND_INVITATION = 'events/sendInvitation';
export const EMAIL_QUEUE = 'email-queue';

export const getEventsAPI = (payload) => {
  return axios.post(GET_EVENTS, payload);
};
export const getAllEventsAPI = (payload) => {
  return axios.get(GET_ALL_EVENTS, payload);
};
export const getVenuesAPI = (payload) => {
  return axios.post(GET_VENUES, payload);
};

export const deleteEventsAPI = (codes) => {
  return axios.post(DELETE_EVENT, codes);
};

export const addEventAPI = (payload) => {
  return axios.post(ADD_EVENT, payload);
};
export const editEventAPI = (payload, code) => {
  return axios.post(`${UPDATE_EVENT}/${code}`, payload);
};
export const addLocationAPI = (payload) => {
  return axios.post(ADD_LOCATION, payload);
};
export const editLocationAPI = (payload, code) => {
  return axios.post(`${UPDATE_LOCATION}/${code}`, payload);
};
export const addVenueAPI = (payload) => {
  return axios.post(ADD_VENUE, payload);
};
export const editVenueAPI = (payload, code) => {
  return axios.post(`${UPDATE_VENUE}/${code}`, payload);
};
export const addAttendeesAPI = (payload, code) => {
  // console.log(payload);
  return axios.post(`${ADD_ATTENDEES}/${code}`, payload);
};
export const rejectAttendeesAPI = (payload, code) => {
  // console.log(payload, code);
  return axios.post(`${REJECT_ATTENDEES}/${code}`, payload);
};

export const sendInvitationAPI = (payload, code) => {
  return axios.post(`${SEND_INVITATION}/${code}`, payload);
};
export const emailQueueAPI = (payload) => {
  return axios.post(`${EMAIL_QUEUE}`, payload);
};

// export const addEventAPI = (payload, file) => {
//   // payload: form data  ==>  file
//   let formData = new FormData();
//   formData.append("file", file.file);
//   for (let i in payload) {
//     formData.append(i, payload[i]);
//   }
//   return axios.post(ADD_EVENT, formData, {
//     headers: { "Content-Type": "multipart/form-data" },
//   });
// };
