import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CategoryList from '../modules/category/categorylist';
// import TagList from '../modules/tag/taglist';
// import GuestList from '../modules/guests/guestlist';
import VirtualGuestList from '../modules/virtualguests/virtualguestlist';
import GuestAdd from '../modules/guests/guestadd';
import GuestEdit from '../modules/guests/guestedit';
import Login from '../modules/users/login/login';
import ResetPassword from '../modules/users/resetPassword/resetPassword';
import ForgotPassword from '../modules/users/forgotPassword/forgotPassword';
import ChangePassword from '../modules/users/changePassword/changePassword';
import AddUser from '../modules/users/addUser/addUser';
import UserEdit from '../modules/users/editUser/editUser';
// import EventsList from '../modules/events/eventsList';
import AddEvent from '../modules/events/addEvent';
import AddVirtualEvent from '../modules/events/addVirtualEvent';
import EditEvent from '../modules/events/editEvent';
import EditVirtualEvent from '../modules/events/editVirtualEvent';
import Attendees from '../modules/events/attendees';
// import UsersList from '../modules/users/usersList';
import SupportPage from '../modules/support/support';
import PrivateRoute from './privateRoute';
// import UsersLogs from '../modules/users/userLogs';
import RestrictedRoute from './restrictedRoute';
import Dashboard from '../components/dashboard';
import InvitationStatus from '../modules/invitationStatus';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Box, Grid } from '@material-ui/core';

// lazy loading routes components
const EventsList = lazy(() => import('../modules/events/eventsList'));
const GuestList = lazy(() => import('../modules/guests/guestlist'));
// const VirtualGuestList = lazy(() => import('../modules/virtualguests/virtualguestlist'));
const UsersList = lazy(() => import('../modules/users/usersList'));
const TagList = lazy(() => import('../modules/tag/taglist'));
const UsersLogs = lazy(() => import('../modules/users/userLogs'));

const Rouets = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Box m={50}>
                <LinearProgress color='primary' size={100} />
              </Box>
            </Grid>
          </Grid>
        }>
        <Switch>
          <Route path='/invitation-status/:eventCode/:guestCode/:data'>
            <InvitationStatus />
          </Route>
          <PrivateRoute exact path='/'>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path='/categorylist'>
            <CategoryList />
          </PrivateRoute>
          <PrivateRoute exact path='/taglist'>
            <TagList />
          </PrivateRoute>
          <PrivateRoute exact path='/guestlist'>
            <GuestList />
          </PrivateRoute>
          <PrivateRoute exact path='/virtual-guests'>
            <VirtualGuestList />
          </PrivateRoute>
          <PrivateRoute exact path='/guest/add'>
            <GuestAdd />
          </PrivateRoute>

          <PrivateRoute exact path='/guest/edit/:code'>
            <GuestEdit />
          </PrivateRoute>
          <PrivateRoute exact path='/eventslist'>
            <EventsList />
          </PrivateRoute>
          <PrivateRoute exact path='/events/add'>
            <AddEvent />
          </PrivateRoute>
          <PrivateRoute exact path='/events/add-virtual'>
            <AddVirtualEvent />
          </PrivateRoute>
          <PrivateRoute exact path='/events/attendees'>
            <Attendees />
          </PrivateRoute>
          <RestrictedRoute exact path='/login'>
            <Login />
          </RestrictedRoute>
          <PrivateRoute exact path='/userslist'>
            <UsersList />
          </PrivateRoute>
          <PrivateRoute exact path='/events/edit/:code'>
            <EditEvent />
          </PrivateRoute>
          <PrivateRoute exact path='/events/edit-virtual/:code'>
            <EditVirtualEvent />
          </PrivateRoute>
          <PrivateRoute exact path='/add-user'>
            <AddUser />
          </PrivateRoute>
          <PrivateRoute exact path='/users/edit/:code'>
            <UserEdit />
          </PrivateRoute>
          <PrivateRoute exact path='/userslogs'>
            <UsersLogs />
          </PrivateRoute>
          <RestrictedRoute exact path='/reset-password/:code'>
            <ResetPassword />
          </RestrictedRoute>
          <RestrictedRoute exact path='/forgot-password'>
            <ForgotPassword />
          </RestrictedRoute>
          <PrivateRoute exact path='/change-password'>
            <ChangePassword />
          </PrivateRoute>
          <PrivateRoute exact path='/support'>
            <SupportPage />
          </PrivateRoute>
          <RestrictedRoute exact path='/forgot-password'>
            <ForgotPassword />
          </RestrictedRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Rouets;
