/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import GuestFormFragment from './GuestFormFragment';
import MainLayout from '../../layouts/MainLayout';
import { useParams } from 'react-router-dom';
import EventsPerGuest from './eventsPerGuest';
import { getGuestEventByCode } from '../../API/guests';

function GuestEdit(props) {
  const { list } = useSelector((state) => state.guests);
  const [guest, setGuest] = useState(null);
  const [currentGuestEvents, setCurrentGuestEvents] = useState(null);
  const [currentGuestCategories, setCurrentGuestCategories] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const { code } = useParams();

  useEffect(() => {
    const _guest = list.find((guest) => guest.code === code);
    setGuest(_guest);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let _events = [];
    let _categories = [];
    let _statuses = [];
    getGuestEventByCode(code)
      .then((guestEvents) => {
        guestEvents.data.map((_guestEvent, idx) => {
          _events.push(_guestEvent.events);
          _categories.push(_guestEvent.categories);
          _statuses.push(_guestEvent.status);
        });
        setCurrentGuestEvents(_events);
        setCurrentGuestCategories(_categories);
        setStatuses(_statuses);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line
  }, [guest]);
  return (
    <MainLayout>
      <GuestFormFragment guestData={guest} />
      {currentGuestEvents && currentGuestCategories && statuses && (
        <EventsPerGuest
          guest={guest}
          events={currentGuestEvents}
          categories={currentGuestCategories}
          statuses={statuses}
        />
      )}
    </MainLayout>
  );
}

export default GuestEdit;
