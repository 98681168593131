import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from "./redux/store";
// import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { persistor } from './redux/store';
import setupAxios from './redux/setupAxios';
import axios from 'axios';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

// const persistor = persistStore(store);

setupAxios(axios, store);

// Sentry.init({
//   dsn: 'https://17af2a90083248e6acac0e990dde97c5@o507284.ingest.sentry.io/5791968',
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.REACT_APP_ENV,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
