import axios from 'axios';

export const GET_VIRTUAL_GUESTS_LIST = 'virtual-guests';
export const EXPORT_VIRTUAL_GUEST_LIST = 'virtual-guests/virtual-guests-export';

export const getVirtualGuestsList = (payload) => {
  return axios.post(GET_VIRTUAL_GUESTS_LIST, payload);
};
export function virtualGuestExportList() {
  return axios.post(EXPORT_VIRTUAL_GUEST_LIST, [], {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
  });
}
