import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import { editUserAPI } from '../../../API/users';
import { connect } from 'react-redux';
import * as UserReducer from '../../../redux/reducers/users';
import { addUserValidator } from '../../../validators/validators';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  makeStyles,
  Select,
  MenuItem,
  Card,
  CardContent,
  Paper,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  buttonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.main,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.main,
      opacity: 0.7,
    },
  },
  formControl: {
    width: '100%',
  },
  countryCode: {
    width: '100px',
  },
}));

const EditUser = (props) => {
  const { userData } = props;

  const [errorValidation, setErrorValidation] = useState({});
  const [userCode, setUserCode] = useState(null);

  const classes = useStyles();
  const history = useHistory();

  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    if (userData) {
      setFormValues(userData);
      setUserCode(userData.code);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let errorMessages = {};
    try {
      props.processAddUser();
      addUserValidator
        .validate(formValues, { abortEarly: false })
        .then(() => {
          editUserAPI(formValues, userCode)
            .then(({ data }) => {
              history.push('/userslist');
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          e.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          setErrorValidation(errorMessages);
          console.log('state: validation', errorValidation);
        });
    } catch (e) {
      console.log(e);
    }
  };
  // form

  return (
    <MainLayout>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Card>
            <CardContent>
              <Box xs={12} mb={5}>
                <Typography variant='h6'>
                  <Box fontWeight='fontWeightBold'>Edit USER</Box>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item lg={4} xs={12} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='Name'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.firstName}
                      error={errorValidation.firstName}
                      helperText={errorValidation.firstName}
                      onChange={handleChange}
                      name='firstName'></TextField>
                  </Box>

                  <FormControl fullWidth variant='outlined'>
                    <InputLabel>User Permissions</InputLabel>
                    <Select
                      onChange={handleChange}
                      name='userType'
                      error={errorValidation.userType}
                      value={formValues.userType}>
                      <MenuItem value='0'>User</MenuItem>
                      <MenuItem value='1'>Admin</MenuItem>
                      <MenuItem value='2'>Viewer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item container xs={12} lg={4} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='Last Name'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.lastName}
                      onChange={handleChange}
                      name='lastName'
                      error={errorValidation.lastName}
                      helperText={errorValidation.lastName}></TextField>
                  </Box>
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel>Active Status</InputLabel>
                    <Select
                      onChange={handleChange}
                      name='isActive'
                      error={errorValidation.isActive}
                      value={formValues.isActive}>
                      <MenuItem value='0'>Inactive</MenuItem>
                      <MenuItem value='1'>Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} lg={4} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='Email'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.email}
                      error={errorValidation.email}
                      helperText={errorValidation.email}
                      onChange={handleChange}
                      disabled
                      name='email'></TextField>
                  </Box>
                  <TextField
                    label='Mobile'
                    type='text'
                    variant='filled'
                    fullWidth
                    value={formValues.mobile}
                    error={errorValidation.mobile}
                    helperText={errorValidation.mobile}
                    onChange={handleChange}
                    name='mobile'></TextField>
                </Grid>
              </Grid>
              <Box item align='right' p={2}>
                {Object.keys(formValues).length > 0 &&
                  Object.values(formValues).filter((item) => item !== '').length > 0 && (
                    <Box clone mr={2}>
                      <Button onClick={() => history.goBack()}>Cancel</Button>
                    </Box>
                  )}
                <Button onClick={handleSubmit} className={classes.buttonStyles}>
                  Edit
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </div>
    </MainLayout>
  );
};

export default connect(null, UserReducer.actions)(EditUser);
