import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  Popover,
  Typography,
  Tooltip,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
// import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import QueryBuilderIcon from '@material-ui/icons/WatchLater';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
// import CategoryIcon from "@material-ui/icons/Category";
// import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GmisLogo from '../gmis-logo';
import clsx from 'clsx';
import { handleLogout } from '../helpers/helpers';

const drawerWidth = 240;
const menuData = [
  {
    icon: 'dashboard',
    text: 'Dashboard',
    summary: '',
    expanded: false,
    link: '/',
  },
  {
    icon: 'group',
    text: 'Guest List',
    subText: 'Category',
    summary: 'Categories',
    expanded: true,
    link: '/guestlist',
    subLink: '/categorylist',
  },
  {
    icon: 'virtual-guests',
    text: 'Virtual Guests',
    summary: '',
    expanded: false,
    link: '/virtual-guests',
  },
  // {
  //   icon: "category",
  //   text: "Category",
  //   summary: "Category",
  //   expanded: true,
  //   link: "/categorylist",
  // },
  // {
  //   icon: "tag",
  //   text: "Tag",
  //   summary: "Tag",
  //   expanded: true,
  //   link: "/taglist",
  // },
  {
    icon: 'event',
    text: 'Events',
    summary: 'Attendees',
    expanded: true,
    subText: 'Attendees',
    link: '/eventslist',
    subLink: '/events/attendees',
  },
  {
    icon: 'query-builder',
    text: 'User Logs',
    summary: '',
    expanded: false,
    link: '/userslogs',
  },
  {
    icon: 'account',
    text: 'User Accounts',
    summary: '',
    expanded: false,
    link: '/userslist',
  },
  {
    icon: 'logout',
    text: 'Logout',
    summary: '',
    expanded: false,
    link: '',
  },
  {
    icon: 'headset',
    text: 'Support',
    summary: '',
    expanded: false,
    link: '/support',
  },
];

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  absolutePosition: {
    position: 'absolute',
    right: '-3%',
    top: '50%',
    transform: 'translateY(-65%)',
  },
  navLink: {
    textDecoration: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuIcons: {
    color: theme.palette.primary.main,
    '& :active': {
      color: '#ffffff',
    },
  },
  activeLink: {
    fill: '#ffffff',
    '& path': {
      fill: '#ffffff',
    },
    '& .MuiTypography-root': {
      color: '#ffffff',
    },
  },
  drawerBackground: {
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.paper.default,
    },
  },
  appBarBackground: {
    // "& .MuiAppBar-colorPrimary": {
    backgroundColor: `${theme.palette.background.default} !important`,
    // },
  },
  listitem: {
    '&:hover': {
      backgroundColor: '#333',
    },
  },
}));
const CustomDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [anchorElCat, setAnchorElCat] = useState(null);
  const openPopoverCat = Boolean(anchorElCat);
  const [anchorElAttendee, setAnchorElAttendee] = useState(null);
  const openPopoverAttendee = Boolean(anchorElAttendee);

  const { users } = useSelector((state) => state);
  const CURRENT_USER = users.currentUser;

  const handlePopoverOpenCat = (event) => {
    setAnchorElCat(event.currentTarget);
  };

  const handlePopoverOpenAttendee = (event) => {
    setAnchorElAttendee(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElCat(null);
    setAnchorElAttendee(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const handleLogout = () => {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('persist:users');
  //   localStorage.removeItem('persist:events');
  //   localStorage.removeItem('persist:guests');
  //   localStorage.removeItem('persist:tags');
  //   localStorage.removeItem('persist:categories');
  //   window.location.reload();
  // };

  return (
    <>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, classes.appBarBackground, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, classes.menuIcons, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={10}>
              <GmisLogo width='238px' viewBox='0 0 238 50' />
            </Grid>
            <Box display={{ xs: 'none', sm: 'block' }} clone>
              <Grid item xs={2}>
                <NavLink className={classes.navLink} to=''>
                  <Box clone width={1} mt={2}>
                    <ListItemIcon onClick={handleLogout}>
                      <Grid container>
                        <Box position='relative' clone>
                          <Grid item xs={8} sm={6} md={8} lg={9}>
                            <Tooltip title='Logout'>
                              <ExitToAppIcon
                                className={[classes.menuIcons, classes.absolutePosition].join(' ')}></ExitToAppIcon>
                            </Tooltip>
                          </Grid>
                        </Box>
                        <Grid item xs={4} sm={6} md={4} lg={3}>
                          <Box ml={1} style={{ textAlign: 'end' }}>
                            <ListItemText className={classes.menuIcons}>Logout</ListItemText>
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItemIcon>
                  </Box>
                </NavLink>
              </Grid>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        elevation={5}
        variant='permanent'
        className={clsx(classes.drawer, classes.drawerBackground, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className={classes.menuIcons} />
            ) : (
              <ChevronLeftIcon className={classes.menuIcons} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuData.map((_data, idx) => (
            <ListItem button key={idx} className={classes.listitem}>
              {_data.icon === 'dashboard' ? (
                <Tooltip title='Dashboard'>
                  <NavLink
                    className={classes.navLink}
                    exact
                    to={_data.link}
                    activeClassName={classes.activeLink}
                    key={idx}>
                    <ListItemIcon>
                      <Box ml={1}>
                        <DashboardIcon className={classes.menuIcons}></DashboardIcon>
                      </Box>
                      <Box ml={4}>
                        <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                      </Box>
                    </ListItemIcon>
                  </NavLink>
                </Tooltip>
              ) : _data.icon === 'group' ? (
                <Tooltip title='Guests and Categories'>
                  <Box mt={3}>
                    <Popover
                      id='category-id'
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={openPopoverCat}
                      anchorEl={anchorElCat}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus>
                      {/* <Typography className={classes.typography}>
                      {`Expand for Categories ${CURRENT_USER.userType}`}
                    </Typography> */}
                    </Popover>
                    <Box>
                      <NavLink
                        className={classes.navLink}
                        to={_data.link}
                        activeClassName={classes.activeLink}
                        key={idx}>
                        <ListItemIcon key={idx}>
                          <Box ml={1}>
                            <GroupIcon
                              aria-describedby='category-id'
                              aria-owns={openPopoverCat ? 'category-id' : undefined}
                              aria-haspopup='true'
                              onMouseEnter={handlePopoverOpenCat}
                              onMouseLeave={handlePopoverClose}
                              className={classes.menuIcons}></GroupIcon>
                          </Box>
                          <Box ml={4}>
                            <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                            <NavLink className={classes.navLink} to={_data.subLink}>
                              <Typography className={classes.menuIcons} variant='caption'>
                                {_data.subText}
                              </Typography>
                            </NavLink>
                          </Box>
                        </ListItemIcon>
                      </NavLink>
                    </Box>
                  </Box>
                </Tooltip>
              ) : _data.icon === 'virtual-guests' && CURRENT_USER.userType !== 2 ? (
                <Tooltip title='Virtual Guests'>
                  <NavLink className={classes.navLink} to={_data.link} activeClassName={classes.activeLink} key={idx}>
                    <ListItemIcon key={idx}>
                      <Box ml={1}>
                        <PeopleAltOutlinedIcon className={classes.menuIcons}></PeopleAltOutlinedIcon>
                      </Box>
                      <Box ml={4}>
                        <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                      </Box>
                    </ListItemIcon>
                  </NavLink>
                </Tooltip>
              ) : _data.icon === 'event' ? (
                <Tooltip title='Events and Attendees'>
                  <Box>
                    <Popover
                      id='attendee-id'
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={openPopoverAttendee}
                      anchorEl={anchorElAttendee}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus>
                      {/* <Typography className={classes.typography}>Expand for Attendees</Typography> */}
                    </Popover>
                    <NavLink className={classes.navLink} to={_data.link} activeClassName={classes.activeLink} key={idx}>
                      <ListItemIcon key={idx}>
                        <Box ml={1}>
                          <EventIcon
                            aria-describedby='attendee-id'
                            aria-owns={openPopoverAttendee ? 'attendee-id' : undefined}
                            aria-haspopup='true'
                            onMouseEnter={handlePopoverOpenAttendee}
                            onMouseLeave={handlePopoverClose}
                            className={classes.menuIcons}></EventIcon>
                        </Box>
                        <Box ml={4}>
                          <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                          <NavLink className={classes.navLink} to={_data.subLink}>
                            <Typography className={classes.menuIcons} variant='caption'>
                              {_data.subText}
                            </Typography>
                          </NavLink>
                        </Box>
                      </ListItemIcon>
                    </NavLink>
                  </Box>
                </Tooltip>
              ) : _data.icon === 'query-builder' && CURRENT_USER.userType !== 2 ? (
                <Tooltip title='User Logs'>
                  <NavLink className={classes.navLink} to={_data.link} activeClassName={classes.activeLink} key={idx}>
                    <ListItemIcon key={idx}>
                      <Box ml={1}>
                        <QueryBuilderIcon className={classes.menuIcons}></QueryBuilderIcon>
                      </Box>
                      <Box ml={4}>
                        <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                      </Box>
                    </ListItemIcon>
                  </NavLink>
                </Tooltip>
              ) : _data.icon === 'account' ? (
                <Box mt={3}>
                  <Tooltip title='Users List'>
                    <NavLink className={classes.navLink} to={_data.link} activeClassName={classes.activeLink} key={idx}>
                      <ListItemIcon key={idx}>
                        <Box ml={1}>
                          <AccountBoxIcon className={classes.menuIcons}></AccountBoxIcon>
                        </Box>
                        <Box ml={4}>
                          <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                        </Box>
                      </ListItemIcon>
                    </NavLink>
                  </Tooltip>
                </Box>
              ) : _data.icon === 'headset' ? (
                <Tooltip title='Support'>
                  <NavLink className={classes.navLink} to={_data.link} activeClassName={classes.activeLink} key={idx}>
                    <Box mt={{ xs: 5, sm: 10 }}>
                      <ListItemIcon key={idx}>
                        <Box ml={1}>
                          <HeadsetMicIcon className={classes.menuIcons}></HeadsetMicIcon>
                        </Box>
                        <Box ml={4}>
                          <ListItemText className={classes.menuIcons}>{_data.text}</ListItemText>
                        </Box>
                      </ListItemIcon>
                    </Box>
                  </NavLink>
                </Tooltip>
              ) : null}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};
export default CustomDrawer;
