import React from "react";
import GuestFormFragment from "./GuestFormFragment";
import MainLayout from "../../layouts/MainLayout";

const GuestAdd = (props) => {
  return (
    <MainLayout>
      <GuestFormFragment />
    </MainLayout>
  );
};

export default GuestAdd;
