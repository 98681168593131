const colors = {
  // backgroundColor: '#1f1f22',
  primary: '#A4C627',
  secondary: '#A6B3F1',
  error: '#Cf6679',
  disabled: '#fcfaf2',
  warning: '#Cf9266',
  textSecondary: '#A5A5A5',
  backgroundColorGray: '#2D2E31',
  lightDivider: '#3f4043',
  textPrimary: '#E2E2E2',
  divider: '#5D5D5D',
  textDisabled: '#747476',
  backgroundColor: '#111215',
  backgroundColorDarkLight: '#19191D',
  paper: '#1F1F22',
};
export default colors;
