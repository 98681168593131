/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Box,
  Button,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Paper,
  Checkbox,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import MainLayout from '../../layouts/MainLayout';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { editEventAPI } from '../../API/events';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddAgenda from '../agendas/addAgenda';
import AgendaList from '../agendas/listAgenda';
import colors from '../../constants/colors';
import { addVirtualEventValidator } from '../../validators/validators';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  cardStyles: {
    minWidth: 450,
  },

  buttonStyles: {
    backgroundColor: '#A4C627',
    color: '#19191D',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#A4C627',
      color: '#19191D',
      opacity: 0.7,
    },
  },
  alert: {
    transitionTimingFunction: 'ease',
    transition: '0.2s',
  },
}));

const EditEvent = (props) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const [errorValidation, setErrorValidation] = useState({});
  const [dateFromPicked, setDateFromPicked] = useState(new Date());
  const [dateToPicked, setDateToPicked] = useState(new Date());
  const [eventCode, setEventCode] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [agendaFormValues, setAgendaFormValues] = useState({});
  const [selected, setSelected] = useState([]);
  const [dateOfEvent, setDateOfEvent] = useState(null);
  const [checked, setChecked] = useState(false);

  const { code } = useParams();
  const { events } = useSelector((state) => state);
  const eventsList = events.list;
  const event = eventsList.filter((event) => event.code === code);
  const history = useHistory();

  useEffect(() => {
    const eventData = {
      name: event[0].name && event[0].name,
      startDate: event[0].startDate && event[0].startDate.slice(0, 10),
      endDate: event[0].endDate && event[0].endDate.slice(0, 10),
      description: event[0].description && event[0].description,
      inWebsite: event[0].inWebsite && event[0].inWebsite,
    };

    setFormValues(eventData);
    setEventCode(code);
    // console.log(eventData);
    setChecked(eventData.inWebsite);
    //eslint-disable-next-line
  }, []);

  const handleDateFromPicker = (id) => (e) => {
    setDateFromPicked(e);
    setFormValues({
      ...formValues,
      [id]: e.toISOString(),
    });
  };
  const handleDateToPicker = (id) => (e) => {
    setDateToPicked(e);
    setFormValues({
      ...formValues,
      [id]: e.toISOString(),
    });
  };
  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
    const { name, checked } = event.target;
    // console.log(checked);
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setErrorValidation({
      ...errorValidation,
      [name]: null,
    });
  };
  const viewAlertSuccess = () => {
    setShowAlert(true);
    setAlertMessage('Event Edited Successfuly');
    setAlertSeverity('success');
  };
  const viewAlertFailed = () => {
    setShowAlert(true);
    setAlertMessage('Something went wrong');
    setAlertSeverity('error');
  };
  const removeAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage('');
      setAlertSeverity('');
    }, 3000);
  };

  const handleSubmit = () => {
    let errorMessages = {};
    try {
      const { name, startDate, endDate, description, inWebsite } = formValues;
      addVirtualEventValidator
        .validate({ name, description }, { abortEarly: false })
        .then(() => {
          editEventAPI({ name, startDate, endDate, description, inWebsite }, code)
            .then(({ data }) => {
              viewAlertSuccess();
              removeAlert();
            })
            .catch((e) => {
              console.log(e);
              viewAlertFailed();
              removeAlert();
            });
        })
        .catch((e) => {
          console.log('e:', e, e.inner);
          //fill the validation here
          e.inner.forEach((err) => {
            console.log('err', err);
            errorMessages[err.path] = err.message;
          });
          setErrorValidation(errorMessages);
          console.log('state: validation', errorValidation);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const clearForm = () => {
    setFormValues({
      name: '',
      startDate: '',
      endDate: '',
      description: '',
      inWebsite: '',
    });

    setErrorValidation({});
  };
  const updateAgenda = (data) => {
    setAgendaFormValues(data);
  };

  return (
    <MainLayout>
      <Box mt={10} ml={10}>
        <Box ml={1}>
          {/* <CardContent> */}
          <Box xs={12} mb={5}>
            <Typography variant='h6'>
              {event[0].name ? (
                <Box fontWeight='fontWeightBold'>{`Edit EVENT ${event[0].name.toUpperCase()}: ${event[0].code}`}</Box>
              ) : (
                <Box fontWeight='fontWeightBold'>Edit EVENT</Box>
              )}
            </Typography>
          </Box>
          <Grid container>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Box mb={2}>
                  <TextField
                    label='Event Name'
                    type='text'
                    variant='filled'
                    fullWidth
                    value={formValues.name}
                    onChange={handleChange}
                    name='name'
                    error={errorValidation.name}
                    helperText={errorValidation.name}></TextField>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box mr={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      id='startDate'
                      value={formValues.startDate}
                      label='FROM'
                      name='from'
                      disablePast={true}
                      error={errorValidation.startDate}
                      helperText={errorValidation.startDate}
                      autoOk
                      error={errorValidation.startDate}
                      helperText={errorValidation.startDate}
                      onChange={handleDateFromPicker('startDate')}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='endDate'
                    value={formValues.endDate}
                    label='TO'
                    name='to'
                    disablePast={true}
                    minDate={formValues.startDate}
                    error={errorValidation.endDate}
                    helperText={errorValidation.endDate}
                    autoOk
                    error={errorValidation.endDate}
                    helperText={errorValidation.endDate}
                    onChange={handleDateToPicker('endDate')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <TextField
                  label='Description'
                  type='text'
                  variant='filled'
                  fullWidth
                  multiline
                  rows={5}
                  value={formValues.description}
                  onChange={handleChange}
                  name='description'
                  error={errorValidation.description}
                  helperText={errorValidation.description}></TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckBox}
                  name='inWebsite'
                  value={checked}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Add to Registration Form
              </Grid>
            </Grid>
          </Grid>
          <Box item align='right' p={2}>
            <Box clone mr={2}>
              <Button>
                <Box fontWeight='fontWeightBold' onClick={() => history.goBack()}>
                  Cancel
                </Box>
              </Button>
            </Box>
            <Button onClick={handleSubmit} className={classes.buttonStyles} variant='contained'>
              <Box fontWeight='fontWeightBold'>Submit</Box>
            </Button>
          </Box>
          {showAlert && (
            <Grid item xs={12}>
              <Box mb={1}>
                <Alert className={classes.alert} severity={alertSeverity}>
                  {alertMessage}
                </Alert>
              </Box>
            </Grid>
          )}
          <AddAgenda
            event={event}
            agenda={agendaFormValues}
            setSelected={setSelected}
            setDateOfEvent={setDateOfEvent}
          />
          <AgendaList
            setEditAgenda={updateAgenda}
            eventCode={code}
            selected={selected}
            setSelected={setSelected}
            dateOfEvent={dateOfEvent}
          />
          {/* </CardContent> */}
        </Box>
      </Box>
    </MainLayout>
  );
};
export default EditEvent;
