import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//? initial state: empty array
const initialState = {
  list: [],
  limit: 20,
  page: 1,
  total: 0,
  loading: false,
};
//? actionTypes:
// getguests ==> addguest ==> deleteguest ==> updateguest
export const actionTypes = {
  VIRTUAL_GUEST_LIST: '[VIRTUAL GUEST] LIST GUESTS',
  VIRTUAL_GUEST_LIST_SUCCESS: '[VIRTUAL GUEST] LIST VIRTUAL GUESTS SUCCESS',
  VIRTUAL_GUEST_LIST_FAILED: '[VIRTUAL GUEST] LIST VIRTUAL GUESTS FAILED',
};
//?reducer with persisteRudcer
export const reducer = persistReducer(
  {
    storage,
    key: 'virtaulGuests',
    whitelist: ['virtualGuests', 'list'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.VIRTUAL_GUEST_LIST:
        return { ...state, loading: true };
      case actionTypes.VIRTUAL_GUEST_LIST_SUCCESS:
        const { data, total, limit, page } = action.payload;
        return { loading: false, list: data, total, limit, page };
      case actionTypes.VIRTUAL_GUEST_LIST_FAILED:
        return { ...state, loading: false };
      //?==============================================
      default:
        return state;
    }
  }
);
//? actions
export const actions = {
  processFilteredVirtualGuests: () => ({
    type: actionTypes.VIRTUAL_GUEST_LIST,
    payload: {},
  }),
  processFilteredVirtualGuestsSuccess: (data) => ({
    type: actionTypes.VIRTUAL_GUEST_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredVirtualGuestsFailed: () => ({
    type: actionTypes.VIRTUAL_GUEST_LIST_FAILED,
    payload: {},
  }),
};
//?saga function

export function* saga() {}
