import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Toolbar,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getGuestsWithGuestEventList, GetSourceAPI, eventGuestsExportList } from '../../API/guests';

import { getCategoriesAPI } from '../../API/categories';
import * as GuestReducer from '../../redux/reducers/guests';
import * as CategoryReducer from '../../redux/reducers/categories';

import { withStyles } from '@material-ui/core/styles';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { addAttendeesAPI, rejectAttendeesAPI, sendInvitationAPI, emailQueueAPI } from '../../API/events';
import MainLayout from '../../layouts/MainLayout';
import {
  getComparator,
  stableSort,
  useStyles,
  StyledTableRow,
  StyledCheckbox,
  useToolbarStyles,
  useStylesTheme,
} from '../../helpers/helpers';
import moment from 'moment';

//*Table cell style
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const classesTheme = useStylesTheme();

  const {
    numSelected,
    onFilterSearch,
    guestCodes,
    selectedEvent,
    eventCodes,
    changeSelectedEvent,
    changeNumSelected,
    setCodes,
    invitationBtn,
    setInvitationStatuses,
    fetchGuestList,
    CURRENT_USER,
    Sources,
    showEmailQueueDialog,
  } = props;
  useEffect(() => {}, [eventCodes]);
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [approveWithPDF, setApproveWithPDF] = useState(false);
  const [approveWithRSVP, setApproveWithRSVP] = useState(false);
  const [emailQueueTemplate, setEmailQueueTemplate] = useState('approvedWithoutRSVP');

  const history = useHistory();

  const { categories } = useSelector((state) => state);

  function handleEventSelect(e) {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setAdvancedFilter({
      category: '',
      status: '',
      tag: '',
      eventId: '',
    });
    changeSelectedEvent(e.target.value);
  }

  const [searchText, setSearchText] = useState('');
  const [value] = useDebounce(searchText, 1000);
  const [advancedFilter, setAdvancedFilter] = useState({
    category: '',
    status: '',
    tag: '',
    eventId: '',
  });
  const _filterRef = useRef(null);

  useEffect(() => {
    if (_filterRef.current) {
      onFilterSearch(value, advancedFilter);
    }
    _filterRef.current = true;
    // eslint-disable-next-line
  }, [value, advancedFilter]);

  const handleChange = (key, value) => {
    switch (key) {
      case 'category':
        setAdvancedFilter({ ...advancedFilter, category: value });
        break;
      case 'status':
        if (value === '') {
          setAdvancedFilter({ ...advancedFilter, status: value, eventId: '' });
        } else {
          setAdvancedFilter({ ...advancedFilter, status: value, eventId: selectedEvent.id });
        }
        break;
      case 'tag':
        setAdvancedFilter({ ...advancedFilter, tag: value });
        break;
      default:
        break;
    }
  };
  //
  if (guestCodes.length < 1) {
    if (approveWithPDF) {
      setApproveWithPDF(false);
    }
    if (approveWithRSVP) {
      setApproveWithRSVP(false);
    }
  }

  const handleAddGuestsToEvent = () => {
    try {
      // update num selected here:
      changeNumSelected([]);
      addAttendeesAPI({ codes: guestCodes, approveWithPDF, approveWithRSVP, emailQueueTemplate }, selectedEvent.code)
        .then(({ data }) => {
          setCodes([]);
          fetchGuestList();
          setApproveWithPDF(false);
          setApproveWithRSVP(false);
          setInvitationStatuses([]);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      setCodes([]);
    }
  };
  const handleRejectGuestsToEvent = () => {
    try {
      // update num selected here:
      changeNumSelected([]);
      rejectAttendeesAPI({ codes: guestCodes }, selectedEvent.code)
        .then(({ data }) => {
          setCodes([]);
          fetchGuestList();
          setInvitationStatuses([]);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      setCodes([]);
    }
  };
  const handleSendInvitationToGuest = () => {
    try {
      // update num selected here:
      changeNumSelected([]);

      sendInvitationAPI({ guests: guestCodes }, selectedEvent.code)
        .then(({ data }) => {
          fetchGuestList();
          setCodes([]);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      setCodes([]);
    }
  };

  const handleApproveWithPDF = (event) => {
    setApproveWithPDF(event.target.checked);
  };
  const handleApproveWithRSVP = (event) => {
    setApproveWithRSVP(event.target.checked);
    if (event.target.checked === true) {
      setEmailQueueTemplate('approved');
    } else {
      setEmailQueueTemplate('approvedWithoutRSVP');
    }
  };

  const invites = ['Approved', 'Rejected', 'Applied', 'Not Selected'];
  // const tags = ['Website', 'JAFZA'];
  // const [tags, setTags] = useState([]);

  const exportGuestEventList = () => {
    eventGuestsExportList(selectedEvent.code)
      .then(({ data }) => {
        let blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const filename = `event-guests-${moment().format('YYYY-MM-DD').toString()}.csv`;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var a = window.document.createElement('a');

          a.href = window.URL.createObjectURL(blob, {
            type: 'text/csv',
          });
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toolbar
        className={clsx(classesTheme.root, {
          [classesTheme.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <>
            <div className={classes.title}>
              <Typography color='inherit' variant='subtitle1'>
                {numSelected} selected
              </Typography>
            </div>
            <div className={classes.spacer} />
            <Box display='flex'>
              {/* {invitationBtn && CURRENT_USER.userType !== 2 ? (
                <Tooltip title='Send Invitations'>
                  <IconButton aria-label='Send Invitations' onClick={handleSendInvitationToGuest}>
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
              ) : null} */}
              {CURRENT_USER.userType !== 2 && (
                <>
                  <Checkbox
                    checked={approveWithPDF}
                    onChange={handleApproveWithPDF}
                    name='approveWithPDF'
                    value={approveWithPDF}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant='body1' style={{ paddingTop: '18px' }}>
                    PDF
                  </Typography>
                  <Checkbox
                    checked={approveWithRSVP}
                    onChange={handleApproveWithRSVP}
                    name='approveWithRSVP'
                    value={approveWithRSVP}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography variant='body1' style={{ paddingTop: '18px' }}>
                    RSVP
                  </Typography>
                  <Tooltip title='Approve'>
                    <IconButton aria-label='Add' onClick={handleAddGuestsToEvent}>
                      <VerifiedUserIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Reject'>
                    <IconButton aria-label='Add' onClick={handleRejectGuestsToEvent}>
                      <CancelPresentationIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <div>
              {/* SELECT */}
              <div className={classes.container}>
                <Box ml={2} mt={1} mb={1}>
                  <FormControl variant='filled' className={classes.formControl}>
                    <InputLabel variant='filled' id='event-label' style={{ fontSize: '24px', padding: '22px 10px' }}>
                      {selectedEvent && selectedEvent.name && selectedEvent.name}
                    </InputLabel>
                    <Select
                      className={[classes.container, classesTheme.searchForm].join(' ')}
                      labelId='event-label'
                      id='event'
                      placeholder='event'
                      value={
                        Object.keys(formValues).length > 0
                          ? formValues
                          : Object.keys(selectedEvent).length > 0 && selectedEvent.code
                      }
                      name='code'
                      onChange={handleEventSelect}
                      fullWidth>
                      {eventCodes.map((data, index) => {
                        return (
                          <MenuItem key={data.code} value={data} id={data.code}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>

                {/* SEARCH */}
                <div>
                  <form className={classes.container} noValidate autoComplete='off'>
                    <Box ml={2} mt={2}>
                      <TextField
                        className={[classes.container, classesTheme.searchForm].join(' ')}
                        id='outlined-search'
                        label='Search Guest'
                        type='search'
                        margin='normal'
                        variant='outlined'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </Box>
                    {CURRENT_USER.userType === 1 && (
                      <>
                        <Box ml={2} mt={2}>
                          <Button
                            variant='contained'
                            className='btn btn-danger'
                            color='primary'
                            onClick={() => history.push('/guest/add')}>
                            <PostAddIcon />
                          </Button>
                        </Box>
                        <Box ml={2} mt={2}>
                          <Button
                            variant='contained'
                            className='btn btn-danger'
                            color='primary'
                            onClick={() => showEmailQueueDialog(true)}>
                            <EmailIcon />
                          </Button>
                        </Box>
                        <Box ml={2} mt={2}>
                          <Tooltip title='Export Events Guests CSV'>
                            <Button
                              variant='contained'
                              className='btn btn-info'
                              color='primary'
                              onClick={() => exportGuestEventList()}>
                              <GetAppIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>

            {/* </Box> */}
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Tooltip title='Filter list'>
                <IconButton aria-label='Filter list' onClick={() => setIsAdvanceFilter(!isAdvanceFilter)}>
                  <FilterListIcon color='primary' />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </Toolbar>

      {/* ADVANCED FILTER */}
      {isAdvanceFilter && (
        <Box ml={1} p={1}>
          <Grid container spacing={2} alignContent='space-between'>
            <Grid item xs={3}>
              <Box clone>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='category-label'>Category</InputLabel>
                  <Select
                    labelId='category-label'
                    id='category'
                    name='categoryCode'
                    value={advancedFilter.category}
                    onChange={(e) => handleChange('category', e.target.value)}
                    fullWidth
                    required>
                    {categories.data.map((data, index) => {
                      return (
                        <MenuItem value={data.code} key={index}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value=''>All Categories</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box clone>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='invitation-label'>Status</InputLabel>
                  <Select
                    labelId='invitation-label'
                    id='invitation'
                    name='invitationCode'
                    value={advancedFilter.status}
                    onChange={(e) => handleChange('status', e.target.value)}
                    fullWidth
                    required>
                    {invites.map((data, index) => {
                      return (
                        <MenuItem value={data} key={index}>
                          {data}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value=''>All</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box clone>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='invitation-label'>Source</InputLabel>
                  <Select
                    labelId='invitation-label'
                    id='tags'
                    name='tag'
                    value={advancedFilter.tag}
                    onChange={(e) => handleChange('tag', e.target.value)}
                    fullWidth
                    required>
                    {Sources &&
                      Sources.data &&
                      Sources.data.length > 0 &&
                      Sources.data.map((data, index) => {
                        return (
                          <MenuItem value={data} key={index}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    <MenuItem value=''>All</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

//!===============================================================
const headRows = [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Guest ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Email',
  },
  {
    id: 'jobTitle',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Job Title',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Category',
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Source',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Registration Date',
  },
  {
    id: 'invitation',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'invitationstatus',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Invitation Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell padding='checkbox'>
            <StyledCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && numSelected !== 0 && rowCount !== 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Select all guests' }}
            />
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          {headRows.map((row) => (
            <StyledTableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}>
              {row.isSort ? (
                <TableSortLabel
                  className={'MuiTableSortLabel-dark'}
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              ) : (
                row.label
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//!===============================================================

const EventGuests = ({
  selectedEvent,
  changeSelectedEvent,
  eventCodes,
  processFilteredGuests,
  processFilteredGuestsSuccess,
  processFilteredGuestsFailed,
  processFilteredCategories,
  processFilteredCategoriesSuccess,
  processFilteredCategoriesFailed,
}) => {
  const { guests, users } = useSelector((state) => state);
  const CURRENT_USER = users.currentUser;
  const classes = useStyles();
  //* state:
  const [filterObject, setFilterObject] = useState(null);
  const [selected, setSelected] = useState([]);
  const [dense] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [invitationBtn, setInvitationBtn] = useState(true);
  const [codes, setCodes] = useState([]);
  const [invitationStatuses, setInvitationStatuses] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [emailQueueDialog, setEmailQueueDialog] = useState(false);
  const [emailQueueMsg, setEmailQueueMsg] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [withPDF, setWithPDF] = useState(false);
  const [withRSVP, setWithRSVP] = useState(false);
  const [emailQueueTemplate, setEmailQueueTemplate] = useState('approvedWithoutRSVP');

  const fetchGuestList = () => {
    try {
      const _page = page + 1;

      processFilteredGuests();
      getGuestsWithGuestEventList({
        page: _page,
        limit: rowsPerPage,
        order,
        orderBy,
        filters: filterObject,
      })
        .then(({ data }) => {
          processFilteredGuestsSuccess(data);
        })
        .catch((err) => {
          processFilteredGuestsFailed();
        });
    } catch (error) {
      console.log('=========', error);
    }
  };

  const fetchSourceList = () => {
    try {
      GetSourceAPI()
        .then((_sources) => {
          setSources(_sources);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log('Source Error:', error);
    }
  };

  const fetchCateogryList = () => {
    processFilteredCategories();
    getCategoriesAPI()
      .then(({ data }) => {
        setCategories(data.data);
        processFilteredCategoriesSuccess(data);
      })
      .catch((err) => processFilteredCategoriesFailed());
  };

  const handleFilterSearch = (data, filter, save) => {
    const filters = cleanObject({
      ...filterObject,
      search: data.length > 0 ? data : null,
      ...filter,
    });
    setFilterObject(filters);
  };
  const cleanObject = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] === null || obj[prop] === undefined || obj[prop].length === 0) {
          delete obj[prop];
        }
      }
    }
    return obj;
  };

  //* sort handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //* clickall select
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = guests.list.map((n) => n);
      setSelected(newSelecteds);
      setCodes([]);
      // fill it up
      const newCodes = guests.list.map((g) => g.code);
      setCodes(newCodes);

      return;
    } else {
      setCodes([]);
    }
    setSelected([]);
  };

  const handleClick = (event, code, guestEvents) => {
    const codeIdx = codes.indexOf(code.code);

    let cloneGuestEvents = guestEvents;
    const eventHasGuests = cloneGuestEvents.filter((event) => event.eventId === selectedEvent.id);
    let currentGuestSelected = eventHasGuests.find((guest) => guest.guestId === code.id);
    // console.log(currentGuestSelected);
    // console.log(codeIdx);
    if (codeIdx === -1) {
      setCodes([...codes, code.code]);

      if (currentGuestSelected && currentGuestSelected.status === 'Rejected') {
        setInvitationStatuses([...invitationStatuses, `Rejected${code.code}`]);
      } else if (currentGuestSelected && currentGuestSelected.status === 'Approved') {
        setInvitationStatuses([...invitationStatuses, `Approved${code.code}`]);
      } else if (currentGuestSelected && currentGuestSelected.status === 'Applied') {
        setInvitationStatuses([...invitationStatuses, `Applied${code.code}`]);
      } else {
        setInvitationStatuses([...invitationStatuses, `Not Selected${code.code}`]);
      }
    } else {
      let codesClone = codes;
      const filter = codesClone.filter((id) => id !== code.code);
      setCodes(filter);
      if (currentGuestSelected && currentGuestSelected.status === 'Rejected') {
        let invitationStatusesClone = invitationStatuses;
        const filterInvitationStatuses = invitationStatusesClone.filter((status) => status !== `Rejected${code.code}`);
        setInvitationStatuses(filterInvitationStatuses);
      } else if (currentGuestSelected && currentGuestSelected.status === 'Approved') {
        let invitationStatusesClone = invitationStatuses;
        const filterInvitationStatuses = invitationStatusesClone.filter((status) => status !== `Approved${code.code}`);
        setInvitationStatuses(filterInvitationStatuses);
      } else if (currentGuestSelected && currentGuestSelected.status === 'Applied') {
        let invitationStatusesClone = invitationStatuses;
        const filterInvitationStatuses = invitationStatusesClone.filter((status) => status !== `Applied${code.code}`);
        setInvitationStatuses(filterInvitationStatuses);
      } else {
        let invitationStatusesClone = invitationStatuses;
        const filterInvitationStatuses = invitationStatusesClone.filter(
          (status) => status !== `Not Selected${code.code}`
        );
        setInvitationStatuses(filterInvitationStatuses);
      }
    }

    const selectedIndex = selected.indexOf(code);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    const findNot = invitationStatuses.filter((status) => status.includes('Not'));
    const findRejected = invitationStatuses.filter((status) => status.includes('Rejected'));
    // console.log(invitationStatuses);
    if (findNot.length > 0 || findRejected.length > 0) {
      setInvitationBtn(false);
    } else {
      setInvitationBtn(true);
    }
  }, [invitationStatuses]);

  useEffect(() => {
    fetchGuestList();
    fetchCateogryList();
    fetchSourceList();
    //eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const saveEmailQueue = (categoryCode, eventCode, template) => {
    try {
      const APIData = {
        categoryCode,
        eventCode,
        template,
        withPDF,
        withRSVP,
      };
      // console.log(APIData);
      emailQueueAPI(APIData)
        .then((response) => {
          setEmailQueueMsg();
          if (response && response.status === 201) {
            // setEmailQueueMsg('List of guests added in queue for sending invitations.');
            setEmailQueueDialog(false);
          } else if (response.status === 400) {
            setEmailQueueMsg('List of guests not found for specified event and category');
          }
          setWithPDF(false);
          setWithRSVP(false);
        })
        .catch((e) => {
          setEmailQueueMsg('List of guests not found for specified event and category');
          console.log('error===', e);
        });
    } catch (e) {
      console.log(e);
      setCodes([]);
    }
  };

  const handleWithPDF = (event) => {
    setWithPDF(event.target.checked);
  };
  const handleWithRSVP = (event) => {
    setWithRSVP(event.target.checked);
    if (event.target.checked === true) {
      setEmailQueueTemplate('approved');
    } else {
      setEmailQueueTemplate('approvedWithoutRSVP');
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = +event.target.value;
    const _page = page + 1;
    const _prevPageCount = Math.ceil(guests.list.total / limit);
    if (_page > _prevPageCount) setPage(0);
    setRowsPerPage(limit);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const _toolBarFiltering = useRef(null);

  useEffect(() => {
    if (_toolBarFiltering.current) {
      fetchGuestList();
    }
    _toolBarFiltering.current = true;
    // eslint-disable-next-line
  }, [filterObject, order, orderBy, page, rowsPerPage]);
  const findStatus = (guestData, guestEvents) => {
    if (selectedEvent && guestData && guestEvents && guestEvents.length > 0) {
      for (let i = 0; i < guestEvents.length; i++) {
        // console.log(selectedEvent, guestEvents[i].events);
        if (
          guestEvents[i].events &&
          selectedEvent.id === guestEvents[i].events.id &&
          guestEvents[i].guests.id === guestData.id
        ) {
          return (
            <>
              <TableCell align='left'>{guestEvents[i].status}</TableCell>
              {guestEvents[i].invitationStatus ? (
                <TableCell align='left'>{guestEvents[i].invitationStatus}</TableCell>
              ) : (
                <TableCell align='left'> - </TableCell>
              )}
            </>
          );
        }
      }
    }
    return (
      <>
        <TableCell align='left'>Not Selected</TableCell>
        <TableCell align='left'> - </TableCell>
      </>
    );
  };

  return (
    <MainLayout>
      <Dialog
        maxWidth='xs'
        fullWidth
        open={emailQueueDialog}
        onClose={() => setEmailQueueDialog(false)}
        aria-labelledby='draggable-dialog-title'>
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Select category to send invitation
        </DialogTitle>
        <DialogContent>
          <Box width={1}>
            {categories && (
              <FormControl fullWidth variant='filled' className={classes.formControl}>
                <InputLabel id='category-label'>Category</InputLabel>
                <Select
                  labelId='category-label'
                  id='category'
                  name='categoryCode'
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  fullWidth
                  required>
                  {categories.map((data, index) => {
                    return (
                      <MenuItem value={data.code} key={index}>
                        {data.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Checkbox
                  checked={withPDF}
                  onChange={handleWithPDF}
                  name='withPDF'
                  value={withPDF}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Send Email with PDF
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={withRSVP}
                  onChange={handleWithRSVP}
                  name='withRSVP'
                  value={withRSVP}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Send Email with RSVP/VIP
              </Grid>
            </Grid>
          </Box>
          <Box width={1}>{emailQueueMsg && <Typography variant='body1'>{emailQueueMsg}</Typography>}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            //   disabled={btnDeleteStatus}
            onClick={() => saveEmailQueue(selectedCategory, selectedEvent.code, emailQueueTemplate)}
            color='primary'
            disabled={selectedCategory ? false : true}>
            Submit
          </Button>
          <Button autoFocus onClick={() => setEmailQueueDialog(false)} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.root}>
        <Box className={classes.paper}>
          <Grid container direction='column'>
            <Grid item>
              <Typography color='inherit' variant='h4' className={classes.pageTitle}>
                {`Attendees of ${selectedEvent && selectedEvent.name && selectedEvent.name}`}
              </Typography>
            </Grid>
          </Grid>
          <EnhancedTableToolbar
            numSelected={selected.length}
            changeNumSelected={setSelected}
            onFilterSearch={handleFilterSearch}
            guestCodes={codes}
            showEmailQueueDialog={() => setEmailQueueDialog(true)}
            selectedEvent={selectedEvent}
            eventCodes={eventCodes}
            changeSelectedEvent={changeSelectedEvent}
            setCodes={setCodes}
            invitationBtn={invitationBtn}
            setInvitationStatuses={setInvitationStatuses}
            invitationStatuses={invitationStatuses}
            fetchGuestList={fetchGuestList}
            CURRENT_USER={CURRENT_USER}
            Sources={sources}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
              aria-label='enhanced table'>
              <EnhancedTableHead
                classes={classes}
                order={order}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={guests.total < rowsPerPage ? guests.total : rowsPerPage}
              />
              <TableBody>
                {stableSort(guests.list, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.code}
                      selected={isItemSelected}>
                      <TableCell padding='checkbox'>
                        <StyledCheckbox
                          onClick={(event) => handleClick(event, row, row.guestEvent)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {row.file ? (
                        <TableCell>
                          <img
                            src={`${process.env.REACT_APP_FILE_URL}/${row.file.code}`}
                            width='100px'
                            valign='middle'
                            alt='img'
                          />
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      <TableCell component='th' id={labelId} scope='row' padding='none'>
                        {row.code}
                      </TableCell>
                      <TableCell align='left'>{row.fullName ? row.fullName : '-'}</TableCell>
                      <TableCell align='left'>{row.email ? row.email : '-'}</TableCell>
                      <TableCell align='left'>{row.jobTitle ? row.jobTitle : '-'}</TableCell>
                      <TableCell align='left'>{row.category && row.category.name ? row.category.name : '-'}</TableCell>
                      <TableCell align='left'>{row.tag ? row.tag : '-'}</TableCell>
                      <TableCell align='left'>
                        {row.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : '-'}
                      </TableCell>
                      {row && row.guestEvent && findStatus(row, row.guestEvent)}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component='div'
            count={guests.total}
            rowsPerPage={guests.limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </MainLayout>
  );
};
export default connect(null, {
  ...CategoryReducer.actions,
  ...GuestReducer.actions,
})(EventGuests);
