import { Grid, TextField, Button, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import 'date-fns';
import { sendSupport } from '../../validators/support-validator';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const SupportPage = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(9),

      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      marginTop: '80px',
      marginLeft: '75px',
      background: 'transparent',
    },
  }));
  const classes = useStyles();

  const [jsonFormData, setJsonFormData] = useState({});
  const [imageFiles, setImageFiles] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('There was an error.');

  const history = useHistory();

  const handleFormChange = (e) => {
    // console.log(jsonFormData);
    // console.log(e);
    setJsonFormData({
      ...jsonFormData,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const handleFile = (file) => {
    setImageFiles(file[0]);
  };

  const handleSubmit = async () => {
    let errorMessages = {};
    try {
      sendSupport
        .validate(jsonFormData, { abortEarly: false })
        .then(() => {
          if (!imageFiles) {
            return;
          }

          let formData = new FormData();
          formData.append('imageFiles', imageFiles);
          for (let i in jsonFormData) {
            formData.append(i, jsonFormData[i]);
          }
          // console.log(formData);
          axios
            .post('support', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((_response) => {
              if (_response.status === 200) {
                // history.push('/support');
                setAlertMessage(' Issue has been raised successfuly');
                setAlertSeverity('success');
                setShowAlert(true);
                setJsonFormData({ title: '', issue: '' });
              } else {
                console.log('error');
              }
            })
            .catch((err) => {
              console.log(err);
              setAlertMessage(err.message);
              setAlertSeverity('error');
              setShowAlert(true);
              setJsonFormData({ title: '', issue: '' });
              setImageFiles({});
            });
        })
        .catch((err) => {
          err.inner.forEach((e) => {
            errorMessages[e.path] = e.message;
          });
          setValidationErrors(errorMessages);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainLayout>
      {/* <Box mt={10}>

      </Box> */}
      {/* <div className={classes.root}> */}
      <Box m={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='inherit' variant='h4' className={classes.pageTitle}>
              Need Support?
            </Typography>
          </Grid>
          <Grid item container xs={12} md={8}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  variant='filled'
                  type='text'
                  fullWidth
                  label='Title'
                  id='title'
                  name='title'
                  onChange={handleFormChange}
                  error={validationErrors.title}
                  helperText={validationErrors.title}
                  value={jsonFormData.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={14}
                  fullWidth
                  variant='filled'
                  onChange={handleFormChange}
                  label='Type your issue'
                  name='issue'
                  error={validationErrors.issue}
                  helperText={validationErrors.issue}
                  value={jsonFormData.issue}
                  id='issue'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4} spacing={1}>
            <Grid item xs={12}>
              <DropzoneArea
                dropzoneText='drag and drop or click here'
                filesLimit={1}
                onChange={(file) => handleFile(file)}
                acceptedFiles={['image/*']}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            {showAlert ? (
              <Grid item container xs={12}>
                <Grid item xs={6} align='right'>
                  <Box clone mt={-1}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                  </Box>
                </Grid>
                <Grid item xs={6} align='right'>
                  <Box mr={1} fontWeight='fontWeightBold'>
                    <Button variant='contained' onClick={() => history.goBack()} color='primary'>
                      Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} align='right'>
                <Box mr={1} fontWeight='fontWeightBold'>
                  <Button variant='contained' onClick={handleSubmit} color='primary'>
                    Submit
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* </div> */}
    </MainLayout>
  );
};

export default SupportPage;
