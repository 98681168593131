import React, { useState } from 'react';
import SecondaryLayout from '../../../layouts/secondaryLayout';
import { forgotPasswordAPI } from '../../../API/users';
import { useHistory, Link } from 'react-router-dom';
import colors from '../../../constants/colors';
import { Grid, Typography, TextField, Box, Card, CardContent, Button, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as UserReucer from '../../../redux/reducers/users';
import { forgotPasswordValidator } from '../../../validators/validators';

const formData = {
  title: 'Forgot Password',
  subtitle: '',
  fields: [
    {
      label: 'email',
      placeHolder: 'Type your email',
      type: 'email',
    },
  ],

  buttons: [
    {
      label: 'Send',
      link: '',
    },
  ],
};
const useStyles = makeStyles((theme) => ({
  cardStyles: {
    minWidth: 450,
  },

  buttonStyles: {
    backgroundColor: colors.primary,
  },
  removeUnderline: {
    textDecoration: 'none',
  },
}));

const ForgotPassword = (props) => {
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [errorValidation, setErrorValidation] = useState({});
  const history = useHistory();

  const classes = useStyles();

  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setErrorValidation({
      ...errorValidation,
      [name]: null,
    });
  }

  const submitForm = () => {
    // console.log('submit: data', formValues);

    let errorMessages = {};
    forgotPasswordValidator
      .validate(formValues, { abortEarly: false })
      .then(() => {
        props.processForgotPassword();
        forgotPasswordAPI({ email: formValues.email })
          .then((data) => {
            // console.log('forgot password:success:', data.data);
            props.processForgotPasswordSuccess(data);
            errorMessages['email'] = data.data;
            setErrorValidation(errorMessages);
          })
          .catch((e) => props.processForgotPasswordFailed());
      })
      .catch((err) => {
        if (err) {
          err.inner.forEach((e) => {
            // console.log(e.path);
            errorMessages[e.path] = e.message;
          });
          setErrorValidation(errorMessages);
        }
        // console.log(errorMessages);
        // console.log('state: validation', errorValidation);
      });
  };
  return (
    <SecondaryLayout>
      <Card className={classes.cardStyles}>
        <CardContent>
          <Grid container direction='column' spacing={5}>
            <Grid item>
              <Box fontWeight='fontWeightBold'>
                <Typography variant='h6'>{formData.title}</Typography>
              </Box>
              {formData.subtitle && <Typography variant='body2'>{formData.subtitle}</Typography>}
            </Grid>
            {formData.fields.map((_data, idx) => (
              <Grid item key={idx}>
                <Box color='textPrimary' fontWeight='fontWeightBold'>
                  <Typography>{_data.label.slice(0, 1).toUpperCase() + _data.label.slice(1)}</Typography>
                </Box>
                <TextField
                  label={_data.placeHolder}
                  type={_data.type}
                  key={idx}
                  variant='filled'
                  fullWidth
                  value={formValues.email}
                  onChange={handleChange}
                  error={errorValidation[_data.label]}
                  helperText={errorValidation[_data.label]}
                  name={_data.label}></TextField>
              </Grid>
            ))}

            {formData.links &&
              formData.links.map((_data, idx) => (
                <Grid item align='right' key={idx}>
                  <Link to={_data.link} className={classes.removeUnderline}>
                    <Typography color='textSecondary' variant='body2'>
                      {_data.label}
                    </Typography>
                  </Link>
                </Grid>
              ))}
            <Grid item container spacing={2}>
              {formData.buttons &&
                formData.buttons.map((_data, idx) => (
                  <Grid item align='center' key={idx}>
                    <Button className={classes.buttonStyles} vaiant='contained' onClick={submitForm}>
                      <Box fontWeight='fontWeightBold'>{_data.label}</Box>
                    </Button>
                  </Grid>
                ))}
              <Grid item align='center'>
                <Button vaiant='contained' onClick={() => history.goBack()}>
                  <Box fontWeight='fontWeightBold'>Back to login</Box>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </SecondaryLayout>
  );
};

export default connect(null, UserReucer.actions)(ForgotPassword);
