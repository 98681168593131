import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  logoGmisDesc: {
    fill:
      theme.palette.type === "dark"
        ? theme.palette.background.light
        : theme.palette.primary.main,
  },
  logoGmisTitle: {
    fill: "#A4C627",
  },
}));

const GmisLogo = ({ viewBox, width }) => {
  const classes = useStyles();
  return (
    <Box pt={{ sm: 1 }}>
      <svg
        version="1.1"
        id="Group_921"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox={viewBox ? viewBox : "0 0 238 50"}
        height="50px"
        width={width ? width : "238px"}
      >
        <g>
          <path
            id="Path_1119"
            className={`${classes.logoGmisDesc}`}
            d="M115.3,22c-0.9,0.3-1.9,0.5-2.8,0.5c-1,0.1-1.9-0.3-2.6-1c-0.6-0.7-0.9-1.5-0.9-2.4v-2.8
		c0-0.9,0.2-1.7,0.8-2.4c0.7-0.7,1.7-1.1,2.7-1c0.9,0,1.7,0.2,2.5,0.4l-0.3,1c-0.7-0.2-1.5-0.4-2.3-0.4c-0.8-0.1-1.5,0.2-2,0.8
		c-0.3,0.5-0.4,1-0.4,1.6v2.6c0,1.6,0.8,2.4,2.3,2.4c0.6,0,1.2-0.1,1.8-0.2V18l1-0.1V22z"
          />
          <path
            id="Path_1120"
            className={`${classes.logoGmisDesc}`}
            d="M118.3,21.7l-0.2,0.9c-0.4,0-0.7-0.2-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9v-8.6h1.1v8.6
		C117.9,21.5,118,21.6,118.3,21.7"
          />
          <path
            id="Path_1121"
            className={`${classes.logoGmisDesc}`}
            d="M124.9,19.8c0,0.7-0.2,1.4-0.7,2c-1.1,1.1-2.9,1.1-4.1,0c-0.5-0.5-0.8-1.2-0.7-2v-1.6
		c0-0.7,0.2-1.5,0.8-2c0.5-0.5,1.3-0.8,2-0.8c0.8,0,1.5,0.2,2,0.8c0.5,0.5,0.8,1.3,0.8,2V19.8z M123.8,19.7v-1.4
		c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.4-0.8-0.6-1.3-0.5c-1.1,0-1.6,0.6-1.6,1.8v1.4c0,1.2,0.5,1.8,1.6,1.8c0.5,0,0.9-0.2,1.3-0.5
		C123.7,20.6,123.8,20.1,123.8,19.7"
          />
          <path
            id="Path_1122"
            className={`${classes.logoGmisDesc}`}
            d="M131.8,20c0,1.4-1,2.5-2.4,2.6c-0.1,0-0.1,0-0.2,0c-0.6-0.1-1.3-0.3-1.8-0.6l-0.1,0.5h-0.9
		v-9.8l1.1-0.1v1.8c0,0.5,0,1-0.1,1.5c0.5-0.3,1.1-0.4,1.7-0.5c0.7,0,1.4,0.2,1.9,0.7c0.5,0.5,0.7,1.2,0.7,1.8L131.8,20z
		 M130.7,19.9v-1.7c0-1.1-0.5-1.7-1.5-1.7c-0.6,0-1.2,0.1-1.7,0.4v4.2c0.5,0.3,1.1,0.4,1.7,0.5C130.2,21.5,130.7,21,130.7,19.9"
          />
          <path
            id="Path_1123"
            className={`${classes.logoGmisDesc}`}
            d="M138,22.5h-0.9L137,22c-0.5,0.3-1.1,0.5-1.8,0.6c-0.6,0-1.2-0.2-1.6-0.6
		c-0.4-0.4-0.7-1-0.6-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.3,1.1-0.5,1.7-0.5h1.5V18c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.6-0.4-1-0.4
		c-0.6,0-1.3,0.1-1.9,0.3l-0.3-1c0.7-0.2,1.4-0.3,2.1-0.4c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.7,1.1,0.6,1.8L138,22.5z M136.9,21.2
		v-1.9h-1.4c-0.9,0-1.4,0.4-1.4,1.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4C135.9,21.6,136.4,21.5,136.9,21.2"
          />
          <path
            id="Path_1124"
            className={`${classes.logoGmisDesc}`}
            d="M140.9,21.7l-0.2,0.9c-0.4,0-0.7-0.2-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9v-8.6h1.1v8.6
		C140.5,21.5,140.7,21.6,140.9,21.7"
          />
          <path
            id="Path_1125"
            className={`${classes.logoGmisDesc}`}
            d="M154.1,22.5H153l-0.9-7.6l-0.3,1.3l-1.3,4.4c-0.2,0.8-0.7,1.2-1.3,1.2c-0.7,0-1.1-0.4-1.3-1.2
		l-1.3-4.4l-0.2-1.3l-0.2,1.7l-0.8,5.9h-1.2l1.2-9.3h1.4l2,7.2c0.1,0.3,0.2,0.4,0.3,0.4s0.2-0.1,0.3-0.4l2.1-7.2h1.4L154.1,22.5z"
          />
          <path
            id="Path_1126"
            className={`${classes.logoGmisDesc}`}
            d="M160.2,22.5h-0.9l-0.1-0.5c-0.5,0.4-1.1,0.5-1.8,0.6c-0.6,0-1.2-0.2-1.6-0.6
		c-0.4-0.4-0.7-1-0.6-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.3,1.1-0.5,1.7-0.5h1.5V18c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.6-0.4-1-0.4
		c-0.6,0-1.3,0.1-1.9,0.3l-0.3-1c0.7-0.2,1.4-0.3,2.1-0.4c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.7,1.1,0.6,1.8V22.5z M159.1,21.2v-1.9
		h-1.4c-0.9,0-1.4,0.4-1.4,1.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4C158.1,21.6,158.6,21.5,159.1,21.2"
          />
          <path
            id="Path_1127"
            className={`${classes.logoGmisDesc}`}
            d="M166.8,22.3c0,0.1-0.1,0.2-0.2,0.2h-0.9V18c0-1.1-0.4-1.6-1.3-1.6c-0.6,0-1.2,0.2-1.7,0.4v5.6
		h-1.1v-7h0.9l0.1,0.5c0.6-0.3,1.2-0.5,1.8-0.6c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.6,1.1,0.6,1.8L166.8,22.3z"
          />
          <path
            id="Path_1128"
            className={`${classes.logoGmisDesc}`}
            d="M173.5,22.5h-0.9l-0.1-0.5c-0.6,0.3-1.2,0.5-1.8,0.6c-0.7,0-1.3-0.2-1.8-0.7
		c-0.4-0.5-0.6-1.1-0.6-1.8v-4.3c0-0.2,0.1-0.3,0.2-0.3h0.9v4.4c0,1.1,0.4,1.6,1.2,1.6c0.6,0,1.2-0.2,1.7-0.4v-5.6h1.1L173.5,22.5z"
          />
          <path
            id="Path_1129"
            className={`${classes.logoGmisDesc}`}
            d="M178.8,12.7l-0.2,1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.3-0.3,0.6-0.2,1
		v0.7h1.7v0.9h-1.7v5.9c0,0.1-0.1,0.2-0.2,0.2h-0.9v-6.1l-1.1-0.1v-0.8h1.1v-0.7c0-0.6,0.2-1.2,0.6-1.6c0.4-0.4,1-0.6,1.6-0.6
		C178.1,12.6,178.5,12.6,178.8,12.7"
          />
          <path
            id="Path_1130"
            className={`${classes.logoGmisDesc}`}
            d="M184.6,22.5h-0.9l-0.1-0.5c-0.5,0.4-1.1,0.5-1.8,0.6c-0.6,0-1.2-0.2-1.6-0.6
		c-0.4-0.4-0.7-1-0.6-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.3,1.1-0.5,1.7-0.5h1.5V18c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.6-0.4-1-0.4
		c-0.6,0-1.3,0.1-1.9,0.3l-0.3-1c0.7-0.2,1.4-0.3,2.1-0.4c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.7,1.1,0.6,1.8L184.6,22.5z M183.5,21.2
		v-1.9h-1.4c-0.9,0-1.4,0.4-1.4,1.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4C182.4,21.6,183,21.5,183.5,21.2"
          />
          <path
            id="Path_1131"
            className={`${classes.logoGmisDesc}`}
            d="M190.7,22.2c-0.7,0.2-1.4,0.4-2.2,0.4c-0.8,0-1.5-0.3-2-0.9c-0.5-0.5-0.7-1.2-0.7-1.9v-1.6
		c0-0.7,0.2-1.4,0.7-1.9c0.5-0.6,1.3-0.9,2-0.9c0.7,0,1.4,0.2,2.1,0.4l-0.2,1c-0.6-0.2-1.2-0.3-1.8-0.4c-0.5,0-1,0.2-1.3,0.6
		c-0.2,0.4-0.4,0.8-0.4,1.2v1.4c0,0.4,0.1,0.9,0.4,1.2c0.3,0.4,0.8,0.6,1.3,0.6c0.6,0,1.3-0.1,1.9-0.3L190.7,22.2z"
          />
          <path
            id="Path_1132"
            className={`${classes.logoGmisDesc}`}
            d="M195.5,22.4c-0.3,0.1-0.6,0.2-1,0.2c-0.6,0-1.1-0.2-1.5-0.6c-0.3-0.4-0.5-0.9-0.5-1.4v-4.3h-1
		v-0.9h1l0.2-2.1h0.9v2.1h1.6v0.9h-1.6v4.1c0,0.7,0.3,1.1,0.9,1.1c0.3,0,0.6,0,0.9-0.1L195.5,22.4z"
          />
          <path
            id="Path_1133"
            className={`${classes.logoGmisDesc}`}
            d="M201.8,22.5h-0.9l-0.1-0.5c-0.6,0.3-1.2,0.5-1.8,0.6c-0.7,0-1.3-0.2-1.8-0.7
		c-0.4-0.5-0.6-1.1-0.6-1.8v-4.3c0-0.2,0.1-0.3,0.2-0.3h0.9v4.4c0,1.1,0.4,1.6,1.2,1.6c0.6,0,1.2-0.2,1.7-0.4v-5.6h1.1V22.5z"
          />
          <path
            id="Path_1134"
            className={`${classes.logoGmisDesc}`}
            d="M206.6,15.5l-0.1,1c-0.2,0-0.4,0-0.6,0c-0.5,0.1-1,0.4-1.4,0.8v5.1c0,0.1-0.1,0.2-0.2,0.2h-0.9
		v-7h0.9l0.1,0.7c0.4-0.5,0.9-0.8,1.6-0.9C206.2,15.4,206.4,15.4,206.6,15.5"
          />
          <path
            id="Path_1135"
            className={`${classes.logoGmisDesc}`}
            d="M209.1,22.3c0,0.1-0.1,0.2-0.2,0.2h-0.9v-6.1l-0.8-0.1v-0.8h2L209.1,22.3z M209.3,13.4
		c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0
		C209.2,13,209.3,13.2,209.3,13.4"
          />
          <path
            id="Path_1136"
            className={`${classes.logoGmisDesc}`}
            d="M216,22.3c0,0.1-0.1,0.2-0.2,0.2h-0.9V18c0-1.1-0.4-1.6-1.3-1.6c-0.6,0-1.2,0.2-1.7,0.4v5.6
		h-1.1v-7h0.9l0.1,0.5c0.6-0.3,1.2-0.5,1.8-0.6c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.6,1.1,0.6,1.8L216,22.3z"
          />
          <path
            id="Path_1137"
            className={`${classes.logoGmisDesc}`}
            d="M222.8,22.6c0,0.6-0.2,1.2-0.7,1.7c-0.4,0.4-1.1,0.7-1.7,0.7h-1.7l-0.2-1h1.9
		c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-0.9V22c-0.5,0.3-1.1,0.4-1.7,0.5c-0.7,0-1.4-0.2-1.9-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-2
		c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.2-0.8,1.9-0.7c0.6,0.1,1.3,0.3,1.8,0.6l0.1-0.5h0.9L222.8,22.6z M221.7,21v-4.1
		c-0.5-0.2-1.1-0.4-1.6-0.5c-0.4,0-0.8,0.1-1.1,0.5c-0.3,0.4-0.4,0.8-0.4,1.2v1.6c0,1.1,0.5,1.7,1.4,1.7
		C220.6,21.4,221.1,21.3,221.7,21"
          />
          <path
            id="Path_1138"
            className={`${classes.logoGmisDesc}`}
            d="M233.9,18l-1.3,0.1v4c-0.8,0.3-1.6,0.4-2.5,0.5c-0.8,0-1.5-0.3-2.1-0.8
		c-0.5-0.5-0.8-1.2-0.8-1.9c-0.1-1,0.5-1.9,1.5-2.3v0c-0.7-0.4-1.2-1.1-1.2-2c0-0.7,0.2-1.4,0.8-1.8c0.6-0.5,1.3-0.7,2-0.7
		c0.5,0,1,0.1,1.6,0.2l-0.2,1c-0.5-0.1-0.9-0.2-1.4-0.2c-0.5,0-0.9,0.1-1.3,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.8,0.4,1.1
		c0.3,0.4,0.7,0.6,1.2,0.6h1.4l0.2-1.3h0.8v1.3h1.3L233.9,18z M231.6,21.3v-3.2h-1.4c-0.9-0.1-1.7,0.6-1.8,1.5c0,0.1,0,0.2,0,0.2
		c0,0.4,0.1,0.8,0.4,1.1c0.4,0.4,0.9,0.6,1.4,0.6C230.7,21.5,231.2,21.4,231.6,21.3"
          />
          <path
            id="Path_1139"
            className={`${classes.logoGmisDesc}`}
            d="M110.3,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9V27h1.1V36.1z"
          />
          <path
            id="Path_1140"
            className={`${classes.logoGmisDesc}`}
            d="M117.2,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-4.4c0-1.1-0.4-1.6-1.3-1.6c-0.6,0-1.2,0.2-1.7,0.4
		v5.6H112v-7h0.9l0.1,0.5c0.6-0.3,1.2-0.5,1.8-0.6c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.6,1.1,0.6,1.8V36.1z"
          />
          <path
            id="Path_1141"
            className={`${classes.logoGmisDesc}`}
            d="M124,36.3h-0.9l-0.1-0.5c-0.6,0.3-1.2,0.5-1.8,0.6c-1.4,0.1-2.5-1-2.6-2.3c0-0.1,0-0.1,0-0.2
		v-2.1c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.2-0.8,1.9-0.7c0.6,0,1.2,0.2,1.7,0.5c0-0.5-0.1-1-0.1-1.5v-1.7h1.1L124,36.3z M122.9,34.9
		v-4.2c-0.5-0.3-1.1-0.4-1.7-0.4c-1,0-1.5,0.6-1.5,1.7v1.7c0,1.1,0.5,1.7,1.5,1.7C121.8,35.4,122.3,35.2,122.9,34.9"
          />
          <path
            id="Path_1142"
            className={`${classes.logoGmisDesc}`}
            d="M130.8,36.3h-0.9l-0.1-0.5c-0.6,0.3-1.2,0.5-1.8,0.6c-0.7,0-1.3-0.2-1.8-0.7
		c-0.4-0.5-0.6-1.1-0.6-1.8v-4.3c0-0.2,0.1-0.3,0.2-0.3h0.9v4.4c0,1.1,0.4,1.6,1.2,1.6c0.6,0,1.2-0.2,1.7-0.4v-5.6h1.1V36.3z"
          />
          <path
            id="Path_1143"
            className={`${classes.logoGmisDesc}`}
            d="M136.4,34.4c0,0.6-0.2,1.1-0.6,1.5c-0.5,0.4-1.1,0.6-1.7,0.5c-0.7,0-1.4-0.2-2-0.4l0.2-1
		c0.6,0.2,1.2,0.3,1.8,0.4c0.8,0,1.2-0.3,1.2-1c0-0.4-0.3-0.7-0.8-1c-0.9-0.5-1.4-0.7-1.5-0.7c-0.5-0.4-0.8-0.9-0.8-1.6
		c0-0.5,0.2-1.1,0.6-1.4c0.4-0.4,1-0.5,1.6-0.5c0.6,0,1.2,0.1,1.7,0.3l-0.3,1c-0.5-0.2-1-0.2-1.4-0.3c-0.7,0-1.1,0.3-1.1,0.9
		c0,0.4,0.3,0.7,0.8,0.9c0.9,0.4,1.4,0.6,1.5,0.7C136.1,33.2,136.4,33.8,136.4,34.4"
          />
          <path
            id="Path_1144"
            className={`${classes.logoGmisDesc}`}
            d="M140.9,36.3c-0.3,0.1-0.6,0.2-1,0.2c-0.6,0-1.1-0.2-1.5-0.6c-0.3-0.4-0.5-0.9-0.5-1.4v-4.2h-1
		v-0.9h1l0.2-2.1h0.9v2.1h1.6v0.9h-1.6v4.1c0,0.7,0.3,1.1,0.9,1.1c0.3,0,0.6,0,0.9-0.1L140.9,36.3z"
          />
          <path
            id="Path_1145"
            className={`${classes.logoGmisDesc}`}
            d="M145.4,29.3l-0.1,1c-0.2,0-0.4,0-0.6,0c-0.5,0.1-1,0.4-1.4,0.8v5.1c0,0.1-0.1,0.2-0.2,0.2h-0.9
		v-7h0.9l0.1,0.7c0.4-0.5,0.9-0.8,1.6-0.9C145,29.2,145.2,29.3,145.4,29.3"
          />
          <path
            id="Path_1146"
            className={`${classes.logoGmisDesc}`}
            d="M147.8,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-6.1l-0.8-0.1v-0.8h2V36.1z M148,27.2
		c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0C148,26.8,148,27,148,27.2
		"
          />
          <path
            id="Path_1147"
            className={`${classes.logoGmisDesc}`}
            d="M154.2,36.3h-0.9l-0.1-0.5c-0.5,0.4-1.1,0.5-1.8,0.6c-0.6,0-1.2-0.2-1.6-0.6
		c-0.4-0.4-0.7-1-0.6-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.3,1.1-0.5,1.7-0.5h1.5v-0.4c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.6-0.4-1-0.4
		c-0.6,0-1.3,0.1-1.9,0.3l-0.3-1c0.7-0.2,1.4-0.3,2.1-0.4c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.7,1.1,0.6,1.8L154.2,36.3z M153.1,35.1
		v-1.9h-1.4c-0.9,0-1.4,0.4-1.4,1.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4C152,35.5,152.6,35.3,153.1,35.1"
          />
          <path
            id="Path_1148"
            className={`${classes.logoGmisDesc}`}
            d="M157.1,35.5l-0.2,0.9c-0.4,0-0.7-0.2-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9v-8.6h1.1v8.6
		C156.7,35.3,156.9,35.5,157.1,35.5"
          />
          <path
            id="Path_1149"
            className={`${classes.logoGmisDesc}`}
            d="M159.8,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-6.1l-0.8-0.1v-0.8h2L159.8,36.1z M160,27.2
		c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0C160,26.8,160,27,160,27.2
		"
          />
          <path
            id="Path_1150"
            className={`${classes.logoGmisDesc}`}
            d="M165.6,34.4c0,0.6-0.2,1.1-0.6,1.5c-0.5,0.4-1.1,0.6-1.7,0.5c-0.7,0-1.4-0.2-2-0.4l0.2-1
		c0.6,0.2,1.2,0.3,1.8,0.4c0.8,0,1.2-0.3,1.2-1c0-0.4-0.3-0.7-0.8-1c-0.9-0.5-1.4-0.7-1.5-0.7c-0.5-0.4-0.8-0.9-0.8-1.6
		c0-0.5,0.2-1.1,0.6-1.4c0.4-0.4,1-0.5,1.6-0.5c0.6,0,1.2,0.1,1.7,0.3l-0.3,1c-0.5-0.2-1-0.2-1.4-0.3c-0.7,0-1.1,0.3-1.1,0.9
		c0,0.4,0.3,0.7,0.8,0.9c0.9,0.4,1.4,0.6,1.5,0.7C165.3,33.2,165.6,33.8,165.6,34.4"
          />
          <path
            id="Path_1151"
            className={`${classes.logoGmisDesc}`}
            d="M171.6,36.3h-0.9l-0.1-0.5c-0.5,0.4-1.1,0.5-1.8,0.6c-0.6,0-1.2-0.2-1.6-0.6
		c-0.4-0.4-0.7-1-0.6-1.6c0-0.6,0.2-1.2,0.7-1.5c0.5-0.3,1.1-0.5,1.7-0.5h1.5v-0.4c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.6-0.4-1-0.4
		c-0.6,0-1.3,0.1-1.9,0.3l-0.3-1c0.7-0.2,1.4-0.3,2.1-0.4c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.7,1.1,0.6,1.8L171.6,36.3z M170.4,35.1
		v-1.9h-1.4c-0.9,0-1.4,0.4-1.4,1.1c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.4C169.4,35.5,170,35.3,170.4,35.1"
          />
          <path
            id="Path_1152"
            className={`${classes.logoGmisDesc}`}
            d="M176.2,36.3c-0.3,0.1-0.6,0.2-1,0.2c-0.6,0-1.1-0.2-1.5-0.6c-0.3-0.4-0.5-0.9-0.5-1.4v-4.3h-1
		v-0.9h1l0.2-2.1h0.9v2.1h1.6v0.9h-1.6v4.1c0,0.7,0.3,1.1,0.9,1.1c0.3,0,0.6,0,0.9-0.1L176.2,36.3z"
          />
          <path
            id="Path_1153"
            className={`${classes.logoGmisDesc}`}
            d="M178.8,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-6.1l-0.8-0.1v-0.8h2V36.1z M179,27.2
		c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0C179,26.8,179,27,179,27.2
		"
          />
          <path
            id="Path_1154"
            className={`${classes.logoGmisDesc}`}
            d="M185.9,33.6c0,0.7-0.2,1.4-0.7,2c-1.1,1.1-2.9,1.1-4.1,0c-0.5-0.5-0.8-1.2-0.7-2V32
		c0-0.7,0.2-1.5,0.8-2c0.5-0.5,1.3-0.8,2-0.8c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.3,0.8,2L185.9,33.6z M184.8,33.5v-1.4
		c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.4-0.8-0.6-1.3-0.5c-1.1,0-1.6,0.6-1.6,1.8v1.4c0,1.2,0.5,1.8,1.6,1.8c0.5,0,0.9-0.2,1.3-0.5
		C184.6,34.5,184.8,34,184.8,33.5"
          />
          <path
            id="Path_1155"
            className={`${classes.logoGmisDesc}`}
            d="M192.6,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-4.4c0-1.1-0.4-1.6-1.3-1.6c-0.6,0-1.2,0.2-1.7,0.4
		v5.6h-1.1v-7h0.9l0.1,0.5c0.6-0.3,1.2-0.5,1.8-0.6c0.7,0,1.3,0.2,1.8,0.7c0.4,0.5,0.6,1.1,0.6,1.8L192.6,36.1z"
          />
          <path
            id="Path_1156"
            className={`${classes.logoGmisDesc}`}
            d="M201.7,33.8c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.7-2.1,0.7c-0.9,0-1.8-0.2-2.6-0.5l0.2-1.1
		c0.8,0.3,1.6,0.4,2.4,0.5c0.5,0,1-0.1,1.3-0.5c0.3-0.3,0.4-0.7,0.4-1.1c0-0.7-0.5-1.2-1.1-1.5c-0.7-0.3-1.4-0.7-2-1
		c-0.7-0.4-1.1-1.2-1.1-2c0-0.7,0.3-1.4,0.8-1.8c0.6-0.5,1.3-0.7,2-0.7c0.8,0,1.5,0.2,2.2,0.4l-0.3,1c-0.6-0.2-1.3-0.4-2-0.4
		c-0.4,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.5,0.4,1,1.1,1.4c0.7,0.3,1.4,0.7,2,1C201.4,32.2,201.8,33,201.7,33.8"
          />
          <path
            id="Path_1157"
            className={`${classes.logoGmisDesc}`}
            d="M208.2,36.3h-0.9l-0.1-0.5c-0.6,0.3-1.2,0.5-1.8,0.6c-0.7,0-1.3-0.2-1.8-0.7
		c-0.4-0.5-0.6-1.1-0.6-1.8v-4.3c0-0.2,0.1-0.3,0.2-0.3h0.9v4.4c0,1.1,0.4,1.6,1.2,1.6c0.6,0,1.2-0.2,1.7-0.4v-5.6h1.1L208.2,36.3z"
          />
          <path
            id="Path_1158"
            className={`${classes.logoGmisDesc}`}
            d="M218.9,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-4.2c0-1.2-0.5-1.8-1.4-1.8c-0.4,0-0.9,0.2-1.1,0.6
		c-0.2,0.4-0.3,0.9-0.3,1.4v4.1h-1.1v-4.1c0-0.5-0.1-0.9-0.3-1.4c-0.2-0.4-0.6-0.6-1.1-0.6c-0.9,0-1.4,0.6-1.4,1.8v4.2h-1.1V32
		c0-0.7,0.2-1.4,0.6-2c0.9-1,2.4-1.1,3.4-0.2c0.2,0.2,0.4,0.4,0.5,0.6c0.7-1.1,2.1-1.5,3.3-0.9c0.2,0.1,0.4,0.3,0.6,0.5
		c0.4,0.6,0.7,1.3,0.6,2L218.9,36.1z"
          />
          <path
            id="Path_1159"
            className={`${classes.logoGmisDesc}`}
            d="M229.8,36.1c0,0.1-0.1,0.2-0.2,0.2h-0.9v-4.2c0-1.2-0.5-1.8-1.4-1.8c-0.4,0-0.9,0.2-1.1,0.6
		c-0.2,0.4-0.3,0.9-0.3,1.4v4.1h-1.1v-4.1c0-0.5-0.1-0.9-0.3-1.4c-0.2-0.4-0.6-0.6-1.1-0.6c-0.9,0-1.4,0.6-1.4,1.8v4.2h-1.1V32
		c0-0.7,0.2-1.4,0.6-2c0.9-1,2.4-1.1,3.4-0.2c0.2,0.2,0.4,0.4,0.5,0.6c0.7-1.1,2.1-1.5,3.3-0.9c0.2,0.1,0.4,0.3,0.6,0.5
		c0.4,0.6,0.7,1.3,0.6,2L229.8,36.1z"
          />
          <path
            id="Path_1160"
            className={`${classes.logoGmisDesc}`}
            d="M233.1,36.1c0,0.1-0.1,0.2-0.2,0.2H232v-6.1l-0.8-0.1v-0.8h2V36.1z M233.3,27.2
		c0,0.4-0.3,0.8-0.8,0.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
		C233.2,26.8,233.3,27,233.3,27.2"
          />
          <path
            id="Path_1161"
            className={`${classes.logoGmisDesc}`}
            d="M238,36.3c-0.3,0.1-0.6,0.2-1,0.2c-0.6,0-1.1-0.2-1.5-0.6c-0.3-0.4-0.5-0.9-0.5-1.4v-4.3h-1
		v-0.9h1l0.2-2.1h0.9v2.1h1.6v0.9h-1.6v4.1c0,0.7,0.3,1.1,0.9,1.1c0.3,0,0.6,0,0.9-0.1L238,36.3z"
          />
          <rect
            id="Rectangle_288"
            x="98.9"
            className={`${classes.logoGmisTitle}`}
            width="2.1"
            height="50"
          />
          <path
            id="Path_1162"
            className={`${classes.logoGmisTitle}`}
            d="M83.7,17.6H68.1v5.4h13.6c1,0,2.1,0.1,3.1,0.3c0.7,0.1,1.4,0.4,2,0.9c0.5,0.5,0.8,1.1,0.9,1.8
		c0.2,0.9,0.3,1.8,0.3,2.7v2.5c0,0.9-0.1,1.8-0.3,2.7l0,0c-0.1,0.7-0.5,1.3-0.9,1.8h0c-0.5,0.5-1.1,0.8-1.7,0.9
		c-0.9,0.2-1.7,0.3-2.6,0.3H69.4c-0.9,0-1.8-0.1-2.6-0.3c-0.7-0.1-1.3-0.5-1.8-0.9l0,0c-0.5-0.5-0.8-1.1-0.9-1.8
		c-0.2-0.9-0.3-1.8-0.3-2.7v-0.9l0.3-0.1l3.4-0.8l0.5-0.1v3.7h15.7V27H69.5c-0.9,0-1.8-0.1-2.6-0.3c-0.7-0.2-1.3-0.5-1.7-0.9
		c-0.4-0.5-0.8-1.1-0.9-1.8c-0.2-0.9-0.3-1.8-0.3-2.7v-1.9c0-0.9,0.1-1.8,0.3-2.7c0.1-0.7,0.5-1.3,0.9-1.8c0.5-0.5,1.1-0.8,1.7-0.9
		c0.9-0.2,1.7-0.3,2.6-0.3h11.8c1,0,2.1,0.1,3.1,0.3c0.7,0.1,1.4,0.4,2,0.9c0.5,0.5,0.8,1,1,1.7c0.2,0.8,0.3,1.6,0.3,2.4v0.8
		l-0.3,0.1l-3.2,0.9l-0.5,0.1V17.6z"
          />
          <path
            id="Path_1163"
            className={`${classes.logoGmisTitle}`}
            d="M57.7,36.3V13.7h4.1v23h-4.1V36.3z"
          />
          <path
            id="Path_1164"
            className={`${classes.logoGmisTitle}`}
            d="M40.9,33.1l-9.4-12.9v0.1c0,0.4,0,0.7,0,1.1v15.4h-4v-23h3.6l0.1,0.2l10.4,14l9.7-14l0.1-0.2h4
		v23h-4.2V21.4c0-0.1,0-0.3,0-0.6c0-0.2,0-0.5,0-0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5l0,0l-8.5,12.3l-0.1,0.2
		h-1.4L40.9,33.1z"
          />
          <path
            id="Path_1165"
            className={`${classes.logoGmisTitle}`}
            d="M21.1,17.6H4.5v15.3h16.5v-5.7h-9v-3.7h13.5v7.6c0,0.9-0.1,1.8-0.3,2.7l0,0
		c-0.1,0.7-0.5,1.3-0.9,1.8l0,0c-0.5,0.5-1.1,0.8-1.8,0.9c-0.9,0.2-1.8,0.3-2.6,0.3H5.6c-0.9,0-1.8-0.1-2.7-0.3
		c-0.7-0.1-1.3-0.5-1.8-0.9c-0.5-0.5-0.8-1.1-0.9-1.8C0.1,32.9,0,32,0,31.1V19.4c0-0.9,0.1-1.8,0.3-2.6c0.1-0.7,0.5-1.3,0.9-1.8
		C1.7,14.5,2.3,14.2,3,14c0.9-0.2,1.8-0.3,2.7-0.3H20c0.9,0,1.8,0.1,2.6,0.3c0.7,0.1,1.3,0.5,1.8,0.9c0.5,0.5,0.8,1.1,0.9,1.7
		c0.2,0.8,0.3,1.7,0.3,2.5V20l-0.3,0.1l-3.7,0.7l-0.5,0.1L21.1,17.6L21.1,17.6z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default GmisLogo;
