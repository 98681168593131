import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import EditableLabel from 'react-inline-editing';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Box, TextField } from '@material-ui/core';
import * as RxCategories from '../../redux/reducers/categories';
import MainLayout from '../../layouts/MainLayout';
import { getCategoriesAPI, editCategoryAPI, deleteCategory, addCategoryAPI } from '../../API/categories';
import CancelIcon from '@material-ui/icons/Cancel';
import colors from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
    // backgroundColor: 'transparent',
    backgroundColor: colors.backgroundColor,
    padding: '10px',
  },
  buttonStyles: {
    backgroundColor: theme.palette.primary.main,
  },
  CategoryBox: {
    display: 'flex',
    backgroundColor: '#2D2E31',
    width: '200px',
    borderRadius: '5px',
  },
  categoriesList: {
    display: 'inline-block',
    justifyContent: 'left',
    padding: '20px',
    width: '160px',
    color: '#A5A5A6',
  },
  categoriesData: {
    padding: '20px',
  },
  closeBtn: { cursor: 'pointer', marginTop: '20px' },
  addBtn: {
    cursor: 'pointer',
  },
  viewrBox: {
    backgroundColor: colors.paper,
    borderRadius: 5,
  },
}));

const CategoryList = (props) => {
  const classes = useStyles();
  const [closeBtn, setCloseBtn] = useState(true);
  const { data } = useSelector((state) => state.categories);
  const { users } = useSelector((state) => state);
  const [categoryData, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [addInput, setAddInput] = useState(false);
  const [addText, setAddText] = useState(true);
  const CURRENT_USER = users.currentUser;

  const deleteCategories = (code) => {
    deleteCategory({ code })
      .then((data) => {
        fetchCateogryList();
      })
      .catch((err) => console.log('delete:category:', err));
  };

  const _handleFocus = (text) => {
    setCloseBtn(false);
    const _category = data.find((category) => category.name === text);
    setCategory(_category);
  };
  const saveCategory = () => {
    handleSave(true, false);
    if (categoryName && categoryName.trim().length > 0) {
      addCategoryAPI(categoryName)
        .then(({ data }) => {
          props.processAddCategoriesSuccess(data);
          fetchCateogryList();
        })
        .catch((err) => props.processAddCategoriesFailed());
    }
  };
  const handleSave = (text, input) => {
    setAddText(text);
    setAddInput(input);
  };
  const _handleFocusOut = (text) => {
    setCloseBtn(true);
    editCategoryAPI(text, categoryData.code)
      .then(({ data }) => {
        props.processEditCategoriesSuccess(data);
        // redirect to homepage
        fetchCateogryList();
      })
      .catch((err) => props.processEditCategoriesFailed());
  };
  // Get Category list
  const fetchCateogryList = () => {
    props.processFilteredCategories();
    getCategoriesAPI()
      .then(({ data }) => {
        // console.log("data:filtered:guest:", data);
        props.processFilteredCategoriesSuccess(data);
      })
      .catch((err) => props.processFilteredCategoriesFailed());
  };
  useEffect(() => {
    fetchCateogryList();
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector((state) => state);

  return (
    <MainLayout>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Box m={3}>
            <Grid item xs={12}>
              <Typography color='inherit' variant='h4' className={classes.pageTitle}>
                Guest List
              </Typography>
              <Typography color='inherit' variant='body1' className={classes.pageTitle}>
                Categories
              </Typography>
            </Grid>
          </Box>
          <Grid container>
            {CURRENT_USER.userType === 2 ? (
              <Grid container>
                <Grid item container xs={12}>
                  {categories.data.map((_data, idx) => {
                    return (
                      <Grid item xs={2}>
                        <Box align='left' ml={2} p={2} className={classes.viewrBox}>
                          <Typography color='disabled'>{_data.name}</Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              categories.data.map((data, index) => {
                return (
                  <>
                    <Box className={classes.CategoryBox} ml={3} mb={3} key={data.code}>
                      <EditableLabel
                        key={data.code}
                        text={data.name}
                        labelClassName={classes.categoriesList}
                        inputClassName={classes.categoriesList}
                        inputWidth='200px'
                        inputHeight='60px'
                        inputMaxLength='255'
                        onFocus={_handleFocus}
                        onFocusOut={_handleFocusOut}
                      />
                      {closeBtn && (
                        <CancelIcon
                          className={classes.closeBtn}
                          onClick={() => {
                            if (window.confirm('Are you sure to delete this category?')) {
                              deleteCategories(data.code);
                            }
                          }}
                        />
                      )}
                    </Box>
                  </>
                );
              })
            )}

            {CURRENT_USER.userType !== 2 && (
              <Box ml={3} mb={3}>
                {addText && (
                  <Box mt={2}>
                    <Typography variant='h6' onClick={() => handleSave(false, true)}>
                      + Add Categories
                    </Typography>
                  </Box>
                )}
                {addInput && (
                  <TextField
                    margin='dense'
                    id='name'
                    label='Type category name'
                    type='text'
                    fullWidth
                    autoFocus
                    onBlur={(e) => saveCategory()}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                )}
              </Box>
            )}
          </Grid>
        </Paper>
      </div>
    </MainLayout>
  );
};

export default connect(null, RxCategories.actions)(CategoryList);
