import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { useDispatch } from "react-redux";
// import { put, takeLatest } from "redux-saga/effects";
// import { loginAPI } from "../../API/login";

export const actionTypes = {
  USER_ADD: '[USER] ADD USER',
  USER_ADD_SUCCESS: '[USER] ADD USER SUCCESS',
  USER_ADD_FAILED: '[USER] ADD USER FAILED',
  USER_LOGIN: '[USER] USER LOGIN',
  USER_LIST: '[USER] LIST USERS',
  USER_LIST_SUCCESS: '[USER] LIST USERS SUCCESS',
  USER_LIST_FAILED: '[USER] LIST USERS FAILED',
  USER_LOGIN_SUCCESS: '[USER] USER LOGIN_SUCCESS',
  USER_LOGIN_FAILED: '[USER] USER LOGIN_FAILED',
  USER_RESET_PASSWORD: '[USER] USER RESET PASSWORD',
  USER_RESET_PASSWORD_FAILED: '[USER] USER RESET PASSWORD FAILED',
  USER_RESET_PASSWORD_SUCCESS: '[USER] USER RESET PASSWORD SUCCESS',
  USER_CHANGE_PASSWORD: '[USER] USER CHANGE PASSWORD',
  USER_CHANGE_PASSWORD_FAILED: '[USER] USER CHANGE PASSWORD FAILED',
  USER_CHANGE_PASSWORD_SUCCESS: '[USER] USER CHANGE PASSWORD SUCCESS',
  USER_FORGOT_PASSWORD: '[USER] USER FORGOT PASSWORD',
  USER_FORGOT_PASSWORD_FAILED: '[USER] USER FORGOT PASSWORD FAILED',
  USER_FORGOT_PASSWORD_SUCCESS: '[USER] USER FORGOT PASSWORD SUCCESS',
};

const initialState = {
  list: [],
  currentUser: null,
  limit: 20,
  total: 0,
  loading: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'users',
    whitelist: ['users', 'currentUser', 'list'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.USER_LOGIN:
        return { ...state, loading: true };
      case actionTypes.USER_LOGIN_FAILED:
        return { ...state, loading: false };
      case actionTypes.USER_LOGIN_SUCCESS:
        const { data } = action.payload;
        return { ...state, loading: false, currentUser: data };
      //==============================================

      case actionTypes.USER_ADD:
        return { ...state, loading: true };
      case actionTypes.USER_ADD_SUCCESS:
        //todo: take the data here
        return { ...state, loading: false };
      case actionTypes.USER_ADD_FAILED:
        return { ...state, loading: false };
      //==============================================

      case actionTypes.USER_RESET_PASSWORD:
        return { ...state, loading: true };
      case actionTypes.USER_RESET_PASSWORD_FAILED:
        return { ...state, loading: false };
      case actionTypes.USER_RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      //==============================================

      case actionTypes.USER_LIST:
        return { ...state, loading: true };
      case actionTypes.USER_LIST_FAILED:
        return { ...state, loading: false };
      case actionTypes.USER_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload.data.data,
          limit: action.payload.data.limit,
          total: action.payload.data.total,
        };
      //==============================================

      default:
        return state;
    }
  }
);

export const actions = {
  processFilteredUsers: () => ({
    type: actionTypes.USER_LIST,
    payload: {},
  }),
  processFilteredUsersSuccess: (data) => ({
    type: actionTypes.USER_LIST_SUCCESS,
    payload: { data },
  }),
  processFilteredUsersFailed: () => ({
    type: actionTypes.USER_LIST_FAILED,
    payload: {},
  }),
  //==============================================
  processLogin: () => ({
    type: actionTypes.USER_LOGIN,
    payload: {},
  }),
  processLoginSuccess: (data) => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: { data },
  }),
  processLoginFailed: () => ({
    type: actionTypes.USER_LOGIN_FAILED,
    payload: {},
  }),
  //==============================================
  processResetPassword: () => ({
    type: actionTypes.USER_RESET_PASSWORD,
    payload: {},
  }),
  processResetPasswordFailed: () => ({
    type: actionTypes.USER_RESET_PASSWORD_FAILED,
    payload: {},
  }),
  processResetPasswordSuccess: (data) => ({
    type: actionTypes.USER_RESET_PASSWORD_SUCCESS,
    payload: { data },
  }),
  //==============================================
  processChangePassword: () => ({
    type: actionTypes.USER_CHANGE_PASSWORD,
    payload: {},
  }),
  processChangePasswordFailed: () => ({
    type: actionTypes.USER_CHANGE_PASSWORD_FAILED,
    payload: {},
  }),
  processChangePasswordSuccess: (data) => ({
    type: actionTypes.USER_CHANGE_PASSWORD_SUCCESS,
    payload: { data },
  }),
  //==============================================
  processForgotPassword: () => ({
    type: actionTypes.USER_FORGOT_PASSWORD,
    payload: {},
  }),
  processForgotPasswordFailed: () => ({
    type: actionTypes.USER_FORGOT_PASSWORD_FAILED,
    payload: {},
  }),
  processForgotPasswordSuccess: (data) => ({
    type: actionTypes.USER_FORGOT_PASSWORD_SUCCESS,
    payload: { data },
  }),
  //==============================================
  processAddUser: () => ({
    type: actionTypes.USER_ADD,
    payload: {},
  }),
  processAddUserSuccess: (data) => ({
    type: actionTypes.USER_ADD_SUCCESS,
    payload: { data },
  }),
  processAddUserFailed: () => ({
    type: actionTypes.USER_ADD_FAILED,
    payload: {},
  }),
  //==============================================
};

// 2nd option
export function* saga() {
  // yield takeLatest(actionTypes.USER_LOGIN, function* LoginUser({ payload }) {
  //   // const { data } = yield loginAPI(payload);
  //   // loginAPI(payload)
  //   //   .then((data) =>
  //   //     yield put(actions.processLoginSuccess(data))
  //   //   )
  //   //   .catch((err) =>
  //   //     yield put(actions.processLoginFailed())
  //   //   );
  // });
}
