import axios from "axios";

const GET_TAGS = "tags";
export const ADD_TAG = "tags/add";
export const EDIT_TAG = "tags/edit";
export const DELETE_TAG = "tags/delete";

export const getTagsAPI = () => {
  return axios.post(GET_TAGS);
};

export const deleteTag = (code) => {
  return axios.post(DELETE_TAG, code);
};

export const addTagAPI = (name) => {
  // payload: form data  ==>  file
  const body = {
    name,
  };
  return axios.post(ADD_TAG, body);
};

export const editTagAPI = (name, code) => {
  // payload: form data  ==>  file
  const body = {
    name,
  };
  return axios.post(EDIT_TAG + "/" + code, body);
};
