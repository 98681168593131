import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';
const theme = createMuiTheme({
  palette: {
    background: {
      default: colors.backgroundColor,
      main: colors.backgroundColorDarkLight,
      dark: colors.backgroundColorGray,
    },
    paper: {
      default: colors.paper,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    disabled: {
      main: colors.disabled,
    },

    text: {
      primary: colors.textPrimary,
      secondary: colors.textPrimary,
      disabled: colors.textDisabled,
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'white',
      },
      switchHeader: {
        '& .MuiPickersCalendarHeader-iconButton': {
          backgroundColor: `${colors.primary} !important`,
          background: `${colors.primary} !important`,
          padding: '4px',
        },
      },
    },
    // MuiPickersCalendarHeader: {
    //   dayLabel: {
    //     // color: 'white',
    //   },
    // },
    MuiButton: {
      root: {
        '& .MuiDisabled': {
          backgroundColor: colors.disabled,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '& .MuiCheckbox-colorSecondary': {
          color: `${colors.primary} !important`,
        },
        // '.Mui-checked': {
        //   color: `${colors.primary} !important`,
        // },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
          color: `${colors.primary} !important`,
        },
      },
    },
    MuiTab: {
      root: {
        backgroundColor: `${colors.primary} !important`,
        marginRight: 10,
      },
    },
    MuiAlert: {
      // root: {
      standardSuccess: {
        backgroundColor: colors.primary,
        fontSize: '16px',
      },
      standardError: {
        backgroundColor: colors.error,
        fontSize: '16px',

        // '&:.icon': {
        //   color: colors.primary,
        // },
      },
      a: {
        textDecoration: 'none',
      },
      // },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '5px',
        paddingLeft: '2px',
        paddingTop: '2px',
      },
      root: {
        backgroundColor: '#2D2E31',
        borderRadius: '5px',
        '& .MuiInputLabel-root': {
          color: '#797a7b',
          paddingLeft: '2px',
          paddingTop: '2px',
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: '#2D2E31',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      select: {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
    MuiInputBase: {
      root: {
        '& .MuiSelect-icon': {
          color: colors.textPrimary,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#2D2E31',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: colors.paper,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${colors.lightDivider}`,
      },
    },

    MuiDropzoneArea: {
      root: {
        minHeight: '376px',
        backgroundColor: `${colors.backgroundColorGray} !important`,
      },
    },

    MuiIconButton: {
      root: {
        color: 'white',
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: `${colors.backgroundColorGray} !important`,
      },
    },
    MuiSvgIcon: {
      root: {
        // marginTop: '5px',
      },
    },
    MuiList: {
      root: {
        height: '150px',
      },
    },
  },
});

export default theme;
