import axios from 'axios';

const ADD_AGENDA = 'agendas/add';
const GET_AGENDAS = 'agendas';
const EDIT_AGENDAS = 'agendas/edit';
const DELETE_AGENDAS = 'agendas/delete';

export const addAgendaAPI = (payload) => {
  return axios.post(ADD_AGENDA, payload);
};

export const getAgendaAPI = (payload) => {
  return axios.post(GET_AGENDAS, payload);
};

export const editAgendaAPI = (payload, code) => {
  return axios.put(`${EDIT_AGENDAS}/${code}`, payload);
};
export const deleteAgendaAPI = (codes) => {
  return axios.post(DELETE_AGENDAS, codes);
};
