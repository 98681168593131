import axios from 'axios';

export const GET_GUESTS = 'guests';
export const GET_ALL_GUESTS = 'guests/allguests';
export const GET_GUESTS_LIST = 'guests/guestlist';
export const GET_GUESTS_WITH_GUESTEVENT_LIST = 'guests/guestlistwithguestevent';
export const GET_ALL_GUESTS_EVENTS = 'guests/allguestsevents';
export const ADD_GUEST = 'guests/add';
export const IMPORT_CSV = 'guests/importcsv';
export const EDIT_GUEST = 'guests/edit';
export const DELETE_GUEST = 'guests/delete';
export const UPDATE_GUEST_CATEGORY = 'guests/updateGuestCategory';
export const COUNTRY_GUESTS = 'guests/country';
export const SOURCE_GUESTS = 'guests/source';
export const CATEGORY_GUESTS = 'guests/countGuestByCategory';
export const DAILY_GUESTS = 'guests/countguestperday';
export const MONTHLY_GUESTS = 'guests/countguestpermonth';
export const TOTAL_GUESTS = 'guests/allguests';
export const JOBROLE_GUESTS = 'guests/jobRole';
export const JOBROLE_GUESTS_PEREVENT = 'guests/jobrolePerEvent';
export const SOURCE_GUESTS_PEREVENT = 'guests/sourcePerEvent';
export const COUNTRY_GUESTS_PEREVENT = 'guests/countryPerEvent';
export const TOP_COUNTRIES_PEREVENT = 'guests/topRegistredCountries';
export const GET_UNIQUE_SOURCE = 'guests/getUniqueSource';
export const GET_JOB_ROLES = 'guests/getJobRoles';
export const GET_INDUSTRY_SECTORS = 'guests/getIndustrySectors';
export const GET_BY_INDUSTRY_SECTOR = 'guests/getByIndustry';
export const EXPORT_GUEST_LIST = 'guests/exportsguest';
export const EXPORT_EVENT_GUEST_LIST = 'guests/exportseventguest';
export const GET_GUEST_EVENT_BY_CODE = 'guests/guestevent';

export const getGuestsAPI = (payload) => {
  return axios.post(GET_GUESTS, payload);
};
export const getAllGuestsAPI = (payload) => {
  return axios.get(GET_ALL_GUESTS, payload);
};
export const getGuestEventByCode = (code) => {
  return axios.get(GET_GUEST_EVENT_BY_CODE + '/' + code);
};
export const getGuestsListAPI = (payload) => {
  return axios.post(GET_GUESTS_LIST, payload);
};
export const getGuestsWithGuestEventList = (payload) => {
  return axios.post(GET_GUESTS_WITH_GUESTEVENT_LIST, payload);
};
export function guestExportList() {
  return axios.post(EXPORT_GUEST_LIST, [], {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
  });
}
export function eventGuestsExportList(eventCode) {
  return axios.post(
    EXPORT_EVENT_GUEST_LIST,
    { eventCode },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv',
      },
    }
  );
}
export const getAllGuestsEventsAPI = (payload) => {
  return axios.get(GET_ALL_GUESTS_EVENTS, payload);
};
export const getTotalGuestsAPI = (payload) => {
  return axios.get(TOTAL_GUESTS, payload);
};
export const guestSourcePerEventAPI = (payload) => {
  return axios.post(SOURCE_GUESTS_PEREVENT, { id: payload });
};
export const guestJobRolePerEventAPI = (payload) => {
  return axios.post(JOBROLE_GUESTS_PEREVENT, { id: payload });
};
export const guestCountryPerEventAPI = (payload) => {
  return axios.post(COUNTRY_GUESTS_PEREVENT, { id: payload });
};
export const guestTopCountriesApi = (payload) => {
  return axios.post(TOP_COUNTRIES_PEREVENT, { id: payload });
};
export const GetSourceAPI = () => {
  return axios.get(GET_UNIQUE_SOURCE);
};
export const guestPerDayOfMonth = (payload) => {
  // payload here is the number representation of the month: April== 04
  return axios.post(DAILY_GUESTS, { month: payload });
};
export const guestPerMonth = (payload) => {
  return axios.get(MONTHLY_GUESTS, payload);
};
export const getCountryGuestsAPI = (payload) => {
  return axios.get(COUNTRY_GUESTS, payload);
};
export const getIndustrySectorAPI = (payload) => {
  return axios.post(GET_BY_INDUSTRY_SECTOR, { id: payload });
};
export const getSourceGuestsAPI = (payload) => {
  return axios.get(SOURCE_GUESTS, payload);
};
export const getJobRoleGuestsAPI = (payload) => {
  return axios.get(JOBROLE_GUESTS, payload);
};
export const getCategoryGuestsAPI = (payload) => {
  return axios.get(CATEGORY_GUESTS, payload);
};

export const deleteGuest = (codes) => {
  return axios.post(DELETE_GUEST, codes);
};
export const updateGuestsCategories = (codes, categoryCode) => {
  return axios.post(UPDATE_GUEST_CATEGORY, { codes, categoryCode });
};

export const addGuestAPI = (payload, files) => {
  // payload: form data  ==>  file
  let formData = new FormData();
  files.forEach((_file) => {
    formData.append('files', _file);
  });
  for (let i in payload) {
    if (i === 'articleLinks') {
      const updatedArticleLinks = payload[i].filter((_link) => {
        return _link !== '' && _link !== null;
      });
      formData.append(i, updatedArticleLinks);
    } else formData.append(i, payload[i]);
  }
  return axios.post(ADD_GUEST, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const importCSVAPI = (payload) => {
  // console.log('=================data.data===========', payload.data);
  return axios.post(IMPORT_CSV, payload);
};

export const editGuestAPI = (payload, files, deletedFiles) => {
  // payload: form data  ==>  file
  let formData = new FormData();
  files.forEach((_file) => {
    formData.append('files', _file);
  });
  formData.append('deletedFiles', deletedFiles);
  for (let i in payload) {
    if (i === 'articleLinks') {
      const updatedArticleLinks = payload[i].filter((_link) => {
        return _link !== '' && _link !== null;
      });
      formData.append(i, updatedArticleLinks);
    } else formData.append(i, payload[i]);
  }
  return axios.put(EDIT_GUEST + '/' + payload.code, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

////////////////////////////////////////////////////////////
export const getJobRolesAPI = () => {
  return axios.get(GET_JOB_ROLES);
};
////////////////////////////////////////////////////////////
export const getIndustrialSectorAPI = () => {
  return axios.get(GET_INDUSTRY_SECTORS);
};
