import { handleLogout } from '../helpers/helpers';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      config.baseUrl = process.env.REACT_APP_API_URL;
      config.url = `${config.baseUrl}${config.url}`;
      const {
        users: { currentUser },
      } = store.getState();

      if (currentUser && currentUser.token) {
        config.headers.Authorization = `Bearer ${currentUser.token}`;
        // console.log(config);
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(undefined, (err) => {
    if (err.response.status === 401) {
      handleLogout();
    }
  });
}
