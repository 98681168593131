import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

const RestrictedRoute = (props) => {
  const { users } = useSelector((state) => state);
  //check if there's a current user
  // route based on that
  return (
    <Route exact to={props.path}>
      {users.currentUser ? <Redirect to='/' /> : props.children}
    </Route>
  );
};

export default RestrictedRoute;
