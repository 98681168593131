import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  total: 0,
  limit: 20,
  list: [],
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: "agenda", whitelist: ["agenda", "list"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.AGENDA_LIST:
        return { ...state, loading: true };
      case actionTypes.AGENDA_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload.data,
          limit: action.payload.limit,
          total: action.payload.total,
        };
      case actionTypes.AGENDA_LIST_FAILED:
        return { ...state, loading: false };
      default:
        return state;
    }
  }
);
export const actionTypes = {
  AGENDA_LIST: "[AGENDA] LIST",
  AGENDA_LIST_SUCCESS: "[AGENDA] LIST SUCCESS",
  AGENDA_LIST_FAILED: "[AGENDA] LIST FAILED",
};

export const actions = {
  processFilteredAgenda: () => ({
    type: actionTypes.AGENDA_LIST,
    payload: {},
  }),
  processFilteredAgendaSuccess: (data) => ({
    type: actionTypes.AGENDA_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredAgendaFailed: () => ({
    type: actionTypes.AGENDA_LIST_FAILED,
    payload: {},
  }),
};

export function* saga() {}
