import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import * as AgendaReducer from '../../redux/reducers/agenda';
import {
  Grid,
  Box,
  Button,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAgendaAPI, deleteAgendaAPI } from '../../API/agenda';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  alertTypography: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: theme.palette.warning.main,
  },
}));

const AgendaList = ({
  setEditAgenda,
  eventCode,
  selected,
  setSelected,
  processFilteredAgenda,
  processFilteredAgendaSuccess,
  processFilteredAgendaFailed,
  dateOfEvent,
}) => {
  const classes = useStyles();

  // define state(s)
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedAgenda, setSelectedAgenda] = useState({});
  const [currentChecked, setCurrentChecked] = useState(null);
  let am = 'AM';
  let pm = 'PM';
  // fetch agendas from remote
  const fetchAgendas = () => {
    processFilteredAgenda();
    getAgendaAPI()
      .then(({ data }) => {
        processFilteredAgendaSuccess(data);
      })
      .catch((e) => {
        processFilteredAgendaFailed();
        console.log(e);
      });
  };

  // useEffect for fetching agendas
  useEffect(() => {
    fetchAgendas();
    //eslint-disable-next-line
  }, []);
  const { agenda } = useSelector((state) => state);

  let hasAgenda = agenda.list;
  hasAgenda = hasAgenda.filter((_data) => {
    return _data.events ? _data.events.code === eventCode : false;
  });
  // console.log(hasAgenda);
  let hasCurrentDateOfEvent = hasAgenda.find((item) => item.date === dateOfEvent);
  // console.log(hasCurrentDateOfEvent);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = agenda.list.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, agenda) => {
    // this state helps me control disabling checkboxes
    setCurrentChecked(agenda.code);
    // This condition updates the agenda for the edit form
    if (event.target.checked) {
      setSelectedAgenda(agenda);
    } else {
      setSelectedAgenda({ name: '', time: '', description: '' });
      setEditAgenda({ name: '', time: '', description: '' });
    }
    //  does not allow the checkbox click to expand the accordion
    event.stopPropagation();
    //selected is a state that keeps all of the selected checkboxes
    const selectedIndex = selected.indexOf(agenda);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, agenda);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const deleteAgenda = (event) => {
    setConfirmDelete(false);
    let code = selected.map((agenda) => agenda.code);
    deleteAgendaAPI({ code: code })
      .then(({ data }) => {
        console.log(data);
        fetchAgendas();
        setSelected([]);
      })
      .catch((e) => {
        console.log(e);

        fetchAgendas();
        setSelected([]);
      });
  };
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };
  const updateAgendaCallback = () => {
    setEditAgenda(selectedAgenda);
  };

  return (
    <Box mt={2}>
      <Dialog
        maxWidth='xs'
        fullWidth
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby='draggable-dialog-title'>
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this agenda?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmDelete(false)} color='primary'>
            Cancel
          </Button>
          <Button
            //   disabled={btnDeleteStatus}
            onClick={deleteAgenda}
            color='primary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Toolbar>
        <Grid container className={classes.root}>
          {selected.length > 0 && (
            <Paper style={{ backgroundColor: '#111215', width: '100%' }}>
              <Box mb={1}>
                <Grid container item xs={12}>
                  <Grid item xs={10}>
                    <Box ml={1} mt={1}>
                      <Typography color='inherit' variant='subtitle1'>
                        {selected.length} selected
                      </Typography>
                    </Box>
                  </Grid>
                  {selected.length === 1 && (
                    <Grid item xs={1} alignContent='flex-end'>
                      {/* <Box align="right" ml={15}> */}
                      <Tooltip title='Edit'>
                        <IconButton color='primary' aria-label='Edit' onClick={updateAgendaCallback}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {/* </Box> */}
                    </Grid>
                  )}
                  <Grid item xs={1} alignContent='flex-end'>
                    {/* <Box align="right"> */}
                    <Tooltip title='Delete'>
                      <IconButton color='primary' aria-label='Delete' onClick={() => setConfirmDelete(true)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    {/* </Box> */}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}

          {hasAgenda.length > 0 && hasCurrentDateOfEvent ? (
            <Paper style={{ backgroundColor: '#111215', width: '100%' }}>
              <Box mb={1}>
                <Grid container item xs={12}>
                  <Grid item xs={1}>
                    <Box ml={0.5}>
                      <Checkbox
                        onChange={handleSelectAllClick}
                        checked={selected.length === agenda.list.length}></Checkbox>
                    </Box>
                  </Grid>
                  {/* <Box ml={8}> */}
                  <Grid item xs={1}>
                    <Box mr={10} align='right' mt={1}>
                      <Typography>Time</Typography>
                    </Box>
                  </Grid>
                  {/* </Box> */}
                  {/* <Box align="right" ml={15} mt={1}> */}
                  <Grid item xs={2}>
                    <Box mt={1} ml={40}>
                      <Typography>Title</Typography>
                    </Box>
                  </Grid>
                  {/* </Box> */}
                </Grid>
              </Box>
            </Paper>
          ) : (
            <Box m={-2} fontWeight='fontWeightBold'>
              <Alert severity='warning' className={classes.alertTypography}>
                Agendas do not exist yet for this day of the event, please add some
              </Alert>
            </Box>
          )}

          {agenda.list.length > 0 &&
            agenda.list.map((_data, idx) => {
              const isItemSelected = isSelected(_data);
              const belongsToEvent = _data.events ? _data.events.code === eventCode : false;
              return (
                <>
                  {belongsToEvent && _data.date === dateOfEvent && (
                    <Accordion key={idx} style={{ width: '100%' }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
                        {/* {_data.date === dateOfEvent && ( */}
                        <Grid container>
                          <Grid item xs={1} align='left'>
                            <FormControlLabel
                              //   label={_data.title}
                              onChange={(event) => handleClick(event, _data)}
                              onFocus={(event) => event.stopPropagation()}
                              disabled={
                                selected.length === 0 || selected.length === agenda.list
                                  ? false
                                  : currentChecked !== _data.code
                              }
                              control={<Checkbox checked={isItemSelected} />}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            {_data.time ? (
                              <Typography align='left'>
                                {`${_data.time.slice(0, 5)} ${parseInt(_data.time.slice(0, 3)) < 12 ? am : pm}`}
                              </Typography>
                            ) : (
                              <Typography align='left'>TBD</Typography>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {_data.name && <Typography align='left'>{_data.name}</Typography>}
                          </Grid>
                        </Grid>
                        {/* )} */}
                      </AccordionSummary>
                      <AccordionDetails>
                        {_data.description && <Typography color='textPrimary'>{_data.description}</Typography>}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              );
            })}
        </Grid>
      </Toolbar>
    </Box>
  );
};
// export default AgendaList;
export default connect(null, AgendaReducer.actions)(AgendaList);
