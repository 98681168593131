import Routes from "./routes/routes";
// import MainLayout from "./layouts/MainLayout";

//leave the main layout here, we will remove it once we have a homepage{dashboard}
function App() {
  return (
    <div className="App">
      {/* <MainLayout></MainLayout> */}
      <Routes />
    </div>
  );
}

export default App;
