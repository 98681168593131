import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = (props) => {
  const { users } = useSelector((state) => state);
  //check if there's a current user
  // route based on that

  return (
    <Route exact path={props.path}>
      {users.currentUser ? props.children : <Redirect to='/login' />}
    </Route>
  );
};

export default PrivateRoute;
