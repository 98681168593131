import axios from 'axios';
//! old function
// const BASE_URL = process.env.REACT_APP_API_URL;

// export default async function login(credentials) {
//   try {
//     const res = await axios.post(`${BASE_URL}login`, credentials);
//     if (res.data.token) {
//       return res.data;
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

export const LOGIN_URL = 'login';
export const Reset_PASSWORD_URL = 'users/editpassword/';
export const Change_PASSWORD_URL = 'users/changepassword/';
export const Forgot_PASSWORD_URL = 'users/forgotpassword/';
export const EDIT_USER = 'users/edit';
export const GET_USERS = 'users';
export const GET_ALL_USERS = 'users/getAllUsers';
export const ADD_USER = 'users/add';
export const DELETE_USERS = 'users/delete';
export const USER_LOGS = 'userlogs';

export function loginAPI(payload) {
  return axios.post(LOGIN_URL, payload);
}

export function resetPasswordAPI(payload) {
  // console.log(payload);
  return axios.post(`${Reset_PASSWORD_URL}${payload.code}`, {
    password: payload.password,
  });
}

export function changePasswordAPI(payload) {
  console.log(payload);
  return axios.post(`${Change_PASSWORD_URL}${payload.code}`, {
    password: payload.password,
    currentPassword: payload.currentPassword,
  });
}

export function forgotPasswordAPI(payload) {
  return axios.post(`${Forgot_PASSWORD_URL}`, {
    email: payload.email,
  });
}

export function getUsersAPI(payload) {
  return axios.post(GET_USERS, payload);
}
export function getAllUsersAPI(payload) {
  return axios.get(GET_ALL_USERS, payload);
}
export function addUserAPI(payload) {
  // console.log(payload);
  return axios.post(ADD_USER, payload);
}
export function editUserAPI(payload, code) {
  return axios.post(`${EDIT_USER}/${code}`, payload);
}
export const deleteUsersAPI = (codes) => {
  return axios.post(DELETE_USERS, codes);
};
export const getUsersLogsAPI = (payload) => {
  return axios.post(USER_LOGS, payload);
};
