import React, { useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import { addUserAPI } from '../../../API/users';
import { connect } from 'react-redux';
import * as UserReducer from '../../../redux/reducers/users';
import { addUserValidator } from '../../../validators/validators';
import { useHistory } from 'react-router-dom';
import colors from '../../../constants/colors';

import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  makeStyles,
  Select,
  MenuItem,
  Card,
  CardContent,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
    backgroundColor: 'transparent',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  buttonStyles: {
    backgroundColor: colors.primary,
    color: colors.backgroundColor,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.backgroundColor,
      opacity: 0.7,
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  countryCode: {
    width: '100px',
  },
  errorColor: {
    color: colors.error,
  },
}));

const AddUser = (props) => {
  const classes = useStyles();
  // const { users } = useSelector((state) => state);
  const history = useHistory();

  const [formValues, setFormValues] = useState({});
  const [errorValidation, setErrorValidation] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setErrorValidation({
      ...errorValidation,
      [name]: null,
    });
  };

  // const clearForm = () => {
  //   setFormValues({
  //     firstName: '',
  //     lastName: '',
  //     password: '',
  //     email: '',
  //     mobile: '',
  //     isActive: '',
  //     userType: '',
  //   });

  //   // clear the validations
  //   setErrorValidation({});
  // };
  const handleSubmit = () => {
    let errorMessages = {};
    try {
      props.processAddUser();
      addUserValidator
        .validate(formValues, { abortEarly: false })
        .then(() => {
          const payload = {
            ...formValues,
          };

          addUserAPI(payload)
            .then(({ data, response }) => {
              props.processAddUserSuccess(data);
              history.push('/userslist');
            })
            .catch((e) => props.processAddUserFailed());
        })
        .catch((e) => {
          e.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          setErrorValidation(errorMessages);
        });

      // clearForm();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <MainLayout>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Card>
            <CardContent>
              <Box xs={12} mb={5}>
                <Typography variant='h6'>
                  <Box fontWeight='fontWeightBold'>ADD USER</Box>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item lg={4} xs={12} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='First Name'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.firstName}
                      onChange={handleChange}
                      name='firstName'
                      error={errorValidation.firstName}
                      helperText={errorValidation.firstName}></TextField>
                  </Box>

                  <FormControl fullWidth variant='filled'>
                    <InputLabel>User Permissions</InputLabel>
                    <Select
                      onChange={handleChange}
                      name='userType'
                      error={errorValidation.userType}
                      value={formValues.userType}>
                      <MenuItem value='0'>User</MenuItem>
                      <MenuItem value='1'>Admin</MenuItem>
                      <MenuItem value='2'>Viewer</MenuItem>
                    </Select>
                    <FormHelperText className={classes.errorColor}>{errorValidation.userType}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='Last Name'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.lastName}
                      onChange={handleChange}
                      name='lastName'
                      error={errorValidation.lastName}
                      helperText={errorValidation.lastName}></TextField>
                  </Box>
                  <FormControl fullWidth variant='outlined'>
                    <InputLabel>Active Status</InputLabel>
                    <Select
                      onChange={handleChange}
                      name='isActive'
                      error={errorValidation.isActive}
                      helperText={errorValidation.password}
                      value={formValues.isActive}>
                      <MenuItem value='0'>Inactive</MenuItem>
                      <MenuItem value='1'>Active</MenuItem>
                    </Select>
                    <FormHelperText className={classes.errorColor}>{errorValidation.isActive}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} lg={4} direction='column'>
                  <Box mb={2}>
                    <TextField
                      label='Password'
                      type='password'
                      variant='filled'
                      name='password'
                      fullWidth
                      value={formValues.password}
                      onChange={handleChange}
                      error={errorValidation.email}
                      helperText={errorValidation.password}></TextField>
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label='Email'
                      type='text'
                      variant='filled'
                      fullWidth
                      value={formValues.email}
                      onChange={handleChange}
                      name='email'
                      error={errorValidation.email}
                      helperText={errorValidation.email}></TextField>
                  </Box>
                  <TextField
                    label='Mobile'
                    type='text'
                    variant='filled'
                    fullWidth
                    value={formValues.mobile}
                    onChange={handleChange}
                    name='mobile'
                    error={errorValidation.mobile}
                    helperText={errorValidation.mobile}></TextField>
                </Grid>
              </Grid>
              <Box item align='right' p={2}>
                <Box clone m={1}>
                  <Button onClick={() => history.goBack()}>
                    <Box fontWeight='fontWeightBold'>Cancel</Box>
                  </Button>
                </Box>
                <Button onClick={handleSubmit} className={classes.buttonStyles}>
                  <Box fontWeight='fontWeightBold'>Submit</Box>
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </div>
    </MainLayout>
  );
};

// connect to the reducer
export default connect(null, UserReducer.actions)(AddUser);
