import React, { useEffect } from 'react';
import SecondaryLayout from '../../layouts/secondaryLayout';
import { invitationStatusAPI } from '../../API/invitation';
import { useParams, useHistory } from 'react-router-dom';

const InvitationStatus = (props) => {
  const { eventCode, guestCode, data } = useParams();
  useEffect(() => {
    console.log(eventCode, guestCode, data, props);
  });

  const history = useHistory();
  invitationStatusAPI({
    eventCode,
    guestCode,
    data,
  }).then((_data) => {
    console.log('_data', _data, 'eventCode', eventCode);
    if (eventCode === 'EVERQROQO' && data === 'No') {
      window.location.href = 'https://gmisummit.com/';
      return null;
    } else if (eventCode === 'EVERQROQO' && data === 'Yes') {
      window.location.href = 'https://gmisummit.com/registration/gala-dinner-thankyou';
      return null;
    } else if (_data.data._guestEvent.categories.code === 'CAT6RRGP4' && data === 'No') {
      window.location.href = 'https://gmisummit.com/';
      return null;
    } else if (_data.data._guestEvent.categories.code === 'CAT6RRGP4' && data === 'Yes') {
      window.location.href = 'https://gmisummit.com/registration/rsvp-vip-thankyou';
      return null;
    } else if (data === 'Yes') {
      window.location.href = 'https://gmisummit.com/registration/rsvp-thankyou';
    } else {
      window.location.href = 'https://gmisummit.com/';
    }
    // if (data === 'Yes') {
    //   window.location.href = 'https://gmisummit.com/registration/rsvp-thankyou';
    //   return null;
    // } else {
    //   window.location.href = 'https://gmisummit.com/';
    // }
  });

  return <SecondaryLayout></SecondaryLayout>;
};

export default InvitationStatus;
