import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  TextField,
  Box,
  Toolbar,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Button,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getVirtualGuestsList } from '../../API/virtualGuests';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import * as VirtualGuestReducer from '../../redux/reducers/virtualGuests';
import * as CategoryReducer from '../../redux/reducers/categories';

import { withStyles } from '@material-ui/core/styles';
import { useDebounce } from 'use-debounce';
import clsx from 'clsx';
import MainLayout from '../../layouts/MainLayout';
import {
  getComparator,
  stableSort,
  useStyles,
  StyledTableRow,
  useToolbarStyles,
  useStylesTheme,
} from '../../helpers/helpers';
import moment from 'moment';
import axios from 'axios';

//*Table cell style
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const classesTheme = useStylesTheme();

  const { numSelected, onFilterSearch, selectedEvent, eventCodes, changeSelectedEvent } = props;
  useEffect(() => {}, [eventCodes]);
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [startDate, setStartDate] = useState(new Date().toJSON());
  const [endDate, setEndDate] = useState(new Date().toJSON());

  const handleDateFromPicker = () => (e) => {
    setStartDate(e);
    handleChange('fromDate', e.toISOString());
  };
  const handleDateToPicker = () => (e) => {
    setEndDate(e);
    handleChange('toDate', e.toISOString());
  };

  const clearFilter = () => {
    // console.log('Hello');
    setAdvancedFilter({ ...advancedFilter, category: '', fromDate: '', toDate: '' });

    setStartDate(new Date().toJSON());
    setEndDate(new Date().toJSON());
  };

  // const { categories } = useSelector((state) => state);

  function handleEventSelect(e) {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
    setAdvancedFilter({
      category: '',
      event: selectedEvent.code,
      fromDate: '',
      toDate: '',
    });
    changeSelectedEvent(e.target.value);
  }

  const [searchText, setSearchText] = useState('');
  const [value] = useDebounce(searchText, 1000);
  const [advancedFilter, setAdvancedFilter] = useState({
    category: '',
    event: selectedEvent.code,
    fromDate: '',
    toDate: '',
  });
  const [categories, setCategories] = useState([]);
  const _filterRef = useRef(null);

  const fetchCateogryList = () => {
    axios
      .post('categories', { filters: { codes: ['GUEPRESS', 'CATDELEGA'] } })
      .then((_categories) => {
        console.log(_categories.data.data);
        setCategories(_categories.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (_filterRef.current) {
      onFilterSearch(value, advancedFilter);
    }
    _filterRef.current = true;
    // eslint-disable-next-line
  }, [value, advancedFilter]);

  const handleChange = (key, value) => {
    switch (key) {
      case 'category':
        setAdvancedFilter({ ...advancedFilter, category: value });
        break;
      case 'fromDate':
        setAdvancedFilter({ ...advancedFilter, fromDate: moment(value).format('YYYY-MM-DD 00:00:00') });
        break;
      case 'toDate':
        setAdvancedFilter({ ...advancedFilter, toDate: moment(value).format('YYYY-MM-DD 23:59:59') });
        break;
      default:
        break;
    }
  };
  //
  useEffect(() => {
    fetchCateogryList();
  }, []);

  return (
    <>
      <Toolbar
        className={clsx(classesTheme.root, {
          [classesTheme.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <>
            <div className={classes.title}>
              <Typography color='inherit' variant='subtitle1'>
                {numSelected} selected
              </Typography>
            </div>
            <div className={classes.spacer} />
          </>
        ) : (
          <>
            <div>
              {/* SELECT */}
              <div className={classes.container}>
                <Box ml={2} mt={1} mb={1}>
                  <FormControl variant='filled' className={classes.formControl}>
                    <InputLabel variant='filled' id='event-label' style={{ fontSize: '24px', padding: '22px 10px' }}>
                      {selectedEvent && selectedEvent.name && selectedEvent.name}
                    </InputLabel>
                    <Select
                      className={[classes.container, classesTheme.searchForm].join(' ')}
                      labelId='event-label'
                      id='event'
                      placeholder='event'
                      value={
                        Object.keys(formValues).length > 0
                          ? formValues
                          : Object.keys(selectedEvent).length > 0 && selectedEvent.code
                      }
                      name='code'
                      onChange={handleEventSelect}
                      fullWidth>
                      {eventCodes.map((data, index) => {
                        return (
                          <MenuItem key={data.code} value={data} id={data.code}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>

                {/* SEARCH */}
                <div>
                  <form className={classes.container} noValidate autoComplete='off'>
                    <Box ml={2} mt={2}>
                      <TextField
                        className={[classes.container, classesTheme.searchForm].join(' ')}
                        id='outlined-search'
                        label='Search Guest'
                        type='search'
                        margin='normal'
                        variant='outlined'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </Box>
                  </form>
                </div>
              </div>
            </div>

            {/* </Box> */}
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Tooltip title='Filter list'>
                <IconButton aria-label='Filter list' onClick={() => setIsAdvanceFilter(!isAdvanceFilter)}>
                  <FilterListIcon color='primary' />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </Toolbar>

      {/* ADVANCED FILTER */}
      {isAdvanceFilter && (
        <Box ml={1} p={1}>
          <Grid container spacing={2} alignContent='space-between'>
            <Grid item xs={2}>
              <Box clone>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='category-label'>Category</InputLabel>
                  <Select
                    labelId='category-label'
                    id='category'
                    name='categoryCode'
                    value={advancedFilter.category}
                    onChange={(e) => handleChange('category', e.target.value)}
                    fullWidth
                    required>
                    {categories &&
                      categories.length > 0 &&
                      categories.map((data, index) => {
                        return (
                          <MenuItem value={data.code} key={index}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    <MenuItem value=''>All Categories</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box mr={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='fromDate'
                    value={startDate}
                    label='FROM'
                    name='from'
                    autoOk
                    onChange={handleDateFromPicker()}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  id='endDate'
                  value={endDate}
                  label='TO'
                  name='to'
                  minDate={startDate}
                  autoOk
                  onChange={handleDateToPicker()}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4} align='right'>
              <Box mt={3}>
                <Button color='primary' onClick={clearFilter}>
                  Clear Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

//!===============================================================
const headRows = [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Guest ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Email',
  },
  {
    id: 'company',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Company',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Country',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Category',
  },
  {
    id: 'registred',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Registration Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <StyledTableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}>
              {row.isSort ? (
                <TableSortLabel
                  className={'MuiTableSortLabel-dark'}
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              ) : (
                row.label
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//!===============================================================

const EventVirtualGuests = ({
  selectedEvent,
  changeSelectedEvent,
  eventCodes,
  processFilteredVirtualGuests,
  processFilteredVirtualGuestsSuccess,
  processFilteredVirtualGuestsFailed,
}) => {
  const { virtualGuests, users } = useSelector((state) => state);
  const CURRENT_USER = users.currentUser;
  const classes = useStyles();
  //* state:
  const [filterObject, setFilterObject] = useState(null);
  const [selected, setSelected] = useState([]);
  const [dense] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [invitationBtn, setInvitationBtn] = useState(true);
  const [codes, setCodes] = useState([]);
  const [invitationStatuses, setInvitationStatuses] = useState([]);
  const [sources, setSources] = useState([]);

  const fetchVirtualGuestList = () => {
    try {
      const _page = page + 1;

      processFilteredVirtualGuests();
      getVirtualGuestsList({
        page: _page,
        limit: rowsPerPage,
        order,
        orderBy,
        filters: filterObject,
      })
        .then(({ data }) => {
          processFilteredVirtualGuestsSuccess(data);
        })
        .catch((err) => {
          processFilteredVirtualGuestsFailed();
        });
    } catch (error) {
      console.log('=========', error);
    }
  };

  const handleFilterSearch = (data, filter, save) => {
    const filters = cleanObject({
      ...filterObject,
      search: data.length > 0 ? data : null,
      ...filter,
    });
    setFilterObject(filters);
  };
  const cleanObject = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] === null || obj[prop] === undefined || obj[prop].length === 0) {
          delete obj[prop];
        }
      }
    }
    return obj;
  };

  //* sort handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const findNot = invitationStatuses.filter((status) => status.includes('Not'));
    const findRejected = invitationStatuses.filter((status) => status.includes('Rejected'));
    // console.log(invitationStatuses);
    if (findNot.length > 0 || findRejected.length > 0) {
      setInvitationBtn(false);
    } else {
      setInvitationBtn(true);
    }
  }, [invitationStatuses]);

  useEffect(() => {
    fetchVirtualGuestList();
    //eslint-disable-next-line
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = +event.target.value;
    const _page = page + 1;
    const _prevPageCount = Math.ceil(virtualGuests.list.total / limit);
    if (_page > _prevPageCount) setPage(0);
    setRowsPerPage(limit);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const _toolBarFiltering = useRef(null);

  useEffect(() => {
    if (_toolBarFiltering.current) {
      fetchVirtualGuestList();
    }
    _toolBarFiltering.current = true;
    // eslint-disable-next-line
  }, [filterObject, order, orderBy, page, rowsPerPage]);

  return (
    <MainLayout>
      <div className={classes.root}>
        <Box className={classes.paper}>
          <Grid container direction='column'>
            <Grid item>
              <Typography color='inherit' variant='h4' className={classes.pageTitle}>
                {`Attendees of ${selectedEvent && selectedEvent.name && selectedEvent.name}`}
              </Typography>
            </Grid>
          </Grid>
          <EnhancedTableToolbar
            numSelected={selected.length}
            changeNumSelected={setSelected}
            onFilterSearch={handleFilterSearch}
            virtualGuestCodes={codes}
            selectedEvent={selectedEvent}
            eventCodes={eventCodes}
            changeSelectedEvent={changeSelectedEvent}
            setCodes={setCodes}
            invitationBtn={invitationBtn}
            setInvitationStatuses={setInvitationStatuses}
            invitationStatuses={invitationStatuses}
            fetchVirtualGuestList={fetchVirtualGuestList}
            CURRENT_USER={CURRENT_USER}
            Sources={sources}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
              aria-label='enhanced table'>
              <EnhancedTableHead
                classes={classes}
                order={order}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                rowCount={virtualGuests.total < rowsPerPage ? virtualGuests.total : rowsPerPage}
              />
              <TableBody>
                {stableSort(virtualGuests.list, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.code}
                      selected={isItemSelected}>
                      <TableCell component='th' id={labelId} scope='row'>
                        {row.code}
                      </TableCell>
                      <TableCell align='left'>
                        {row.firstName} {row.lastName}
                      </TableCell>
                      <TableCell align='left'>{row.email ? row.email : '-'}</TableCell>
                      <TableCell align='left'>{row.company ? row.company : '-'}</TableCell>
                      <TableCell align='left'>{row.country ? row.country : '-'}</TableCell>
                      <TableCell align='left'>{row.category && row.category.name ? row.category.name : '-'}</TableCell>
                      <TableCell align='left'>
                        {row.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : '-'}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component='div'
            count={virtualGuests.total}
            rowsPerPage={virtualGuests.limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </MainLayout>
  );
};
export default connect(null, {
  ...CategoryReducer.actions,
  ...VirtualGuestReducer.actions,
})(EventVirtualGuests);
