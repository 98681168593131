/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Box, Button, ButtonGroup, makeStyles, Tabs, Tab, Paper } from '@material-ui/core';
import { addAgendaAPI, editAgendaAPI, getAgendaAPI } from '../../API/agenda';
import {} from '../../API/agenda';
import * as AgendaReducer from '../../redux/reducers/agenda';
import { connect } from 'react-redux';
import colors from '../../constants/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Scrollbars } from 'rc-scrollbars';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  buttonStyles: {
    backgroundColor: `${colors.primary} ! important`,
  },
  buttonGroup: {
    margin: '50px !important',
    padding: '10px',
  },
  tabs: {
    width: '100%',
  },
  tab: {
    backgroundColor: `${colors.primary} ! important`,
    color: `${colors.backgroundColor} !important`,
    borderTop: 'solid 1px',
    borderRight: 'solid 1px',
    borderLeft: 'solid 1px',
    borderRadius: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
    marginBottom: '-5px',
    // padding: '10px',
    // margin: '10px',
  },

  activeTab: {
    backgroundColor: `${colors.paper} !important`,
    color: `${colors.textPrimary} !important`,

    borderRadius: '5px',
    marginRight: '5px',
    fontWeight: 'bold',
    marginBottom: '-5px',
  },
  scroll: {
    '& .rc-scrollbars-thumb': {
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: `${colors.primary} !important`,
    },
  },
  datetimePadge: {
    '& .MuiFormLabel-root': {
      margin: '10px',
    },
    backgroundColor: theme.palette.paper.default,
    borderRadius: 5,
    color: 'white',
    padding: '5px',
    height: '48px',
  },
}));
const AddAgenda = ({
  event,
  agenda,
  processFilteredAgenda,
  processFilteredAgendaSuccess,
  processFilteredAgendaFailed,
  setSelected,
  setDateOfEvent,
}) => {
  const classes = useStyles();

  const [formValues, setFormValues] = useState(agenda);
  const [daysDates, setDaysDates] = useState([]);
  const [currentDayDate, setCurrentDayDate] = useState({});
  const [active, setActive] = useState(0);
  const [editAgenda, setEditAgenda] = useState(null);

  const { code } = useParams();

  let startDate = new Date(event[0].startDate);
  let endDate = new Date(event[0].endDate);

  const _startDate = moment(startDate);
  const _endDate = moment(endDate);

  let numberOfDays = _endDate.diff(_startDate, 'days') + 1;
  // let numberOfDays = differenceInTime / (1000 * 3600 * 24);
  // numberOfDays = numberOfDays < 0 ? 1 : numberOfDays + 1;
  let fromDate = event[0].startDate.split('-');
  fromDate[2] = fromDate[2].slice(0, 2);

  // console.log();

  const fetchAgendas = () => {
    processFilteredAgenda();
    getAgendaAPI()
      .then(({ data }) => {
        processFilteredAgendaSuccess(data);
      })
      .catch((e) => {
        processFilteredAgendaFailed();
        console.log(e);
      });
  };

  const handleSubmit = () => {
    //
    const data = {
      ...formValues,
      date: new Date(currentDayDate.date).toISOString(),
      eventCode: code,
    };
    try {
      addAgendaAPI(data)
        .then(({ data }) => {
          fetchAgendas();
          setFormValues({ time: '', description: '', name: '' });
        })
        .catch((e) => {
          setFormValues({ time: '', description: '', name: '' });
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditSubmit = () => {
    const data = { ...formValues, date: currentDayDate.date, eventCode: code };
    const agendaCode = data.code;
    delete data.code;

    try {
      editAgendaAPI(data, agendaCode)
        .then(({ data }) => {
          // fetch data
          fetchAgendas();
          setFormValues({ time: '', description: '', name: '' });
          setSelected([]);
          setEditAgenda(false);
        })
        .catch((e) => {
          setFormValues({ time: '', description: '', name: '' });
          setSelected([]);
          setEditAgenda(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let daysAndDates = [];
    setDaysDates(fromDate);
    for (let i = 0; i < numberOfDays; i++) {
      let date = i === 0 ? fromDate.join('-') : new Date(startDate.getTime() + 24 * i * 3600 * 1000).toISOString();
      let data = {
        day: `Day ${i + 1}`,
        date,
      };
      daysAndDates.push(data);
    }

    setDaysDates(daysAndDates);
    daysAndDates.length > 0 && setDateOfEvent(daysAndDates[0].date.slice(0, 10));

    setCurrentDayDate(daysAndDates[0]);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormValues(agenda);
    if (!agenda.name && !agenda.description && !agenda.time) {
      setEditAgenda(false);
    } else {
      setEditAgenda(true);
    }
  }, [agenda]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const changeDate = (idx, data) => {
    setDateOfEvent(data.date.slice(0, 10));
    setCurrentDayDate(daysDates[idx]);
    setActive(idx);
  };

  return (
    <>
      <Grid container direction='column' className='addAgenda' spacing={3}>
        <Grid item xs={12}>
          <Box mb={-2}>
            <Tabs textColor='secondary' className={classes.tabs}>
              {daysDates.length > 0 && (
                <Scrollbars style={{ maxWidth: '89%', height: 60 }} className={classes.scroll} thumbMinSize={30}>
                  {daysDates.map((data, idx) => (
                    <Tab
                      style={{ opacity: 1 }}
                      label={data.day}
                      key={idx}
                      className={active === idx ? classes.activeTab : classes.tab}
                      onClick={() => changeDate(idx, data)}></Tab>
                  ))}
                </Scrollbars>
              )}
            </Tabs>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} lg={2}>
            {currentDayDate.date ? (
              <TextField
                className={classes.datetimePadge}
                varieant='filled'
                color='primary'
                disabled
                value={currentDayDate.date.slice(0, 10)}
              />
            ) : (
              <TextField varieant='filled' color='primary' disabled value='' className={classes.datetimePadge} />
            )}
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id='time'
              label='Time'
              type='time'
              name='time'
              defaultValue='00:00'
              value={formValues.time}
              onChange={handleChange}
              className={classes.datetimePadge}
              //   InputLabelProps={{
              //     shrink: true,
              //   }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Grid>
        </Grid>
        <Grid item lg={8} xs={12}>
          <TextField
            label='Title'
            type='text'
            variant='filled'
            fullWidth
            value={formValues.name}
            onChange={handleChange}
            name='name'
            // error={errorValidation.firstname}
            // helperText={errorValidation.firstname}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label='Description'
            type='text'
            variant='filled'
            fullWidth
            multiline
            rows={5}
            value={formValues.description}
            onChange={handleChange}
            name='description'
            // error={errorValidation.description}
            // helperText={errorValidation.description}
          ></TextField>
        </Grid>
        {editAgenda ? (
          <Box item align='right' p={2}>
            <Button onClick={handleEditSubmit} color='primary' variant='contained'>
              <Box fontWeight='fontWeightBold'>Edit Agenda</Box>
            </Button>
          </Box>
        ) : (
          <Box item align='right' p={2}>
            <Button onClick={handleSubmit} color='primary' variant='contained'>
              <Box fontWeight='fontWeightBold'>Add Agenda</Box>
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );
};
export default connect(null, AgendaReducer.actions)(AddAgenda);
