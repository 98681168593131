import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  total: 0,
  limit: 20,
  list: [],
  loading: false,
  isFirstLoad: true,
};

export const reducer = persistReducer(
  { storage, key: 'events', whitelist: ['events', 'list'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.EVENTS_LIST:
        return { ...state, loading: true };
      case actionTypes.EVENTS_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload.data,
          limit: action.payload.limit,
          total: action.payload.total,
          guestEvents: action.payload.guestEvents,
          isFirstLoad: false,
        };
      case actionTypes.EVENTS_LIST_FAILED:
        return { ...state, loading: false };
      default:
        return state;
    }
  }
);
export const actionTypes = {
  EVENTS_LIST: '[EVENTS] LIST',
  EVENTS_LIST_SUCCESS: '[EVENTS] LIST SUCCESS',
  EVENTS_LIST_FAILED: '[EVENTS] LIST FAILED',
};

export const actions = {
  processFilteredEvents: () => ({
    type: actionTypes.EVENTS_LIST,
    payload: {},
  }),
  processFilteredEventsSuccess: (data) => ({
    type: actionTypes.EVENTS_LIST_SUCCESS,
    payload: data,
  }),
  processFilteredEventsFailed: () => ({
    type: actionTypes.EVENTS_LIST_FAILED,
    payload: {},
  }),
};

export function* saga() {}
