/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SecondaryLayout from '../../../layouts/secondaryLayout';
import { resetPasswordAPI } from '../../../API/users';
import { useHistory, Link } from 'react-router-dom';
import colors from '../../../constants/colors';

import { Grid, Typography, TextField, Box, Card, CardContent, Button, makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import * as UserReucer from '../../../redux/reducers/users';
import { resetPasswordValidator } from '../../../validators/validators';

const formData = {
  title: 'Reset Password',
  subtitle: '',
  fields: [
    {
      label: 'password',
      placeHolder: 'Type your password',
      type: 'password',
    },
    {
      label: 'confirm',
      placeHolder: 'Confirm your password',
      type: 'password',
    },
  ],

  buttons: [
    {
      label: 'Reset Password',
      link: '',
    },
  ],
};
const useStyles = makeStyles((theme) => ({
  cardStyles: {
    minWidth: 450,
  },

  buttonStyles: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: colors.primary,
  },
  removeUnderline: {
    textDecoration: 'none',
  },
}));

const ResetPassword = (props) => {
  const [formValues, setFormValues] = useState({
    password: '',
    confirm: '',
  });
  const [errorValidation, setErrorValidation] = useState({});

  const history = useHistory();
  const currentUser = useSelector((store) => store.users.currentUser);
  const queryString = window.location.href;
  const code = queryString.split('/').pop();
  const classes = useStyles();
  const { users } = useSelector((state) => state);
  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues((data) => ({
      ...data,
      [name]: value,
    }));
  }

  const submitForm = () => {
    console.log('test test');
    let errorMessages = {};

    try {
      resetPasswordValidator
        .validate(formValues, { abortEarly: false })
        .then(() => {
          props.processResetPassword();
          resetPasswordAPI({ password: formValues.password, code: code })
            .then((data) => {
              props.processResetPasswordSuccess(data);
              history.push('/');
            })
            .catch((e) => props.processResetPasswordFailed());
        })
        .catch((e) => {
          e.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          setErrorValidation(errorMessages);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <SecondaryLayout>
      <Card className={classes.cardStyles}>
        <CardContent>
          <Grid container direction='column' spacing={5}>
            <Grid item>
              <Box fontWeight='fontWeightBold'>
                <Typography variant='h6'>{formData.title}</Typography>
              </Box>
              {formData.subtitle && <Typography variant='body2'>{formData.subtitle}</Typography>}
            </Grid>
            {formData.fields.map((_data, idx) => (
              <Grid item key={idx}>
                <Box color='textPrimary' fontWeight='fontWeightBold'>
                  <Typography>{_data.label.slice(0, 1).toUpperCase() + _data.label.slice(1)}</Typography>
                </Box>
                <TextField
                  label={_data.placeHolder}
                  type={_data.type}
                  key={idx}
                  variant='filled'
                  fullWidth
                  value={formValues[_data.label]}
                  onChange={handleChange}
                  name={_data.label}
                  error={errorValidation[_data.label]}
                  helperText={errorValidation[_data.label]}></TextField>
              </Grid>
            ))}

            {formData.links &&
              formData.links.map((_data, idx) => (
                <Grid item align='right' key={idx}>
                  <Link to={_data.link} classname={classes.removeUnderline}>
                    <Typography color='textSecondary' variant='body2'>
                      {_data.label}
                    </Typography>
                  </Link>
                </Grid>
              ))}

            {formData.buttons &&
              formData.buttons.map((_data, idx) => (
                <Grid item align='center' key={idx}>
                  <Button className={classes.buttonStyles} vaiant='contained' onClick={submitForm}>
                    <Box fontWeight='fontWeightBold'>{_data.label}</Box>
                  </Button>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </SecondaryLayout>
  );
};

export default connect(null, UserReucer.actions)(ResetPassword);
