import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import MainLayout from '../../layouts/MainLayout';
import { useSelector } from 'react-redux';
import VirtualEventGuests from './virtualEventGuests';
import EventGuests from './eventGuests';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '92%',
    marginBottom: theme.spacing(2),
    marginTop: '80px',
    marginLeft: '75px',
  },
  formControl: {
    width: '100%',
  },
  cardStyles: {
    minWidth: 450,
  },
  buttonStyles: {
    backgroundColor: theme.palette.primary.main,
  },
}));
const Attendees = () => {
  const [atendessList, setAtendeesList] = useState(false);
  const [event, setEvent] = useState({});
  const [eventCodes, setEventCodes] = useState({});
  if (atendessList && event && eventCodes) {
    console.log(event.isVirtual);
    if (event.isVirtual) {
      return <VirtualEventGuests eventCodes={eventCodes} selectedEvent={event} changeSelectedEvent={setEvent} />;
    } else {
      return <EventGuests eventCodes={eventCodes} selectedEvent={event} changeSelectedEvent={setEvent} />;
    }
  } else {
    return <SelectAttendees changeView={setAtendeesList} changeEvent={setEvent} changeEventCodes={setEventCodes} />;
  }

  // return atendessList ? (
  //   <VirtualEventGuests eventCodes={eventCodes} selectedEvent={event} changeSelectedEvent={setEvent} />
  // ) : (
  //   <SelectAttendees changeView={setAtendeesList} changeEvent={setEvent} changeEventCodes={setEventCodes} />
  // );
};
const SelectAttendees = ({ changeView, changeEvent, changeEventCodes }) => {
  const classes = useStyles();
  const { events } = useSelector((state) => state);
  let eventsClone = events.list;
  const eventsCodes = eventsClone.map((event) => ({
    code: event.code,
    name: event.name,
    id: event.id,
    isVirtual: event.isVirtual,
  }));
  const handleChange = (e) => {
    changeEventCodes(eventsCodes);
    changeEvent(e.target.value);
    changeView(true);
  };
  return (
    <MainLayout>
      <Paper className={classes.paper}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item lg={6} md={3} sm={12}>
                <FormControl variant='filled' className={classes.formControl}>
                  <InputLabel id='event-label'>Choose Event</InputLabel>
                  <Select labelId='event-label' id='event' label='event' name='code' onChange={handleChange} fullWidth>
                    {eventsCodes.map((data, index) => {
                      return (
                        <MenuItem value={data} id={data.code}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </MainLayout>
  );
};

export default Attendees;
