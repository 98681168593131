import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../../../layouts/MainLayout';
import EditUser from '../editUser/editUserFormFragment';
import { useParams } from 'react-router-dom';

function UserEdit(props) {
  const { list } = useSelector((state) => state.users);
  const [user, setUser] = useState(null);
  const { code } = useParams();

  useEffect(() => {
    const _user = list.find((user) => user.code === code);
    setUser(_user);
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <EditUser userData={user} />
    </MainLayout>
  );
}

export default UserEdit;
